.content {
  color: var(--light-dark-blue-700-text-icon, var(--legacy-light-dark-blue-700-text-icon, #1a2a45));

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: -0.00875rem;
}

.seeMore {
  color: var(--text-link-primary, #006b8f) !important;

  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.25rem !important;
  letter-spacing: -0.00875rem !important;
  text-decoration-line: underline !important;
  cursor: pointer;
}

.resetStyles {
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  ol,
  ul {
    padding-inline-start: 40px;
  }
  ol {
    list-style: decimal;
  }
  ol[type='a'] {
    list-style-type: lower-alpha;
  }
  ol[type='i'] {
    list-style-type: lower-roman;
  }
  ul {
    list-style-type: disc;
  }
}

.descriptionModal {
  line-height: 1.75rem;

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
  }
}

.descriptionFooter {
  display: flex;
  justify-content: flex-end;
}