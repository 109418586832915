@import '../../../../../sass/color-palette';
@import '../../../../../sass/mixins';

.mobileGallery {
  width: 100%;
  @include toRem('height', 151);

  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  &_item {
    height: 155px;

    & img {
      border-radius: 0.25rem;
      object-fit: cover;
      height: -webkit-fill-available;
      width: 100%;
    }
  }
}

.main {
  grid-template-columns: 1fr;
}

.multi {
  grid-auto-columns: minmax(300px, 35px);
}
