.review {
  width: fit-content;
  border: 0.5px solid rgba(0, 153, 204, 1);
  background: rgba(207, 236, 245, 1);
  border-radius: 25px;
  padding: 0.5rem;
  color: rgba(95, 106, 125, 1);
  font-size: 10px;
  margin: 0 auto;
  font-weight: 400;
  display: flex;
  gap: 9px;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 766px) {
    display: none;
  }
}
.review_star_text {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
