.featureModal {
  z-index: 10000;
  & > div {
    border-radius: 0.5rem;
    padding-top: 24px;
  }
}

.listsContainer {
  &:only-child {
    padding: 24px 0;
    border-bottom: transparent;
  }

  & > :not(:only-child):first-child {
    border-bottom: 1px solid #c6cad1;
    padding-bottom: 32px;
  }

  & > :nth-child(2) {
    padding-top: 32px;
  }
}
