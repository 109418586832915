.sponsoredSection {
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  @media screen and (min-width: 1000px) {
    display: none;
  }
}
.sponsored_match {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 999;
}

.title {
  text-align: center;
  margin-bottom: 2rem;

  & b {
    color: rgba(135, 77, 0, 1);
  }
}

.card {
  border: 1px solid rgba(241, 242, 243, 1);
  border-radius: 4px;
  padding: 0 1rem;
}

.image {
  height: 115px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}

.content {
  padding: 1rem;
}

.name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chipsWrapper {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.sponsoredChip {
  border: 0.8px solid rgba(212, 136, 6, 1);
}
