
.layoutv3{
    .reviewLikeQuestion {
        max-width: 752px;
        width: 100%;
        height: calc(100vh - 140px);
        overflow-y: scroll ;
        
        @media (min-width:768px) {
            height: 100%;
            overflow-y: unset;
        }

        &-title {
            margin-bottom: 32px;
        }

        &__tag-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            // justify-content: center;
            gap:1rem;
        }
        &__tag-item {
            display: flex;
            align-items: center;
            text-align: center;
            border-radius: 8px;
            text-transform: capitalize;
            min-height: 42px;
            padding: 8px 12px;
            background: #fff;
            border: 1px solid #DADEE5;
            cursor: pointer;
            /* @media(min-width: 768px){
                margin: 32px 8px auto 8px;
            } */
        }
        
        .selected {
            /* Accent/10 */
            background: #E5F4FF;
            /* Accent/100 */
            border: 1px solid #008CFF;
        }
    }

}