@import '../../../../../sass/color-palette';

.bigModalReview {
  background: white;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &_tab {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
    transition: .4s;
    
    &:hover {
      background: $dark-blue-05;
      transition: .4s;
    }
  }

  &_bar {
    background: $primary;
    height: 5px;
    border-radius: 4px;
    
    transition: .6s ease-in-out;
    transition-delay: .2s;
  }

  &_content {    
    max-width: 752px;
    margin: 0 auto;

    
    @media screen and (max-width: 760px) {
      max-width: 95%;
    }
  }

  &_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;

    @media (min-width:768px){
      margin-top: 64px;
    }

    button {
      width: 160px;
    }
  }
}

.warningValidation {
  color: $red-legacy;
}

// @keyframes progressBar {
//   from {
//     width: 0;
//   }
//   to {
//     width: 20%;
//   }
// }