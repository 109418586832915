/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@use '../themes';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a,
p {
  font-family: 'Open Sans', sans-serif;
}

html,
body {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  @include themes.theme-aware('background-color', 'bg-default');
  @include themes.theme-aware('color', 'text');

  &.body-blue {
    @include themes.theme-aware('background-color', 'primary');
  }
}

html {
  box-sizing: border-box;
  background: transparent;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  padding: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-layout__children {
  margin: 0 !important;
}

.container {
  width: 100%;
  margin: auto;
}

.layoutv3 {
  .navbar .nav-link {
    font-size: 16px !important;
  }
  .heart-green {
    @include themes.theme-aware('color', 'bg-tertiary');
    margin-right: 18px !important;
  }
}
.parent-gray-active {
  @include themes.theme-aware('color', 'bg-secondary');
}

.container.right-aligned {
  margin-right: 0;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
