.sectionGroup {
  margin: 1.5rem 0;
}

.title {
  margin-bottom: .5rem;
}

.group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  transition: max-height 0.4s ease-in;
}

.filterChip {
  font-size: 14px;
  padding: 4px 7px;
  border-radius: 4px;
  border: 1px solid #c6cad1;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

  display: flex;
  gap: 0.5rem;

  &:hover {
    border: 1px solid #0c5e79;
    background: #e6f5fa;
  }
}
.selected {
  border: 1px solid #0c5e79;
  background: #e6f5fa;
}

.seeMore {
  color: #006b8f;
  font-size: 14px;
  padding-top: 1rem;
}
