@import '../../../../../sass/color-palette';

.starsExperience {
  display: flex;
  gap: 18px;

  & button:focus {
    outline: none;
  }

  &_item {
    & svg {
      width: 16px;
      height: 16px;

      transition: all 0.3s ease-out;

      // &:hover {
      //   // fill: red;
      // }
    }
  }
}

.starOn {
  fill: rgb(239, 210, 76);
}
.starOff {
  fill: $dark-blue-25;
}
