@import '../../../../../sass/mixins';

.layoutv3 {
  .title-nearby {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
  }
  .caroussel-card {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .card {
      .schools_slider {
        &__buttons {
          button {
            align-items: center;
            background: #fff;
            border-radius: 8px;
            border: 2px solid #d8e3f5;
            padding: 0px;
            width: 33px;
            height: 33px;
          }

          svg {
            height: 16px;
            width: 16px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

#nearby-slider {
  .card {
    border: none;
  }

  .school-info {
    min-height: 180px;
  }

  .slick-initialized {
    display: flex;
    align-items: center;
  }

  .slick-active {
    margin-right: 0.5rem;

    &:last-child {
      padding: 0;
    }
  }

  .card-column {
    @media (max-width: 767px) {
      @include toRem('width', 351);
    }
  }

  .custom-button-slider {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #d8e3f5;
    padding: 0px;
    width: 33px;
    height: 33px;

    svg {
      height: 24px;
      width: 24px;
      margin: 3px;
      fill: #0c5e79;
    }
  }
  .prev-btn {
    margin-right: -10px;
    z-index: 50;
  }

  .next-btn {
    margin-left: -10px;
    z-index: 50;
  }

  /* .slick-list {
    @media (max-width: 767px) {
      @include toRem('height', 310);
    }
  } */

  .slick-track {
    display: flex;
  }
}
