@use 'sass:map';
@use 'colors/scheme-colors';

$themes: (
  light: (
    primary: scheme-colors.$primary-400, // token legacy
    //text
    text-heading: scheme-colors.$neutral-700,
    text: scheme-colors.$neutral-600,
    text-subtle: scheme-colors.$neutral-500,
    text-disabled: scheme-colors.$neutral-300,
    text-success: scheme-colors.$success-600,
    text-link-primary: scheme-colors.$primary-500,
    text-link-primary-visited: scheme-colors.$primary-600,
    text-onAction: scheme-colors.$neutral-100,
    text-onAction-disabled: scheme-colors.$neutral-300,
    // Backgraound
    bg-default: #ffffff,
    bg-subtle: scheme-colors.$neutral-100,
    bg-primary: scheme-colors.$primary-500,
    bg-primary-hover: scheme-colors.$primary-400,
    bg-primary-pressed: scheme-colors.$primary-600,
    bg-primary-active: scheme-colors.$primary-400,
    bg-primary-disabled: scheme-colors.$neutral-100,
    bg-secondary: scheme-colors.$secondary-500,
    bg-secondary-hover: scheme-colors.$secondary-400,
    bg-secondary-pressed: scheme-colors.$secondary-600,
    bg-secondary-active: scheme-colors.$secondary-400,
    bg-secondary-disabled: scheme-colors.$neutral-100,
    bg-tertiary: scheme-colors.$tertiary-400,
    bg-tertiary-hover: scheme-colors.$tertiary-500,
    bg-tertiary-pressed: scheme-colors.$tertiary-600,
    bg-tertiary-active: scheme-colors.$tertiary-400,
    bg-tertiary-disabled: scheme-colors.$neutral-100,
    bg-success: scheme-colors.$success-100,
    bg-warning: scheme-colors.$warning-100,
    bg-danger: scheme-colors.$danger-100,
    bg-info: scheme-colors.$info-100,
    // Border
    border-default: scheme-colors.$neutral-200,
    border-subtle: scheme-colors.$neutral-300,
    border-primary: scheme-colors.$primary-300,
    border-success: scheme-colors.$success-300,
    border-warning: scheme-colors.$warning-300,
    border-danger: scheme-colors.$danger-300,
    border-info: scheme-colors.$info-300,
    // Icon
    icon-default:scheme-colors.$neutral-700,
    icon-subtle:scheme-colors.$neutral-400,
    icon-onAction:scheme-colors.$neutral-100,
    icon-onAction-disabled:scheme-colors.$neutral-300,
    icon-primary:scheme-colors.$primary-500,
    icon-success:scheme-colors.$success-700,
    icon-warning:scheme-colors.$warning-700,
    icon-danger:scheme-colors.$danger-700,
    icon-info:scheme-colors.$info-700
  ),
  dark: (
    primary: scheme-colors.$primary-300, // token legacy
    //text
    text-heading: scheme-colors.$neutral-100,
    text: scheme-colors.$neutral-100,
    text-subtle: scheme-colors.$neutral-300,
    text-disabled: scheme-colors.$neutral-500,
    text-success: scheme-colors.$success-500,
    text-link-primary: scheme-colors.$primary-400,
    text-link-primary-visited: scheme-colors.$primary-500,
    text-onAction: scheme-colors.$neutral-100,
    text-onAction-disabled: scheme-colors.$neutral-500,
    // Backgraound
    bg-default: scheme-colors.$neutral-700,
    bg-subtle: scheme-colors.$neutral-600,
    bg-primary: scheme-colors.$primary-400,
    bg-primary-hover: scheme-colors.$primary-300,
    bg-primary-pressed: scheme-colors.$primary-500,
    bg-primary-active: scheme-colors.$primary-300,
    bg-primary-disabled: scheme-colors.$neutral-600,
    bg-secondary: scheme-colors.$secondary-400,
    bg-secondary-hover: scheme-colors.$secondary-400,
    bg-secondary-pressed: scheme-colors.$secondary-600,
    bg-secondary-active: scheme-colors.$secondary-400,
    bg-secondary-disabled: scheme-colors.$neutral-600,
    bg-tertiary: scheme-colors.$tertiary-300,
    bg-tertiary-hover: scheme-colors.$tertiary-400,
    bg-tertiary-pressed: scheme-colors.$tertiary-500,
    bg-tertiary-active: scheme-colors.$tertiary-300,
    bg-tertiary-disabled: scheme-colors.$neutral-600,
    bg-success: scheme-colors.$success-200,
    bg-warning: scheme-colors.$warning-200,
    bg-danger: scheme-colors.$danger-200,
    bg-info: scheme-colors.$info-200,
    // Border
    border-default: scheme-colors.$neutral-600,
    border-subtle: scheme-colors.$neutral-100,
    border-primary: scheme-colors.$primary-500,
    border-success: scheme-colors.$success-500,
    border-warning: scheme-colors.$warning-500,
    border-danger: scheme-colors.$danger-500,
    border-info: scheme-colors.$info-500,
    // Icon
    icon-default:scheme-colors.$neutral-100,
    icon-subtle:scheme-colors.$neutral-400,
    icon-onAction:scheme-colors.$neutral-100,
    icon-onAction-disabled:scheme-colors.$neutral-500,
    icon-primary:scheme-colors.$primary-400,
    icon-success:scheme-colors.$success-600,
    icon-warning:scheme-colors.$warning-600,
    icon-danger:scheme-colors.$danger-600,
    icon-info:scheme-colors.$info-600
  ),
);

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {

    :global(.theme-#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme-#{$theme-name} & {
      #{$key}: map.get(map.get($themes, $theme-name), $color);
    }
  }
}
