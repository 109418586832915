
.layoutv3{
    .reviewQuestion {
        max-width: 752px;
        width: 100%;
        
        &-title {
            margin-bottom: 32px;
        }

        &__container {
            display: flex;
            justify-content: center;
        }
        
        &__answer {
            width: 100%;
            padding: 12px;
            box-sizing: border-box;
            resize: none;
            height: 154px;
            border-radius: 8px;
        }
        
    }

}