.container {
  background: #018d5a0d;
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  border: 1px solid #c6cad1;
  border-radius: 8px;
  @media (min-width: 768px) {
    flex-direction: row;
  }

  ul {
    margin-bottom: 16px;
    li {
      display: flex;
      align-items: center;
      gap: 7px;
      margin-bottom: 8px;
    }
  }
}
.list {
  font-size: 12px;
}

.button {
  background-color: #007a4d !important;
}
