@import '../../../../sass/color-palette';

.paginationProfile {
  display: grid;
  place-items: center;
  
  &_container {
    display: flex;
  }

  &_item {
    padding: 15px 18px;
    // border: 1px solid $dark-blue-700;
    border-radius: 4px;
    cursor: pointer;
  }

  &_selected {
    background: $navy-blue-10;
    font-weight: bold;
  }
}