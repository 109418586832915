@use '../../../sass/themes';

.container {
  padding: 0;
}

.bulletin-board {
  padding-top: 0;
  background-color: #f4f4fa;
  .bulletin-grid {
    padding: 0 15px;
    width: 100%;
    .sgs2 {
      float: none;
      margin-bottom: 40px;
      border-radius: 3.5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .bulletinCard {
      &.sgs3 {
        margin: 0;
        max-width: initial;
        .card-desc--bulletin {
          .info {
            height: 112px;
            margin: 0;
            padding: 30px;
            .name {
              font-size: 14px;
              font-weight: 600;
              line-height: 21px;
              height: initial;
              margin-bottom: 15px;
              a {
                text-decoration: none;
              }
            }
          }
        }
      }
      .heading {
        background: linear-gradient(180deg, rgba(27, 27, 27, 0.74), rgba(41, 41, 41, 0));
        border-radius: 3.5px 3.5px 0 0;
        padding: 10px 10px 0 15px;
        position: absolute;
        top: 0;
        width: 100%;
        .time-ago {
          @include themes.theme-aware('color', 'text');
          color: #fff;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.6px;
          text-transform: uppercase;
        }
      }
    }
    .bulletin-item {
      @media (max-width: 1200px) {
        margin-bottom: 40px;
      }
      .thumb {
        height: 180px;
        position: relative;
        background-size: cover;
        background-position: top center;
        .heading {
          padding: 10px 10px 0 15px;
          border-radius: 3.5px 3.5px 0 0;
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(27, 27, 27, 0.74)),
            to(rgba(41, 41, 41, 0))
          );
          background: linear-gradient(180deg, rgba(27, 27, 27, 0.74) 0%, rgba(41, 41, 41, 0) 100%);
          .time-ago {
            @include themes.theme-aware('color', 'bg-default');
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.6px;
            text-transform: uppercase;
          }
          .menu-button {
            width: 20px;
            height: 30px;
            padding: 0;
            border: 0;
            float: right;
            cursor: pointer;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .play-button {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 35px;
          height: 35px;
          margin: auto;
          border: none;
          position: absolute;
          background-size: cover;
          background-position: center;
          background-color: transparent;
        }
      }
      .info {
        text-align: center;
        padding: 30px;
        @include themes.theme-aware('background-color', 'bg-default');
        border-radius: 0 0 3.5px 3.5px;
        .name {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .uploaded-legend {
          font-size: 13px;
          font-weight: 300;
        }
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
.less60 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .featured-schools {
    .schools-grid {
      width: 320px;
      max-width: initial;
      margin: auto;
      .school-item {
        width: 260px;
        max-width: initial;
      }
    }
  }
}
@media (min-width: 576px) {
  .featured-schools {
    .schools-grid {
      width: 540px;
      max-width: initial;
      margin: auto;
      .school-item {
        width: 490px;
        max-width: initial;
      }
    }
  }
}
@media (min-width: 868px) {
  .landing-section {
    padding: 100px 0;
    .section-title {
      font-size: 27px;
      margin-bottom: 60px;
    }
    .section-legend {
      line-height: initial;
      padding: 0;
      margin: -40px 0 60px;
    }
  }
  .featured-schools {
    .schools-grid {
      width: 720px;
      .school-item {
        width: 325px;
      }
      &.slick-slider {
        button {
          &.slick-prev {
            left: -15px;
          }
          &.slick-next {
            right: -15px;
          }
        }
      }
    }
  }
  .bulletin-board {
    overflow: auto;
    .bulletin-grid {
      .sgs2 {
        width: 50%;
        float: left;
        padding: 0 10px;
        margin: 0 0 40px 0;
      }
      .bulletin-item {
        width: 50%;
        float: left;
        padding: 0 10px;
        .thumb {
          height: 150px;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .home-hero .home-hero-content .home-hero-box .find-school .search-box .geosuggest__input,
  .home-hero .home-hero-content .home-hero-box .find-school .search-box .search-button {
    font-size: 14px;
  }
  .home-hero {
    height: 625px;
    .home-hero-content {
      .home-hero-box {
        top: -16%;
        width: 940px;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        &::before {
          content: '';
          top: -18px;
          left: -20px;
          width: 63px;
          height: 63px;
          display: block;
          position: absolute;
          border-radius: 6px;
          background-color: $secondary;
          -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
        }
        h3 {
          font-size: 24px;
        }
        .cta {
          .theme-button {
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
  }
  .featured-schools {
    .schools-grid {
      width: 960px;
      padding-left: 42px !important;
      .school-item {
        width: 260px;
        margin: 0 25px !important;
      }
      &.slick-slider {
        button {
          &.slick-prev {
            left: -10px;
          }
          &.slick-next {
            right: -10px;
          }
        }
      }
    }
  }
  .bulletin-board {
    .bulletin-grid {
      .bulletin-item {
        width: 33.33%;
        padding: 0 13.5px;
        .bulletinCard {
          width: 100%;
          max-width: initial;
          border-radius: 3.5px;
          .card-image {
            height: 150px;
            text-align: center;
            img {
              max-height: 150px;
              max-width: 100%;
            }
          }
          .card-video {
            height: 150px;
            iframe {
              width: 100%;
              height: 100%;
              background-color: #000000;
            }
          }
          .info {
            height: 112px;
            margin: 0;
            .name {
              line-height: 21px;
              height: initial;
              a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .featured-schools {
    .schools-grid {
      width: 1140px;
      padding-left: 42px !important;
      .school-item {
        width: 320px;
      }
    }
  }
  .bulletin-board {
    .bulletin-grid {
      .bulletin-item {
        width: 25%;
      }
    }
  }
}
@media (max-width: 768px) {
  .home-hero-box {
    .geosuggest {
      position: initial;
    }
    .separator {
      margin-top: 40px;
      margin-bottom: 40px;
      .separator-line {
        width: 100%;
        margin: 0 auto;
        border-bottom: 1px solid #e6e6e6;
        height: 1px;
      }
      .separator-text {
        width: 26px;
        height: 26px;
        border-radius: 50px;
        position: absolute;
        border: 1px solid #e6e6e6;
        background: #fff;
        margin-top: -13px;
        left: 50%;
        margin-left: -12px;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        color: #29a0df;
        font-weight: bold;
      }
    }
  }
}
.card {
  @include themes.theme-aware('background-color', 'bg-default');
  @include themes.theme-aware('color', 'text');
  line-height: 1.25;
}

.bulletin-board {
  .bulletin-grid {
    .bulletin-item {
      .card-video {
        iframe {
          width: 100%;
          height: 100%;
          background-color: #000000;
        }
      }
    }
  }
}
