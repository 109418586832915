//about banner
@mixin arrow-text {
  width: 30%;
  display: flex;
  flex-direction: column;
  color: #001f29;
  font-size: 16px;
  line-height: 24px;
}
.about_banner {
  position: static;

  .title {
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: bold;
    color: #007b9c;
    margin: 0 0 16px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 0px;
    .button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      @media (min-width: 767px) {
        width: 40%;
        padding: 0 24px;
      }
    }
    .arrow_text_left {
      @include arrow-text;
      align-items: flex-end;
      justify-content: center;
      display: none;
      @media (min-width: 760px) {
        display: flex;
      }
      p {
        text-align: center;
        font-family: 'Rock Salt', cursive;
      }
    }
    .arrow_text_right {
      @include arrow-text;
      justify-content: flex-end;
      align-items: flex-start;
      display: none;
      @media (min-width: 760px) {
        display: flex;
      }
      p {
        text-align: center;
        margin-top: 14px;
        font-family: 'Rock Salt', cursive;
      }
    }
  }
}
