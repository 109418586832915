@import '../../../../../../sass/color-palette';

.label {
  color: $dark-blue-600;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 0.875rem;
  margin-bottom: 0.75rem;
}

.langSelector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.languageButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a3aab5;
  border-radius: 8px;
  width: 100%;
  height: 3rem;
  padding: 0.5rem 1rem;
  color: #1a2a45;

  &:focus {
    box-shadow: 0px 0px 0px 2px rgba(0, 153, 204, 0.25);
    outline: none;
  }
}

.selected {
  border: 1px solid $primary-blue;
  background: #e6f5fa;
}
