@use '../../../sass/mixins';
@use '../../../sass/themes';
/* Base */
select,
.dropdown {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
}

.dropdown-label {
  display: block;
  width: 100%;
  @include mixins.toRem('font-size', 14);
  margin-bottom: 0.5rem;
}

select,
.dropdown-select {
  @include themes.theme-aware('color', 'text');
  display: block;
  font-size: 0.85rem;
  @include mixins.toRem('line-height', 20);
  color: inherit;
  padding: 0.063rem 2rem 0.63rem 0.5rem !important;
  border-radius: 0.219rem !important;
  border: 1px solid;
  @include themes.theme-aware('border-color', 'border-default');
  background: none;
  background-repeat: no-repeat;
  background-image: url('https://static.schola.com/chevron-down-solid-grey.svg');
  background-size: 1rem 1rem;
  background-position: right 0.75rem center;
  height: 2.19rem !important;
  // Reset default select attributes
  appearance: none;
  // Hack for IE10+ to remove the default dropdown arrow they add
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;
    width: 100%;
  }
}

// Disabled cursor on parent label and all children elements
.dropdown--disabled,
.dropdown--disabled * {
  cursor: not-allowed !important;
  opacity: 0.75;
}

.dropdown--disabled .dropdown-select {
  @include themes.theme-aware('background-color', 'bg-primary-disabled');
}
