@import '../../../../../sass/color-palette';

.categoryContainer {
  /* display: flex;
  flex-direction: column; */
  gap: 1.5rem;
  /*   padding: 1.5rem 0; */
  @media screen and (min-width: 768px) {
    gap: 2rem;
    /* padding: 3rem 0; */
  }
}

.categoryDescription {
  color: $dark-blue;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  padding-bottom: 16px;
  margin-top: -1rem;
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

.specialNeedsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 64px 0 48px 0;
}

.academicsContainer {
  gap: 1.5rem;
  @media screen and (min-width: 768px) {
    gap: 32px;
    /* padding: 48px 0 48px 0; */
  }
}

.academicWrapper {
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 40px;
  }
}

.aboutContainer {
  align-self: start;
  justify-self: end;
  margin-top: -1rem;
  margin-bottom: 2.5rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  @media screen and (min-width: 768px) {
    gap: 2rem;
  }
}

.specialNeedsFeaturesContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.activitiesContainer > :first-child {
  padding-bottom: 16px;
}

.categoryTitle {
  color: #1a2a45;
  font-family: Open Sans;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 1rem;
}
