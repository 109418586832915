@import '../../../../../../sass/color-palette';

@mixin flex {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.students {
  @include flex;
  justify-content: space-between;

  &_counter {
    @include flex;
    gap: 1rem;

    & button {
      &:focus {
        outline: 4px auto $primary-blue;
      }
    }
  }
}

.studentHeader {
  @include flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  & svg {
    fill: #0c5e79;
  }
}

.buttonsGroup {
  @include flex;
}

.selectBox {
  position: relative;
}

.dropContainer {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.5px solid #cedfe4;
  background: #fff;

  position: absolute;
  top: 2.5rem;
  right: 0;
  left: 0;
  z-index: 1;

  &_scroll {
    max-height: 150px;
    overflow: scroll;
  }

  /* elevation-50 */
  box-shadow: 0px 2px 6px 0px rgba(33, 53, 86, 0.08);

  &_button {
    width: 100%;
    padding: 1rem;
    border-bottom: 0.5px solid rgba(129, 138, 153, 0.2);
    @include flex;
    justify-content: space-between;

    &:hover {
      background: rgba(230, 245, 250, 0.48);
    }

    & svg {
      fill: #818a99;
    }
  }

  &_name {
    @include flex;
  }
}

.placeSelection {
  @include flex;
  border-radius: 0.5rem;
  border: 1px solid var(--light-dark-blue-300-border-disabled-text, #a3aab5);
  background: #fff;
  min-height: 2.25rem;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;

  &_name {
    color: var(--light-dark-blue-500-secondary-text-icon, #5f6a7d);
    font-size: 1rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }

  &_arrow {
    position: absolute;
    top: calc(50% - 0.5rem);
    right: 1rem;
  }
}

.addStudent {
  @include flex;
  color: #006b8f;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;

  &:hover {
    background: rgba(230, 245, 250, 0.48);
  }

  &_name {
    @include flex;
  }
}

.errorLabel {
  color: #ff444f;
  font-size: 12px;
  margin: 0.3rem 0.2rem;
}
