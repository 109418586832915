@use 'mixins';

$spacing-01: 4; // px unit  == 0.25rem
$spacing-02: 8; // px unit  == 0.5rem
$spacing-03: 12; // px unit == 0.75rem
$spacing-04: 16; // px unit == 1rem
$spacing-05: 20; // px unit == 1.25rem
$spacing-06: 24; // px unit == 1.5rem
$spacing-07: 32; // px unit == 2rem
$spacing-08: 40; // px unit == 2.5rem
$spacing-09: 48; // px unit == 3rem
$spacing-10: 56; // px unit == 3.5rem
$spacing-11: 64; // px unit == 4rem
$spacing-12: 80; // px unit == 5rem

$units: $spacing-01, $spacing-02, $spacing-03, $spacing-04, $spacing-05, $spacing-06, $spacing-07, $spacing-08,
  $spacing-09, $spacing-10, $spacing-11, $spacing-12;

@mixin spacing($spacing) {
  ////////////////// MARGIN
  .spacing-m-#{$spacing} {
    @include mixins.toRem('margin', $spacing);
    // return ej: .spacing-m-16 { margin: 1rem }
  }
  .spacing-mt-#{$spacing} {
    @include mixins.toRem('margin-top', $spacing);
    // return ej: .spacing-mt-16 { margin-top: 1rem }
  }
  .spacing-mr-#{$spacing} {
    @include mixins.toRem('margin-right', $spacing);
    // return ej: .spacing-mr-16 { margin-right: 1rem }
  }
  .spacing-mb-#{$spacing} {
    @include mixins.toRem('margin-bottom', $spacing);
    // return ej: .spacing-mb-16 { margin-bottom: 1rem }
  }
  .spacing-ml-#{$spacing} {
    @include mixins.toRem('margin-left', $spacing);
    // return ej: .spacing-ml-16 { margin-left: 1rem }
  }
  .spacing-mx-#{$spacing} {
    @include mixins.toRem('margin-right', $spacing);
    @include mixins.toRem('margin-left', $spacing);
    // return ej: .spacing-mx-16 { margin-left: 1rem }
  }
  .spacing-my-#{$spacing} {
    @include mixins.toRem('margin-top', $spacing);
    @include mixins.toRem('margin-bottom', $spacing);
    // return ej: .spacing-mx-16 { margin-left: 1rem }
  }
  ////////////////// PADDING
  .spacing-p-#{$spacing} {
    @media (min-width: 320px) {
      @include mixins.toRem('padding', $spacing);
      // return ej: .spacing-p-16 { padding: 1rem }
    }
  }
  .spacing-sm-p-#{$spacing} {
    @media (min-width: 576px) {
      @include mixins.toRem('padding', $spacing);
    }
  }

  .spacing-pt-#{$spacing} {
    @include mixins.toRem('padding-top', $spacing);
    // return ej: .spacing-pt-16 { padding-top: 1rem }
  }
  .spacing-pr-#{$spacing} {
    @include mixins.toRem('padding-right', $spacing);
    // return ej: .spacing-pr-16 { padding-right: 1rem }
  }
  .spacing-pb-#{$spacing} {
    @include mixins.toRem('padding-bottom', $spacing);
    // return ej: .spacing-pb-16 { padding-bottom: 1rem }
  }
  .spacing-pl-#{$spacing} {
    @include mixins.toRem('padding-left', $spacing);
    // return ej: .spacing-pl-16 { padding-left: 1rem }
  }
  .spacing-px-#{$spacing} {
    @include mixins.toRem('padding-right', $spacing);
    @include mixins.toRem('padding-left', $spacing);
    // return ej: .spacing-px-16 { padding-left: 1rem }
  }
  .spacing-py-#{$spacing} {
    @include mixins.toRem('padding-top', $spacing);
    @include mixins.toRem('padding-bottom', $spacing);
    // return ej: .spacing-px-16 { padding-left: 1rem }
  }
}
.spacing-p-0 {
  padding: 0 !important;
}
//  classNames building:
@each $unit in $units {
  @include spacing($unit);
}
// mixins for modules scss
@mixin spacing-m($arg) {
  @include mixins.toRem('margin', $arg);
}
@mixin spacing-mt($arg) {
  @include mixins.toRem('margin-top', $arg);
}
@mixin spacing-mr($arg) {
  @include mixins.toRem('margin-right', $arg);
}
@mixin spacing-mb($arg) {
  @include mixins.toRem('margin-bottom', $arg);
}
@mixin spacing-ml($arg) {
  @include mixins.toRem('margin-left', $arg);
}
@mixin spacing-p($arg) {
  @include mixins.toRem('padding', $arg);
}
@mixin spacing-pt($arg) {
  @include mixins.toRem('padding-top', $arg);
}
@mixin spacing-pr($arg) {
  @include mixins.toRem('padding-right', $arg);
}
@mixin spacing-pb($arg) {
  @include mixins.toRem('padding-button', $arg);
}
@mixin spacing-pl($arg) {
  @include mixins.toRem('padding-left', $arg);
}
