.search {
  margin-bottom: 1rem;
  padding: .25rem;
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #c6cad1;
  border-radius: 0.25rem;

  & input {
    border: none !important;
    font-size: 0.75rem;

    &:focus {
      outline: none;
      font-size: 14px;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    display: grid;
    place-items: center;

    & svg {
      fill: #818A99;
    }
  }
}
.searchList {
  background: #fff;
  border: 1px solid #c6cad1;
  border-radius: 0.25rem;

  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  z-index: 1;

  & .item {
    font-size: 0.75rem;
    padding: 1rem;
    border-bottom: 1px solid #f3f5f7;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }
}