.detailsSection {
  background: rgba(230, 245, 250, 0.5);
  margin-top: 5rem;
  padding-bottom: 4rem;
  padding-top: 26px;
  @media screen and (min-width: 768px) {
    padding-bottom: 26px;
  }
}

.container {
  margin: auto;
  max-width: 1035px;
  margin: 0 auto;
}

.content {
  background-color: #ffffff;
  padding: 0 1rem;
}

.schoolName {
  font-size: 54px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.title {
  text-align: center;
  padding: 2rem 0 1rem;
}

.infoContainer {
  padding-bottom: 2rem;
}

.display {
  @media screen and (min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.header {
  position: relative;
}
.matchLabelContainer {
  padding: 12px 0;
}
/* .matchLabel {
  margin-top: 1rem;
  margin-bottom: 1rem;
} */
.image {
  border-radius: 4px;
  overflow: hidden;
  height: 161px;
  @media (min-width: 767px) {
    height: 100%;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.contentFeatures {
  margin: 4rem 0 2rem;
  display: grid;
  gap: 1rem;

  @media screen and (min-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

.schoolDescription {
  font-size: 14px;
  line-height: 22px;
  margin: 1.5rem 0;
  @media (min-width: 768px) {
    margin: 0;
  }
}

.missionCTA {
  padding: 22px 47px;
  margin-bottom: 12px !important;
  @media screen and (min-width: 768px) {
    padding: 39px 57px;
    margin-bottom: 29px !important;
  }
}
.caption {
  font-size: 14px;
  line-height: 22px;
}

.missionLabel {
  margin: 1rem 0;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.buttonContainer {
  position: relative;
}

.tooltip {
  font-size: 14px;
  line-height: 22px;
  background: #fbe9ed;
  border: 1px solid #c11f3f;
  color: #c11f3f;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 0.35rem;
  border-radius: 2px;
  width: 210px;
  height: fit-content;

  & b {
    font-weight: 700;
  }

  position: absolute;

  bottom: -3rem;
  right: 0;
  z-index: 1;

  &::after {
    content: '';
    width: 13px;
    height: 13px;
    background: #fbe9ed;
    border-top: 1px solid #c11f3f;
    border-left: 1px solid #c11f3f;
    position: absolute;
    top: -7px;
    left: 20px;
    transform: rotate(45deg);
  }
}
.linkToMap {
  font-size: 14px;
  color: #006b8f;
  text-decoration: underline;
  margin-top: 2rem;
}
