@import '../../../sass/color-palette';
@import '../../../sass/mixins';

@mixin profileWrapper {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1300px) {
    max-width: 75rem;
  }
}

.schoolProfile {
  @include toRem('margin-top', 82);
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 767px) {
    @include toRem('margin-top', 92);
  }

  .reviewAndTestimonial {
    @include toRem('padding-top', 48);
    @include toRem('margin-top', 48);
    border-top: 1px solid $dark-blue-15;
  }

  .section {
    padding: 0;
    margin-bottom: 7.5rem;
    @include profileWrapper();
  }
  .greenBreak {
    background: $green-300;
    @include toRem('border-radius', 80);
    @include toRem('width', 80);
    @include toRem('height', 8);
    margin: 48px auto;
  }
  .missionCta {
    @include toRem('margin-bottom', 80);
  }

  nav::-webkit-scrollbar {
    display: none;
  }
}

.contactModal:global(.schola-modal-child) {
  min-width: 340px;
  max-height: 600px;
  overflow-y: scroll;
}

.nearby {
  border: 1px solid red;
  max-width: 900px;
  height: 300px;
  // overflow: scroll;
}
