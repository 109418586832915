.selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 31, 41, 1);
  padding-bottom: 0.3rem;
  position: relative;
  width: 90%;
  margin: 1rem auto;

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.current {
  border: 0.5px solid rgba(95, 106, 125, 1);
  background: rgba(255, 255, 255, 1);
  border-radius: 100px;
  padding: 0.3rem 0.5rem;
  font-size: 14px;
}

.droplist {
  background: rgba(255, 255, 255, 1);
  border: 0.5px solid rgba(95, 106, 125, 1);
  position: absolute;
  top: 2.5rem;
  width: 100%;
  border-radius: 0.5rem;
  z-index: 1;
}
.item {
  padding: 0.6rem 0.5rem;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 0.5px solid rgba(95, 106, 125, 1);

  &:last-child {
    border-bottom: none;
  }
}

// TABS
.tabs {
  display: none;

  @media screen and (min-width: 900px) {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.tab {
  font-size: 14px;
  background: rgba(77, 184, 219, 1);
  color: #fff;
  border-radius: 2px 2px 0px 0px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
.currentTab {
  background: rgba(0, 107, 143, 1);
}
