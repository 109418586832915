@import '../../../../../../sass/color-palette';

.disclaimer {
  margin-bottom: 1.5rem;
  display: flex;
  gap: 0.5rem;

  svg {
    min-width: 1rem;
    fill: $primary-500;
    margin-top: 0.25rem;
  }

  a {
    color: $primary-500 !important;
    text-decoration: underline;
  }
}
