@use '../themes';
@use '../mixins';
@use '../variables';

.main-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 75px;
}

.contact-page {
  @include themes.theme-aware('background-color', 'bg-primary');
}

.border-dashed {
  border-width: 2px;
  border-style: dashed;
  @include themes.theme-aware('border-color', 'border-default');
}

.main-layout__children {
  z-index: 0; // Allows main content to sit on top of header ribbon
  min-height: calc(100vh - 404px); // 404 = header + footer height mobile/tablet-portrait
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: variables.$screen-sm) {
    min-height: calc(100vh - 530px); // 294 = header + footer height tablet-landscape/desktop
  }

  @media (min-width: variables.$tablet-landscape) {
    min-height: calc(100vh - 294px); // 294 = header + footer height tablet-landscape/desktop
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.container {
  width: 100%;
  max-width: variables.$desktop;
  margin: 0 auto;
  padding-left: 8px;
  padding-right: 8px;

  @media (min-width: variables.$tablet-portrait) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.paper {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: variables.$card-border-radius;
  box-shadow: variables.$card-box-shadow;

  @media (min-width: variables.$tablet-portrait) {
    padding: 24px;
  }

  @media (min-width: variables.$tablet-landscape) {
    padding: 32px;
  }
}

.paper__heading {
  h3 {
    margin-bottom: 16px;
  }
  p {
    max-width: 520px;
  }
}

.paper__divider {
  width: 100%;
  height: 1px;
  @include themes.theme-aware('background-color', 'dark-blue-200');
  border: none;
  margin: 40px auto;
}

.text-center {
  @media (min-width: 320px) {
    text-align: center;
  }
}
.text-md-center {
  @media (min-width: 768px) {
    text-align: center;
  }
}

img {
  display: inline-block;
  max-width: 100%;
}

.body-lock {
  overflow: hidden;
  position: fixed;
}

.breadcrumb {
  width: 100%;
  position: absolute;
  top: 0;
}

.breadcrumb > span {
  float: left;
  width: 20%;
  height: 8px;
  border-right: 3px solid #ffffff;
}

.breadcrumb > span.active {
  @include themes.theme-aware('background-color', 'primary');
}
.upload-file-content {
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .icon-upload-file {
    color: #adb1bd;
    font-size: 3rem;
  }
}
.btn-success {
  height: 46px;
  padding: 12.5px 24px;
  border-radius: 5px !important;
  @include themes.theme-aware('background-color', 'bg-tertiary');
}
.text-green {
  @include themes.theme-aware('background-color', 'bg-tertiary');
}
.bg-red {
  background: #ff5e5e;
}
.bg-yellow {
  background: #f8d55c;
}
.bg-green {
  @include themes.theme-aware('background', 'bg-tertiary');
}
.bg-green-light {
  @include themes.theme-aware('background', 'bg-tertiary');
}
.text-white {
  color: #fff !important;
}
.text-black {
  color: #000 !important;
}
.text-lightblack {
  color: #adb1bd !important;
}
.text-grey-5 {
  color: #91949f !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left !important;
}
.text-blue {
  @include themes.theme-aware('color', 'primary');
}
.text-red {
  color: #ff5e5e !important;
}
.text-yellow {
  color: #f8d55c !important;
}
.font-normal {
  font-weight: normal !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-600 {
  font-weight: 600 !important;
}
.font-500 {
  font-weight: 500 !important;
}
.btn-success {
  @include themes.theme-aware('background', 'bg-tertiary');
}
.border-none {
  border: none !important;
}
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-13 {
  font-size: 13px;
}
.font-18 {
  font-size: 18px;
}
.cursor-pointer {
  cursor: pointer;
}
.icon-w12 {
  width: 12px;
}
.border-blue {
  @include themes.theme-aware('border-color', 'border-primary');
  border-width: 1px;
  border-style: solid;
}
.modal-loading .modal__header,
.modal-loading .modal__title,
.modal-loading .modal__footer,
.modal-loading .modal-footer {
  display: none !important;
}
.modal-loading {
  .modal__content {
    background: none;
    box-shadow: none;
  }
  .modal__overlay {
    background: rgba(255, 255, 255, 0.5) !important;
  }
}
.img-loading-search {
  position: fixed;
  z-index: 99;
  top: 40%;
  left: 45%;
  width: 200px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.9);
  @media (max-width: 480px) {
    left: 25%;
    top: 30%;
  }
}
.schola-select {
  font-size: 12px;
  color: #c9cdd7 !important;
  padding: 0;
  .schola__value-container {
    padding: 0 8px;
    height: 36px;
    & > div {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      input {
        height: 30px !important;
      }
    }
  }
  .schola__menu {
    color: #484848 !important;
  }
  .schola__control {
    box-shadow: none !important;
    min-height: 33px;
    border-radius: 3.5px;
    border: 0.7px solid #dddfe7;
    .schola__indicator-separator {
      margin-bottom: 12px;
      margin-top: 9px;
    }
    &:hover {
      @include themes.theme-aware('border-color', 'bg-tertiary');
    }
  }
}
.rc-slider,
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  width: 85% !important;
  margin-top: 7px;
}
.rc-slider-rail,
.rc-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-rail {
  width: 100%;
  background-color: #e9e9e9;
}
.rc-slider-track {
  background-color: #abe2fb;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: 2px solid #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-mark-text-active {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
}
