@import '../../../../../sass/color-palette';

.container {
  container-type: inline-size;
}

.enrollment {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.enrollment_icon {
  width: 2.5rem;
}

.bookImage {
  width: 100%;
}

.content {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  .description {
    color: $dark-blue;
    text-align: start;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: -0.01rem;
  }
}

.heroContainer {
  order: 2;
  @media (min-width: 768px) {
    order: 1;
  }
  .heroEnrollment {
    padding: 0.5rem 1rem;
    background: rgba(254, 239, 216, 0.5);
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    & img {
      width: 16px;
      height: 22px;
    }
  }

  .heroContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    .description {
      color: $dark-blue;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
      letter-spacing: -0.00875rem;
    }
  }
}

.deadline {
  color: #5f1a29;
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.00875rem;
}

/* Para anchos de pantalla entre 520px y 768px */
@media (min-width: 520px) and (max-width: 768px) {
  .heroContainer .heroContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
}

/* Para anchos de pantalla menores de 520px o entre 769px y 1030px */
@media (max-width: 520px), (min-width: 769px) and (max-width: 1030px) {
  .heroContainer .heroContent {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

/* Para anchos de pantalla mayores de 1030px */
@media (min-width: 1031px) {
  .heroContainer .heroContent {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .heroContainer .description {
    color: $dark-blue;
    text-align: start;
    font-family: Open Sans;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.00875rem;
  }

  .deadline {
    font-size: 0.8rem;
  }
}
