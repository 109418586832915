.drawer {
  &_overlay {
    background: rgba(33, 53, 86, 0.55);
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0px;
    top: 0px;
    z-index: 10000;

    animation: fadeInBack .2s ease-in-out;

  }

  &_container {
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 10000;

    animation: translateUp .2s ease-in-out;


    &_header {
      text-align: center;
      border-radius: 8px 8px 0 0;
      padding: 1rem;
      position: relative;
      font-weight: 600;

      &_icon {
        position: absolute;
        top: 35%;
        right: 6px;
        cursor: pointer;
      }
    }

    &_content {
      margin: 8px 16px 0;
      padding-bottom: 40px;
    }
  }
}

.hideContainer {
  transform: translateY(100%);
  transition: .3s;
}

.hideOverlay {
  opacity: 0;
  transition: .4s;
}

@keyframes translateUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeInBack {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}