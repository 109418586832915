@import '../../../../../sass/color-palette';

.loadingWrapper {
  background: white;
  text-align: center;

  display: grid;
  place-items: center;
  height: 100vh;

  // to work as a modal
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
}

.containerLine {
  font-size: 18px;
  font-weight: 700;
  line-height: 160%;
  overflow: hidden;
  padding: 0 2rem;

  @media screen and (min-width: 700px) {
    font-size: 32px;
  }
}

.wordChange {
  display: inline-block;
  color: $primary;
  text-transform: capitalize;
  animation: wordUP 1.5s;
  transform: translateY(100%);
}

@keyframes wordUP {
  0% {
    transform: translateY(-50%);
  }

  10% {
    transform: translateY(0%);
  }

  90% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}