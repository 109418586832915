@use '../../../sass/themes';

$radio-padding : .75rem;

.radio {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: -($radio-padding);
    @include themes.theme-aware('color','text');
}

.radio-icon {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 1.5rem + ($radio-padding * 2);
    height: 1.5rem + ($radio-padding * 2);
    border-radius: 100%;
    padding: $radio-padding;
    flex-shrink: 0;
}

.radio-icon:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    @include themes.theme-aware('background-color','bg-primary');
    transform: scale(0, 0);
    opacity: 0.35;
    transition-property: transform, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0;
}

.radio--checked .radio-icon:after {
    transform: scale(1, 1);
    opacity: 0;
    transition-duration: 500ms;
}

.radio-label {
    font-weight: 500;
    font-size: 1.125rem;
    @include themes.theme-aware('color','text');

    @media (min-width: $tablet-landscape) {
        font-size: 1.25rem;
    }
}

.radio-icon + .radio-label {
    padding-top: $radio-padding;
    padding-right: $radio-padding;
    padding-bottom: $radio-padding;
}

/* Hide HTML input */
.radio input[type="radio"] {
    display: none;
}

.radio + .radio {
    margin-left: 1rem;
}

// Disabled cursor on parent label and all children elements
.radio--disabled,
.radio--disabled * {
    cursor: not-allowed !important;
}
.radio--disabled .radio-icon {
    opacity: 0.75;
}
.radio--disabled .radio-label {
    opacity: 0.5;
}
