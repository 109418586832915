@use "sass:math";
@use "themes";
@import "variables";

// mixin receibe rule property and valud like pixel for return property like rem
@mixin toRem($property, $value) {
  $remvalue: math.div($value, 16);
  #{$property}: $remvalue + rem;
}

@mixin padding($topBottom, $leftRight) {
  @include toRem('padding-top', $topBottom);
  @include toRem('padding-right', $leftRight);
  @include toRem('padding-bottom', $topBottom);
  @include toRem('padding-left', $leftRight);
}

@mixin margin($topBottom, $leftRight) {
  @include toRem('margin-top', $topBottom);
  @include toRem('margin-right', $leftRight);
  @include toRem('margin-bottom', $topBottom);
  @include toRem('margin-left', $leftRight);
}

@mixin panel {
  background-color: #fff;
  @include toRem('border-radius', 16);
  box-shadow: 0 2px 11px 0 $dark-blue-25;
}
@mixin dropDownPanel() {
  background: #ffffff;
  border: 1px solid rgba(47, 72, 113, 0.1);
  box-shadow: 0px 2px 6px rgba(33, 53, 86, 0.08);
  border-radius: 0.5rem;
}
// Mixin for buttons color
@mixin button-tertiary($isModule) {
  color: white !important;
  @if $isModule {
    @include themes.themify(themes.$themes){
      background-color: themes.themed('bg-tertiary');
    }
  } @else {
    @include themes.theme-aware('background-color', 'bg-tertiary');
  }
  &:hover {
    color: #ffffff;
    text-decoration: none;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-tertiary-hover');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-tertiary-hover');
    }
  }
  &:active {
    text-decoration: none;
    color: #ffffff;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-tertiary-active');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-tertiary-active');
    }
  }
  &:focus {
    color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
    text-decoration: none;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-tertiary-pressed');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-tertiary-pressed');
    }
  }
}

@mixin button-primary($isModule) {
  color: #ffffff !important;
  @if $isModule {
    @include themes.themify(themes.$themes){
      background-color: themes.themed('bg-primary');
    }
  } @else {
    @include themes.theme-aware('background-color', 'bg-primary');
  }
  &:hover {
    color: #ffffff;
    text-decoration: none;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-primary-hover');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-primary-hover');
    }
  }
  &:active {
    text-decoration: none;
    color: #ffffff;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-primary-active');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-primary-active');
    }
  }
  &:focus {
    color: #ffffff;
    border: 0.063rem solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
    text-decoration: none;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-primary-pressed');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-primary-pressed');
    }
  }
}
@mixin button-secondary($isModule) {
  border-width: 0.063rem;
  border-style: solid;
  @if $isModule {
    @include themes.themify(themes.$themes){
      background-color: themes.themed('bg-subtle');
      color: themes.themed('text-link-primary');
      border-color: themes.themed('border-default');
    }
  } @else {
    @include themes.theme-aware('background-color', 'bg-subtle');
    @include themes.theme-aware('color','text-link-primary');
    @include themes.theme-aware('border-color','border-default');
  }
  &:hover {
    color: #ffffff;
    text-decoration: none;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-primary-hover');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-primary-hover');
    }
  }
  &:active {
    text-decoration: none;
    color: #ffffff;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-primary-active');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-primary-active');
    }
  }
  &:focus {
    color: #ffffff;
    border: 0.063rem solid #ffffff;
    box-shadow: 0 0 0 1.5px rgba(0, 140, 255, 0.25);
    outline: none;
    text-decoration: none;
    @if $isModule {
      @include themes.themify(themes.$themes){
        background-color: themes.themed('bg-primary-pressed');
      }
    } @else {
      @include themes.theme-aware('background-color', 'bg-primary-pressed');
    }
  }
}

@mixin modalCTAconfig() {
  .schola-modal-child-header,
  .schola-modal-child-close {
    padding-right: 1rem;
  }

  .schola-modal-child {
    padding-right: 0;

    @media (max-height: 567px) {
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
    }
    @media (min-width: 667px) {
      height: 90vh;
      min-width: 25rem;
      max-width: 33.75rem;
      max-height: fit-content;
    }
  }

  .form-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1rem;
    overflow-y: auto;
    height: 73vh;

    @media (min-height: 768px) {
      height: 77vh;
    }

    .scroll-overlay {
      overflow-y: auto;
      padding-bottom: 6vh;

      .form-body-scroll {
        position: relative;
        padding-right: 1rem;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        width: 100%;
        height: 10%;
      }
      &:after {
        bottom: 3rem;
        background: none;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }
    }
  }
}