@import '../../../../../sass/color-palette';
@import '../../../../../sass/mixins';
@import '../../../../../sass/spacing';

.more-about {
  width: 100%;
  height: max-content;
  border-bottom: 0.0625rem solid $dark-blue-200;

  .title {
    font-size: 1.5rem !important;
    font-weight: 600;
    line-height: 2rem;
    color: $dark-blue;
    text-transform: capitalize;
    margin-bottom: 1rem;
    /* @include toRem('margin-bottom', 24);
    @media screen and (min-width: 768px) {
      @include toRem('margin-bottom', 32);
    } */
  }

  .video {
    width: 100%;
    @include toRem('height', 158);
    @include toRem('height', 350);
    @media screen and (min-width: 768px) {
      margin-bottom: 1.4rem;
    }
  }

  .iframe {
    height: 100%;
    overflow: hidden;
  }

  .description {
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: -0.01rem;
    color: $dark-blue;
    white-space: pre-line;
    @media screen and (min-width: 768px) {
      margin-bottom: 2.5rem;
      letter-spacing: -0.00875rem;
      /* @include margin(40, 0); */
    }
  }

  .bannerContainer {
    padding-bottom: 2.5rem;
  }

  .extendedFeaturesContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @media screen and (min-width: 768px) {
      gap: 1.5rem;
    }
  }

  .socialLinksContainer {
    display: flex;
    flex-direction: column;
    padding-top: 2.5rem;
    gap: 1.5rem;
    & > h5 {
      color: var(--light-dark-blue-700-text-icon, var(--legacy-light-dark-blue-700-text-icon, #1a2a45)) !important;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 1.5rem !important;
    }
  }

  .resetStyles {
    strong {
      font-weight: bold;
    }
    em {
      font-style: italic;
    }

    ol,
    ul {
      padding-inline-start: 40px;
    }
    ol {
      list-style: decimal;
    }
    ol[type='a'] {
      list-style-type: lower-alpha;
    }
    ol[type='i'] {
      list-style-type: lower-roman;
    }
    ul {
      list-style-type: disc;
    }
  }
}

.box {
  color: #1A2A45;
  text-align: left;
  background-color: #FFFFFF;
  border: 1px solid #F3F5F7;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  margin-bottom: 1rem;

  .subtitle {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 8px;
  }
  .text {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }
}
