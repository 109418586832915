
.header-logo {
    width: 152px;
}

.search-results {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.search-results__no-results {
    padding: 32px;
    min-width: 100%;
    p {
        margin-bottom: 16px;
    }
}

.search-results h2 {
    text-transform: uppercase;
    font-size: 20px;
    color: $schola-lightgrey;
}

.search-results .checkbox-label {
    font-size: 16px;
}

.search-results__pagination {
    text-align: right;
    margin: 48px 35px 24px;
    width: 60%;
    float: right;
    .pagination-controls{
        height: 100%
    }

}

.search-results__filters {
    width: 100%;
    padding: 16px;
    margin: 0;

    h4 {
        margin-bottom: 16px;
        font-size: 20px;
    }

    .search-results__filters--location {
        margin-bottom: 24px;
    }

    .search-results__filters--location__address {
        font-size: 16px;
        font-weight: $medium;
    }

    .radius-selector {
        max-width: 200px;
    }

    .grade-selector {
        max-width: 160px;
    }
}

.search-results__filters h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 20px;

    a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $medium;
        font-size: 14px;
    }

    a, a:link, a:visited {
        color: $schola-lightgrey;
    }
}

@media (min-width: $tablet-landscape) {
    .search-results__filters {
        width: 300px;
        margin: 0 auto;
        flex-shrink: 0;
        flex-grow: 0;
        margin:  0 32px 0 0;

    }

    // Automatically space all filters
    .search-results__filters > * + * {
        margin-top: 32px;
    }

    .search-results__filters h2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
            // TODO Clear filter link
            text-transform: uppercase;
            font-size: 16px;
            font-weight: $light;
        }
    }

    .search-results__filters--location {
        .search-results__filters--location__address {
            font-size: 16px;
            font-weight: $medium;
        }
    }
}

// Individual Results
.search-result {
    width: 90%;
    margin-left: 5%;
    padding: 32px 0;
    display: flex;
    flex-direction: column;
}

.search-result__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    .button {
        flex-shrink: 0;

        @media (max-width: $tablet-portrait) {
            display: none;
        }
    }


}

// Automatically space the rating, name, and distance text
.search-result__header > * + * {
    margin-left: 24px;
}

.search-result__header--rating {
    width: 40px;
    height: 40px;
    background: $primary;
    color: #ffffff;
    font-size: 24px;
    font-weight: $medium;
    border-radius: 100px;
    text-align: center;
    line-height: 40px;
    flex-shrink: 0;
}

.search-result__header--no-rating {
    width: 40px;
    height: 40px;
    background: $schola-mist;
    color: #ffffff;
    font-size: 18px;
    font-weight: $medium;
    line-height: 40px;
    text-align: center;
    border-radius: 100px;
}

.search-result__header--name {
    font-size: 20px;
    font-weight: $medium;
    flex-grow: 1;

    @media (min-width: $tablet-portrait) {
        font-size: 24px;
    }
}

.search-result__header--distance {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: $medium;
    line-height: 16px;
    flex-shrink: 0;

    .icon {
        display: inline-block;
    }
}

.search-result__details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 32px;

    @media (min-width: $tablet-portrait) {
        flex-wrap: nowrap;
    }
}

.search-result__details--info {
    font-size: 18px;
    font-weight: $medium;

    .icon {
        display: inline-block;
        flex-shrink: 0;
        margin-right: 8px;
    }
}

// Automatically space the address, type, grades, etc.
.search-result__details--info > * + * {
    margin-top: 16px;
}

.search-result__details--address,
.search-result__details--type,
.search-result__details--grades {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    line-height: 24px;
    font-size: 16px;

    @media (min-width: $tablet-portrait) {
        font-size: 18px;
    }
}

.search-result__details--description {
    margin-top: 16px;
    font-size: 16px;
    font-weight: $regular;

    @media (min-width: $tablet-portrait) {
        margin-top: 32px;
    }
}

.search-result__endorsements {
    max-width: 360px;
    margin-top: 24px;

    @media (min-width: $tablet-portrait) {
        margin-top: 0;
    }
}

.search-result__button-mobile {
    margin: 24px auto 0 auto;

    @media (min-width: $tablet-portrait) {
        display: none !important;
    }
}

.search-results__results {
    width: 100%;
}

.seats--availables {
    margin-left: 5px;
    margin-top: 5px;
    position: relative;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    float: left;
    text-align: center;
    color: red;
    width: 144px;
    text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #FFf, 0 -0.5px #fff;
}
.seats--availables-nowidth{
    width: 100% !important;
}
.map-list-school__image img{
    float: left
}

.gs-div-container{
    display: none !important;
    }
