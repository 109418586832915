@import '../../school-profile.module.scss';

@mixin item {
  background: #e0e0e0;
  border-radius: 0.5rem;
  width: 100%;
  height: 7.5rem;
  animation: skeleton 1.2s ease-in-out infinite;
}

@keyframes skeleton {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.loading {
  @include profileWrapper();
  margin-top: 2.5rem;
  height: 35rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  @media screen and (min-width: 525px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 767px) {
    grid-template-columns: 60% 40%;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.picture {
  @include item();
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.name {
  @include item();
  height: 2.25rem;
  width: 50%;
}

.block {
  @include item();
}

.fullWidth {
  @include item();
  margin-top: 75px;
  height: 20rem;
}
.shared {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  .button {
    @include item();
    width: 100px;
    height: 45px;
  }
}
.column {
  &_item {
    @include item();
    height: 90px;
    margin-bottom: 2rem;
    &_cta {
      @include item();
      height: 200px;
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
