.sponsoredGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 21px;
  margin-bottom: 1rem;
  @media (min-width: 767px) {
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  }
}

.mainList {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.skeletonCard {
  grid-template-columns: 235px 1fr;
  display: grid;
  gap: 1rem;

  & div {
    background: #e6e9ec;
    border-radius: .25rem;
  }

  width: 100%;
  height: 250px;
}

.notFound {
  text-align: center;
  padding: 2rem 0;
  & img {
    margin-top: 2rem;
    width: 350px;
  }
}