.container_type {
  margin-bottom: 1rem;
}
.title_filter {
  padding: 1rem;
  font-weight: 700;
  text-align: center;
}

@mixin checkbox-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.checkLevel0 {
  @include checkbox-row;
}
.checkLevel1 {
  @include checkbox-row;
  padding-left: 1rem;
}
.checkLevel2 {
  @include checkbox-row;
  padding-left: 2.5rem;
}

.check {
  accent-color: #006B8F;
}

.filtersHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: .6rem;
  margin: 1rem 0;

  & button {
    display: block;
  }

  @media (min-width: 767px) {
    margin-top: 0;
    margin-bottom: 1rem;

    & button {
      display: none;
    }
  }
}