@import '../../../../sass/mixins';
@import '../../../../sass/color-palette';

.extendedFeatureContainer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  .icon {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    & svg {
      fill: $dark-blue-500-secondary-text;
    }
  }
  .text {
    h4 {
      color: #1a2a45;
      @include toRem('font-size', 16);
      font-style: normal;
      @include toRem('line-height', 24);
      letter-spacing: -0.16px;
      margin-bottom: 14px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem 0.25rem;
      @media (min-width: 768px) {
        padding-left: 2rem;
        gap: 0.5rem 0.75rem;
      }
      li {
        list-style: none;
        display: inline;
        padding: 0.125rem 0.75rem;
        min-height: 1.25rem;
        color: #5f6a7d;
        border-radius: 20px;
        font-size: 12px;
        border: 0.8px solid var(--light-secondary-200-subtle-border-hover, #cedfe4);
        display: flex;
        align-items: center;
        @media (min-width: 768px) {
          padding: 2px 12px;
          min-height: 1.5rem;
        }
      }
    }
  }
}
