.title {
  color: #1A2A45;
  font-size: 14px;
  font-size: 14px;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.text {
  color: #5F6A7D;
  font-size: 12px;
  font-family: Open Sans;
  line-height: 16px;
  letter-spacing: -0.12px;
}
