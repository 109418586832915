@import '../../../../../../sass/color-palette';

.feedback {
  text-align: center;
}

.danger {
  fill: $danger;
}

.warning {
  fill: $warning;
}

.content {
  opacity: 0;
  animation: fadeIn ease-in 0.4s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
