@import '../../../../sass/mixins';
@import '../../../../sass/color-palette';

.container {
  background: #fff;

  &_content {
    padding-top: 1rem;
    padding-left: 1rem;
  }

  & label {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.inputText {
  width: 100%;
}

.buttonsGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-right: 1rem;
  @media screen and (min-width: 767px) {
    padding-bottom: 1rem;
  }
}

.barProgress {
  height: 0.5rem;
  background: $primary;
  transition: 0.4s;
}

.contactModal :global(.schola-modal-child) {
  max-width: 480px;
}

.contactContent {
  height: 530px;
  overflow-y: scroll;
}

.other {
  margin: 0 1rem;

  &_list {
    li:last-child {
      border-bottom: none;
    }
  }
}

.hideFooter {
  display: none;
}
.nortonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.norton {
  width: 120px;
  height: 75px;
}
