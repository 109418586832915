.settingButton {
  color: #3C4A61;
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 1rem;
  background: #fff;
  border: 1px solid #c6cad1;
  border-radius: .25rem;
}

.settings {
  padding-right: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: .5rem;
  width: 100%;

  @media (min-width: 767px) {
    display: none;
  }
}
