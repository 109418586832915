@use 'themes';
@use 'mixins';
//  classes for color text
.text-white {
  color: white;
}
.text-light-primary-600 {
  @include themes.theme-aware('color', 'primary-600');
}
.text-light-primary-500 {
  @include themes.theme-aware('color', 'primary-500');
}
.text-light-primary-400 {
  @include themes.theme-aware('color', 'primary-400');
}
.text-light-primary-300 {
  @include themes.theme-aware('color', 'primary-300');
}
.text-light-primary-100 {
  @include themes.theme-aware('color', 'primary-100');
}
//  classes for color background
.bg-light-primary-600 {
  @include themes.theme-aware('background-color', 'primary-600');
}
.bg-light-primary-500 {
  @include themes.theme-aware('background-color', 'primary-500');
}
.bg-light-primary-400 {
  @include themes.theme-aware('background-color', 'primary-400');
}
.bg-light-primary-300 {
  @include themes.theme-aware('background-color', 'primary-300');
}
.bg-light-primary-100 {
  @include themes.theme-aware('background-color', 'primary-100');
}

// classes for buttons 
.button-primary {
  @include mixins.button-primary($isModule:false);
}
.button-secondary {
  @include mixins.button-secondary($isModule:false);
}
.button-tertiary {
  @include mixins.button-tertiary($isModule:false);
}
// S
// Schola Modal child Background 
.schola-modal {
  .schola-modal-child {
    @include themes.theme-aware('background-color','bg-default');
  }
}
