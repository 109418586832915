@import '../../../../../sass/color-palette';


.search-barSM {
    display: inline-block;
    width: 100%;
    scrollbar-color: #E5E5E5 #FFF;

    &__inputContainer {
        svg {
            position: absolute;
            left: 1.5em;
            color: #C7CCD4;
            margin-top: 6px;
        }
        .search-icon-mobile {
            left:3.5rem;
            top:1rem;
        }

    }
    
    &__chips-container {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        // border: 1px solid $dark-blue-25 !important;
        // border-radius: 8px;
        margin-top:8px;
        &.chip-container-search-mobile {
            display:none;
        }
    }

    strong {
        font-weight: bold;
    }

    &__chip {
        display: inline;
        position: relative;
        min-height: 26px;
        background-color: $dark-blue-10;
        color: $dark-blue-700;
        font-weight: 400;
        font-size: 14px;
        padding: 5px 12px;
        margin: 5px;
        border-radius: 20px;
        bottom: 70%;

        &-close {
            color: $dark-blue-75;
            margin: 5px 0 5px 5px;;
            height: 8px;
        }
    }

    &__input {
        width: 100%;
        max-height: 48px !important;
        border-radius: 8px !important;
        font-weight: 400;
        font-size: 18px !important;
        line-height: 18px;
        border: 1px solid $dark-blue-25;
        color: $dark-blue-75 !important;
        position: relative;
        padding: 0 2em !important;
        padding-left: 3rem !important;
    }

    .false {
        visibility: collapse;
    }

    &:focus {
        border: 1px solid $accent-100;
    }

    &__divider{
        border: 1px solid $dark-blue-10;
        height: 1px;
        display: flex;
        margin: 8px;
        width: auto;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        @media (max-width:810px){
            display: flex;
            flex-direction: column;
            margin-left: 16.5px;
        }
    }

    .separator {
        @media (max-width:810px){
            visibility: collapse;
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: row;
        margin: 0 8px 0 18.5px;
        font-size: 16px;
        color: $dark-blue-700;

        @media (max-width:810px){
            margin:0;
            display: inline list-item;
        }

        &:hover {
            background: $navy-blue-05;
        }

        &-icon {
            width: 13.75px;
            height: 17.5px;
            color: #000000;
        }

        &__text {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            color: $dark-blue-700;

        }
        &__subtext {
            font-size: 12px;
            color: $dark-blue-75;
            @media (max-width:810px){
                margin-top: 6px;
                font-size: 12px;
                color: $dark-blue-75;
            }
        }

    }

    &__box{
        height: auto;
        width: auto;
        padding: 6px 0;
        margin-top:4rem;
        border: none;
        @media (min-width:767px){
            border-radius: 8px;
            margin-top: 8px;
            border: 1px solid $dark-blue-25;
        }
    }

    &__list {
        color: $dark-blue-700;
        font-size: 16px;

        ul {
            height: calc(100vh - 64px);
            overflow-y: auto;
            scrollbar-color: #E5E5E5 #FFF;
            padding-bottom: 2rem;
            @media (min-width:767px){
                margin: 0;
                padding: 0;
                display: inline-block;
                width: 100%;
                max-height: 200px;
                overflow-y: scroll;
                border-radius: 8px;
            }
        }

        li {
            list-style: none;
            cursor: pointer;
            padding: 8px !important;
            display: flex;
            flex-wrap: wrap;

            input {
                height: 15px;
                margin: 0 18.5px;
            }
        }
        
        li:hover {
            background: $navy-blue-05;
            margin: 5px;
            border-radius: 8px;
        }
        
        li.no-result {
            color: $dark-blue-75;
        }
    }  
    &.searchMobile {
        display: inline-block;
        width: 100%;
        scrollbar-color: #E5E5E5 #FFF;
        position: fixed;
        height: 100vh;
        background: white;
        top: 0;
        z-index: 9999;
        right: 0;

        .comboboxMobile {           
            &_nav {
                position: fixed;
                top: 0;
                z-index: 1;
                width: 100%;
                display: flex;
                align-items: center;
                box-shadow: 0 2px 6px rgba(33, 53, 86, 0.08);
                height: 64px;

                .close {
                    display: block;
                    padding-left: 1rem;
                }
                input {
                    width: 95%;
                    border: none;
                    border-bottom: none !important;
                    outline: none;
                }
                .label {
                    text-align: start;
                    margin: 8px;
                    padding-bottom: 4px;
                    padding-left: 8px;
                    border-radius: 0 !important;
                    transition: 0.4s;
                    color: #0099CC !important;
                    font-size: 24px !important;
                    font-weight: 600;
                    text-overflow: ellipsis;
                    border: none !important;
        
        
                    &:hover {
                        border-bottom: none !important;
                    }
        
                    &::placeholder {
                        color: #A3AAB5;
                        font-weight: 400;
                    }
        
                    &:focus {
                        // outline: none;
                        border-bottom: none !important;
                    }
                }
            }
    
        }
    }
}
