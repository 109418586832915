$width: 1035px;

@mixin section {
  max-width: $width;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (min-width: 1051px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.section {
  @include section;
}

.container {
  margin: auto;
}

.h1 {
  text-align: left;
  font-size: 32px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 17px;
}

.title {
  text-align: left;
  margin: 2rem 0 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

.hero {
  background: url('https://static.schola.com/schola-match-results/header-mobile-scholamatch-results.png') no-repeat
    center right;
  background-size: cover;
  color: #1a2a45;
  font-weight: bold;
  text-align: center;

  @media screen and (min-width: 768px) {
    background: url('https://static.schola.com/schola-match-results/heacer-schola-results.png') no-repeat bottom center;
    background-size: cover;
  }

  & b {
    color: #0c5e79;
  }
  &_container {
    @include section;
    padding-top: 65px;
    padding-bottom: 32px;
    margin: auto;
  }
  .subtitle {
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
  }
}

.chipsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.titleBestMatch {
  @include section;
}
.hero_badge_title {
  display: grid;
  gap: 16px;
  @media (min-width: 768px) {
    grid-template-columns: 81px 650px;
    flex-direction: row;
  }
  .badge_guaranteed {
    width: 81px;
  }
}
