@import '../../../../sass/color-palette';
@import '../../../../sass/mixins';

.mission-section {
  margin: auto;
  background-color: #eef9f0;
  background-image: url('https://static.schola.com/profile/backgroud-cta-profile.webp');
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 5rem;
  margin-bottom: 7.5rem;
  @include toRem('max-width', 1200);
  @include padding(48, 16);
  border-radius: 10px;
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: 767px) {
    @include padding(64, 32);
  }
  @media (min-width: 1250px) {
    margin-left: auto;
    margin-right: auto;
  }

  .title {
    color: $primary-600;
    text-align: center;
    line-height: 120%;
    font-style: normal;
    font-weight: 700;
    @include toRem('font-size', 28);
    @include toRem('margin-bottom', 40);
    @media (min-width: 767px) {
      @include toRem('font-size', 40);
    }
  }

  .grid {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    max-width: 840px;
    margin: auto;
    @include toRem('margin-bottom', 32);
    @media (min-width: 767px) {
      flex-direction: row;
    }

    .divider {
      @include toRem('width', 80);
      @include toRem('height', 24);
      @include margin(0, 16);
      display: none;
      @media (min-width: 767px) {
        display: inline;
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 11px 12px 8px 13px;
    /* @include toRem('width', 216);
    @include toRem('height', 172); */
    display: none;
    &:last-child {
      display: block;
    }
    @media screen and (min-width: 768px) {
      display: flex;
    }

    img {
      width: 100%;
      /* @include toRem('width', 60);
      @include toRem('height', 60); */
    }

    h3 {
      font-size: 20px !important;
      line-height: 28px !important;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.12px;
      color: $dark-blue-500;
    }

    .googleRate {
      max-width: 269px;
    }
  }
}
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  @media screen and (min-width: 768px) {
    max-width: 428px;
  }
}
