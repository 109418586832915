@import '../../../../sass/color-palette';

.alertReferral {
  display: flex;
  align-items: center;
  gap: .5rem;
  border-radius: 8px;
  padding: .75rem 1rem;
  cursor:default;

  position: fixed;
  z-index: 5;
  max-width: 350px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 7rem;
  animation-name: inAlert;
  animation-duration: .6s;
  animation-timing-function: ease-in-out;
  // animation-fill-mode: forwards;

  &_remove {
    opacity: 0;
    top: -50px;
    transition: all .6s;
  }

  &_success {
    background: #F5FBF8;
    border: 1px solid #317159;
    color: #317159;
  }

  &_error {
    background: #fff2f3;
    border: 1px solid $red;
    color: $red;
  }

  &_warning {
    background: #fff8f2;
    border: 1px solid $yellow;
    color: $yellow;
  }
}

@keyframes inAlert {
  from {
    top: -50px;
  }
  to {
    top: 7rem;
  }
}