@import '../../../../sass/color-palette';
@import '../school-profile.module.scss';

.container {
  background: #e6f5fa7a;
  position: relative;
  overflow: hidden;
  padding: 2.5rem 0;
  margin-top: 2rem;

  @media (min-width: 768px) {
    padding: 4rem 0;
  }

  .blob {
    position: absolute;
    top: -323px;
    left: -120px;
    transform: rotate(136deg);

    @media (min-width: 950px) {
      top: -69px;
      left: -105px;
      transform: rotate(0deg);
    }
  }

  .mainGrid {
    @include profileWrapper();
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    gap: 6rem;

    @media (min-width: 950px) {
      grid-template-columns: 20% 70%;
      justify-content: space-between;
      gap: 0;
    }

    .questionsGrid {
      display: grid;
      grid-template-columns: 100%;
      width: 100%;
      gap: 2.3rem;

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem 4rem;
      }

      .article {
        display: flex;
        gap: 20px;
        figure {
          min-width: 2.62494rem;
          img {
            width: 48px;
            height: auto;
          }
        }
        h5 {
          color: $primary-500 !important;
          font-size: 1rem !important;
          font-weight: 600;
        }
        p {
          font-size: 1rem !important;
          color: $primary-500;
          line-height: 1.5rem;
          a {
            font-size: 1rem !important;
            color: $primary-500;
            line-height: 1.5rem;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.br {
  display: none;
  @media (min-width: 768px) {
    display: inline;
  }
}

.titleContainer {
  position: relative;
  width: fit-content;

  .titleSeparation {
    margin-bottom: 0.5rem;
    .title {
      white-space: nowrap;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.4rem;
    }
  }
}
.blobStart {
  position: absolute;
  top: -10px;
  left: -15px;
}
.blobEnd {
  width: 100%;
  position: absolute;
  bottom: -3rem;
  left: -0.35rem;

  @media (min-width: 768px) {
    bottom: -2rem;
  }
}
