.container {
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
  }
}

.buttonFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;

  @media (min-width: 820px) {
    flex-direction: row;
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
    gap: 0.62rem;
  }
}

.linkUnClaimed {
  background-color: #006b8f;
  color: #ffffff !important;
  width: fit-content;
  font-size: 16px;
  padding: 12px 16px;
  font-weight: 600;
  border-radius: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.25s ease-out;
  text-align: center;
}

@mixin badge {
  border-radius: 8px;
  padding: 16px 16px;
  display: flex;
  justify-content: flex-start;
  gap: 0.3rem;
  align-items: center;
  @media (min-width: 820px) {
    flex-shrink: 0;
  }
}
.badgeRadomGray {
  @include badge;
  color: rgba(95, 106, 125, 1);
  background-color: rgba(244, 246, 248, 1) !important;
}

.numRandom {
  font-weight: bold;
}
.iconView {
  color: rgba(95, 106, 125, 1);
}
.iconAlert {
  color: rgba(167, 46, 71, 1);
}

.primary {
  background: #007A4D;
  &:hover {
    background: #007A4D;
  }
}

.secondary {
  border: 1px solid #007A4D;
  color: #007A4D;
  &:hover {
    background: inherit;
  }
}
