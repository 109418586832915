@import '../../../../../sass/color-palette';

.links {
  display: flex;
  align-items: center;
  gap: 1rem;

  & svg {
    fill: $secondary-600;
  }
}

.listContainer {
  display: flex;
  flex-direction: column;

  .listWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    height: 3.5rem;
    align-items: center;
    border-bottom: 1px solid #c6cad1;
    .linkContainer {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      & > a {
        align-self: center;
        color: $primary-500;
      }
      & svg {
        fill: $primary-500;
      }
    }
  }
}

.listContainer :last-child {
  border-bottom: transparent;
}
