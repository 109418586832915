

.main-layout--connections{
  .main-layout__children{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .connections--container{
    height: 100%;
    background: linear-gradient(to bottom, $primary 170px, #ffffff 170px);
    min-height: 170px;
    &::before {
      clear: both;
      content: "";
      display: table;
      margin-top: -1px;
      height: 0;
    }
  }
  
  .connections--list{
    background-color: white;
    width: 95%;
    margin:  2.5% auto;
    box-shadow: 0 3px 6px 0 rgba(0,154,211,0.10), 0 3px 10px 0 rgba(0,0,0,0.08);
    overflow: auto;
    
    @media (max-width: $screen-sm) {
      width: 100%;
      margin-top: 0;
    }

    .list__title{
      padding: 26px 46px;
      font-size: 26px;
      color: $schola-gray;
      font-weight: $bold;
      @media (max-width: $screen-sm) {
        font-size: 24px;
        padding-left: 4.3%;
      }
    }
    .school-connections-table {
      margin: 67px auto 1.7%;
      width: 93.4%;
      line-height: 24px;
      font-weight: $medium;
      font-size: 14px;

      .actionsColumn{
        width: 15%;
      }

      .button {
        width: 109px;
        height: 24px;
        padding: 4px;
        line-height: 24px;
        font-size: 12px;
        font-weight: $bold;
        @media (max-width: $screen-md) {
          min-width: auto;
          width: 100%;
          float: right;
          margin-bottom: 8px;
          margin-top: 8px;
        }
      }

      .unread-connection{
        @include theme-aware('color', 'primary');
        font-weight: $bold;
      }
      .__react_component_tooltip{
        word-wrap: break-word;  
      }
      
      .no-results{
        font-weight: $regular;
        font-size: 16px;
      }
      
      @media (max-width: $screen-md) {
        margin-top: 2.7%;
        .mColumn {
          display: flex;
          padding-left: 16px;
          .dataColumn {
            width: 72%;
            display: inline-block;
            padding-right: 25px;
            .message {
              font-size: 10px;
              line-height: 16px;
              padding-top: 10px;
            }
          }
          .actionsColumn {
            width: 28%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            .status {
              margin-top: 20%;
              margin-bottom: 20%;
              text-align: center;
              width: 100%;
              float: right;
            }
            .profile-link{
              height: 35px;
            }
          }
        }
      }
    }

    .modal--reject{
      width: 55%;
      margin: 0 auto;
      max-width: 704px;
    }

    .modal--reject .modal__children{
      padding-bottom: 2.5%;
      @media (max-width: $screen-md) {
        padding-top: 2.5%;
      }
    }

    .modal--accept {
      .modal__children {
        @media (max-width: $screen-md) {
          padding-top: 8px;
        }
      }
    }

    .modal--accept, .modal--reject {
      @media (max-width: $screen-md) {
        width: 90%;
        margin: auto;
        height: 90%;
        .modal__content {
          box-shadow: 0 3px 6px 0 rgba(0,154,211,0.08), 0 3px 12px 0 rgba(0,0,0,0.06);
          border-radius: 8px;
          height: auto;
        }
        .modal__header {
          background: #ffffff;
          .modal__close {
            position: absolute;
            top: 12px;
            right: 8px;
            background: $dark-blue-15;
            margin: 12px;
            border-radius: 50%;
            padding: 0;
            .icon {
              padding: 2px;
            }
          }
        }
      }
    }

    // pagination changes
    .connections--list-pagination{
      padding-bottom: 16px;
      width: 93.4%;
      margin: 0 auto;
      .pagination-controls {
        @media (max-width: $screen-md) {
          display: block;
          text-align: center;
          height: auto;
          .pagination__count {
            margin: auto;
          }
        }
      }
    }
  }

 

  .accept--modal{
    .modal--header{
      color: $primary;
      margin: 0 auto;
      font-size: 24px;
      text-align: center;
      @media (max-width: $screen-md) {
        color: $schola-gray;
        font-weight: bold;
      }
    }
    .modal--detail-section{
      margin-top: 40px;
      display: block;

      @media (max-width: $screen-md) {
        margin-top: 20px;
      }

      .modal--detail-desciption{
        float: left;
        display: inline-block;
        width: 24%;
        @media (max-width: $screen-md) {
          width: 100%;
          .detail-desciption-m {
            width: 50%;
            display: inline-grid;
          }
        }

        p{
          font-weight: $medium;
          line-height: 24px;
          font-size: 14px;
          margin-top: 4px;
          &.header{
            opacity: 0.88;
            font-size: 12px;
            color: $dark-blue-15;
            margin-top: 16px;
          }
          &.header--first{
            margin-top: 0px;
            opacity: 0.88;
            font-size: 12px;
            color: $dark-blue-15;
          }
        }
      }

      .modal--form-section{
        float: left;
        display: inline-block;
        width: 76%;
        margin-bottom: 40px;

        @media (max-width: $screen-md) {
          margin-top: 18px;
          width: 100%;
        }

        .text-label{
          color: $dark-blue-15;
          font-size: 12px;
          opacity: 0.88;
          line-height: 24px;
        }
        input{
          opacity: 0.88;
          font-size: 14px;
          color: $primary;
          line-height: 24px;
          padding: 4px 16px;
          @media (max-width: $screen-md) {
            outline: none;
          }
        }
        .modal--actions{
          float: right;
          margin-top: 79px;

          @media (max-width: $screen-md) {
            width: 100%;
            margin-top: 24px;
          }

          .button{
            height: 40px;
            min-width: 122px;
            padding: 8px;
            line-height: 24px;
            font-size: 14px;

            @media (max-width: $screen-md) {
              width: 45%;
              margin: 0 2.5%;
              margin-top: 20px;
            }

            @media (max-width: $screen-sm) {
              width: 100%;
              margin: auto;
              margin-top: 20px;
            }
          }
        }
      }
     
 
    }
  }


  .reject--modal{
    text-align: center;
    .modal--header{
      color: $schola-gray;
      margin: 0 auto;
      font-size: 24px;
      text-align: center;
      line-height: 36px;
      font-weight: $bold;
      margin-bottom: 1.6%;
    }
    .modal--header--sub{
      color: $schola-gray;
      margin: 0 auto;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 3.3%;
      @media (max-width: $screen-md) {
        margin-top: 8px;
      }
    }
    .modal--detail-section{
      .text-other{
        border: 1px solid #90A4AE;
        border-radius: 3px;
        height: 24px;
        margin-top: -12px;
        width: 45%;
        margin-left: 8%;
        font-style: italic;
        font-size: 12px;
        padding: 4px;
        color: $schola-gray;
        @media (max-width: $screen-md) {
          width: 90%;
        }
      }
    }
    .model--detail-section-radio{
      text-align: left;
      margin-left: 25%;
        @media (max-width: $screen-md) {
          margin: auto;
          width: 90%;
        }
        label {
          outline: none;
          .radio-label{
            text-transform: uppercase;
            font-size: 16px;
            line-height: 24px;
            opacity: 0.88;
            font-weight: $bold;
            @media (max-width: $screen-md) {
              font-size: 14px;
              padding-right: 0;
            }
          }
        }
    }
    .modal--actions{
      text-align: right;
      margin-top: 17%;
      margin-bottom: 2.5%;

      @media (max-width: $screen-md) {
        margin-bottom: 9%;
      }

      .button{
        height: 40px;
        min-width: 122px;
        padding: 8px;
        @media (max-width: $screen-md) {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: $screen-md) {
  body.body-lock {
    position: fixed !important;
  }
}