@import '../../sass/variables';
@import '../../sass/color-palette';
@import '../../sass/themes';

.fab {
  position: fixed;
  padding: 16px;
  bottom: 16px;
  right: 16px;
  z-index: 1;
  box-shadow: $fab-box-shadow;
  min-width: auto;
}

// Our entire modal container, holds modal content itself as well as a sibling overlay
.modal {
  display: none;

  &.modal--visible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 98;
  }
}

// Holds main modal content
// fullscreen on mobile/tablet with a centered box for desktop
.modal__content {
  position: relative;
  width: 100%;
  //height: 100%;
  background: #ffffff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 99;

  @media (min-width: 768px) {
    max-width: 900px;
    height: auto;
    box-shadow: $card-box-shadow;
  }

  @media (min-width: $desktop) {
    max-width: 1024px;
    height: auto;
    box-shadow: $card-box-shadow;
  }
}

// Holds close button and title
// blue bar on mobile/tablet with a hidden title + right-aligned close button on desktop
.modal__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;

  @media (min-width: 768px) {
    justify-content: flex-end;
    background: transparent;
  }
}

.modal__title {
  display: inline-block;
  font-size: 20px;
  color: white;
  font-weight: $medium;
  margin-left: 24px;
  text-transform: capitalize;
}
.close-box {
  display: none;
}
.modal__close {
  display: inline-block;
  cursor: pointer;

  .icon {
    margin: 4px;
    fill: $schola-mist;
  }
}

// Main content, passed into the modal as children
.modal__children {
  padding: 16px;

  @media (min-width: $tablet-portrait) {
    padding: 24px;
  }

  @media (min-width: 768px) {
    padding: 32px;
    padding-top: 0; // Close button in title provides padding-top for desktop
  }

  @media (min-width: $desktop) {
    padding: 48px;
    padding-top: 0; // Close button in title provides padding-top for desktop
  }
}

// Opaque black background, sibling to modal content, sits behind everything
.modal.modal--visible .modal__overlay {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;
}
.modal__title {
  color: #000;
}
.modal-payment-result,
.modal-fail-notification,
.modal-success-notification,
.modal-confirm-notification {
  .text-red {
    color: $primary-red !important;
  }
  .text-blue-notification {
    color: $info-500 !important;
  }
  .modal__content {
    width: 100% !important;
    max-width: 100% !important;
    @media (min-width: 769px) {
      width: 600px !important;
      max-width: 600px !important;
    }
    .modal__children {
      padding: 0;
      padding-bottom: 0 !important;
    }
    .modal__header {
      display: none;
      padding-bottom: 0 !important;
    }
    .modal-content {
      padding: 0;
      .icon-container {
        &.icon-green {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          overflow: hidden;
          color: #fff;
          background-color: #99cc33;
          /* @include theme-aware('background-color', 'bg-teriary'); */
          i {
            &.fa {
              font-size: 3rem;
            }
          }
        }
        &.icon-red {
          i {
            &.fa {
              font-size: 100px;
              color: $primary-red;
            }
          }
        }
        &.icon-blue-notification {
          width: 70px;
          height: 70px;
          border: 3px solid $info-500;
          border-radius: 35px;
          i {
            &.fa {
              font-size: 50px;
              color: $info-500;
            }
          }
        }
      }
    }
    .modal-footer {
      box-shadow: none;
      padding: 24px;
    }
  }
  .modal__children {
    padding: 24px;
  }
  .icon-container {
    &.icon-green {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      overflow: hidden;
      color: #fff;
      background-color: #99cc33;
      i {
        &.fa {
          font-size: 3rem;
        }
      }
    }
    &.icon-red {
      i {
        &.fa {
          font-size: 100px;
          color: $primary-red;
        }
      }
    }
    &.icon-blue-notification {
      width: 70px;
      height: 70px;
      border: 3px solid $info-500;
      border-radius: 35px;
      i {
        &.fa {
          font-size: 50px;
          color: $info-500;
        }
      }
    }
  }
  #payment-result-content-billing-panel {
    padding: 0;
    .payment-result-subtitle {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .modal-body {
    .payment-result-subtitle {
      display: block;
      padding: 15px 0 15px 0;
      font-size: 24px;
    }
    .payment-result-label {
      display: block;
      padding: 15px 0 15px 0;
      font-size: 16px;
    }
    .payment-result-sumary {
      display: block;
      padding: 15px 0 15px 0;
      font-size: 20px;
    }
  }
  .btn {
    margin: 5px;
  }
}
.modal-success-scholamatchv3 {
  z-index: 9999 !important;
  .modal-content {
    border: none;
  }
}
.modal-payment-fail {
  .button--color--red {
    width: 100px;
  }
}
.modal.modal--visible.parent-student-modal.add-student-modal {
  @media (min-width: 768px) and (max-height: 768px) {
    justify-content: flex-start;
    top: 10%;
  }
  .modal__content {
    @media (max-width: 480px) {
      height: 100%;
    }
  }
}
.modal-confirm-notification {
  .modal__content {
    width: 450px !important;
  }
}
