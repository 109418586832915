.main {
  width: 100%;
}

.main > * {
  border-bottom: 1px solid #c6cad1;
  margin-bottom:1.5rem;
  padding-bottom:1.5rem;
  @media (min-width: 768px) {
    margin-bottom:3rem;
    padding-bottom:3rem;
  }
}

.main {
  h2 {
    margin-bottom:2rem;
  }
}

/* .main > :last-child {
  border-bottom: transparent;
} */

/* .main > :last-child > section {
  padding: 1.5rem 0 0 0;
  @media (min-width: 768px) {
    padding: 48px 0 0 0;
  }
} */
