.photo-gallery {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;

  &.empty {
    height: 150px; // Set the height to 150px when there are no photos
    background: linear-gradient(to bottom, #e6f5fa, #f5faeb);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-size: 1.2em;
  }

  .carousel-container {
    position: relative;
    height: 100%;
    background: linear-gradient(to bottom, #e6f5fa, #f5faeb);

    .carousel {
      display: flex;
      overflow-x: scroll;
      scroll-behavior: smooth;
      height: 100%;
      align-items: center;
      scroll-snap-type: x mandatory;
      justify-content: flex-start;
      gap: 4px; // Add gap between images

      &.center {
        justify-content: center; // Center photos when there is no overflow
      }

      img {
        height: 100%;
        flex-shrink: 0;
        scroll-snap-align: center;
      }
      // Custom scrollbar styles
      &::-webkit-scrollbar {
        height: 4px; /* Height of the horizontal scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #d0d0d078; /* Track color */
      }

      &::-webkit-scrollbar-thumb {
        background: #48484881; /* Scrollbar thumb color */
        border-radius: 10px; /* Round corners */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #4848484c; /* Hover state for scrollbar thumb */
      }
    }

    .arrow {
      background: #fff;
      border: 1px solid #ebebeb;
      border-radius: 50%;
      box-shadow: 0 0 10px #0000001a;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      mix-blend-mode: normal;
      opacity: 0.8;
      position: absolute;
      top: 40%;
      cursor: pointer;
      z-index: 1;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }

      &.prev {
        left: 10px;
      }

      &.next {
        right: 10px;
      }
    }

    &.show-arrows .arrow {
      display: flex;
    }
  }

  .open-modal {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 8px;
    border: 0.5px solid #fff;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .modal-content {
      background-color: white;
      padding: 20px;
      max-width: 90%;
      max-height: 90%;
      overflow-y: auto;
      position: relative;

      .modal-photos {
        display: grid;
        grid-template-columns: repeat(5, minmax(200px, 1fr));
        grid-gap: 10px;

        .modal-photo {
          width: 100%;
          aspect-ratio: 1; // Maintain a square aspect ratio
          object-fit: cover; // Ensure the images cover the entire grid cell
          cursor: pointer;
        }
      }

      .close-modal {
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(15px);
      }
    }
  }

  .zoom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;

    .zoom-modal-content {
      background-color: white;
      padding: 20px;
      max-width: 100%; // Ensure it fits within the screen size
      max-height: 90%;
      overflow-y: auto;
      position: relative;

      .zoomed-image {
        width: 100%;
        min-width: 600px;
        max-width: 1000px;
        height: auto;
      }

      .close-zoom-modal {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(15px);
        color: white;
        border: none;
        padding: 0.5rem 1rem;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
.visually-hidden {
  display: none;
}

// Media query for mobile devices
@media (max-width: 600px) {
  .photo-gallery {
    height: 200px;

    &.empty {
      height: 150px; // Set the height to 150px on mobile when there are no photos
    }

    .carousel img {
      height: 200px;
    }

    .modal-content .modal-photos {
      grid-template-columns: repeat(3, minmax(100px, 1fr)) !important ; // Ensure at least 3 columns
      grid-gap: 5px !important; // Adjust gap for smaller images

      .modal-photo {
        width: 100% !important;
        height: 100px !important; // Fixed height for square aspect ratio
        object-fit: cover !important;
      }
    }

    .zoom-modal-content {
      .zoomed-image {
        width: 100% !important;
        min-width: 300px !important;
        max-width: 100% !important; // Ensure it fits within the screen size
      }
    }
  }
}
