.galleryButtons {
  display: none;

  @media screen and (min-width: 767px) {
    display: flex;
    gap: 0.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }

  &_button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 10px 16px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
  }
}
.navButtons {
  position: inherit;
}
