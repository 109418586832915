.container {
  position: fixed;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 9px 28px 8px #0000000d;
}

.popButton {
  right: 4px;
  bottom: 4px;
  width: 55px;
  height: 55px;
  border-radius: 50%;

  & svg {
    font-size: 1.5rem;
    color: #006b8f;
  }
}

.formBox {
  width: 480px;
  height: auto;
  right: 0;
  bottom: 0;
}

.close_icon_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  padding-bottom: 0;
}
.content {
  padding: 0 52px 16px 24px;
}
.question {
  font-size: 1rem;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 1rem;
}
.buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
}
.button {
  width: 100%;
  height: 50px;
  border: 1px solid #006b8f;
  background-color: white;
  color: #006b8f;
}
.textarea {
  min-height: 64px;
  margin-bottom: 12px;
  padding-top: 0.5rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
.button_send {
  width: fit-content;
}

.currentSelected {
  background: #e6f5fa;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & svg {
    font-size: 1.2rem;
  }
}

.success {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & svg {
    font-size: 2rem;
    color: #006b8f;
  }
}
