@import 'colors/primitives';
@import 'colors/scheme-colors';

// UI Colors
// Primary Color Brand
$primary: #0099cc;
$primary-blue: $primary;
$text-primary-blue: $primary;
$schola-green: #99cc33;
$aqua-secondary: #0c5e79;
$dark-blue: #1a2a45;
// Secondary Colors
$orange: #ffaa5a;
$amaranth: #ee4266;
$indigo: #5076c7;
// Semantic Colors
$success: #02ca81;
$warning: #fbb13c;
$danger: #d62246;
$info: #136ac3;

//// Usage
// Primary
// Use this to emphasise main ui components and text.
$primary-700-text-icon: $dark-blue;
$primary-600-pressed: #003d52;
$primary-500-default-link: #006b8f;
$primary-400-accent-icon-hover-selected: $primary-blue;
$primary-300-border: #4db8db;
$primary-200-subtle-border: #99d6eb;

//Secondary
//Use this to emphasise secondary ui components and text.
$secondary-600-text-icon-default: $aqua-secondary;
$secondary-500-secondary-text-accent: #3d7e94;
$secondary-300-border-pressed: #9ebfc9;
$secondary-200-subtle-border: #cedfe4;

//Neutral
//Use this as the base for text color and neutral greys for Schola® platform. Use it for Titles, secondary text and pargraph.
$dark-blue-700-text-icon: #1a2a45;
$dark-blue-500-secondary-text: #5f6a7d;
$dark-blue-400-subtle-icon-border: #818a99;
$dark-blue-300-subtle-border-disabled-text: #a3aab5;
$dark-blue-200-disabled-border: #c6cad1;

//Success
//Use this color to communicate positive actions
$success-700-text-icon: #015134;
$success-600-2ry-text-icon-pressed: #018d5a;
$success-400-accent-default: #02ca81;
$success-300-border-hover: #4edaa7;
$success-200-subtle-border: #9aeacd;

//Warning
//Use this color to communicate warning actions
$warning-700-text-icon: #644718;
$warning-600-secondary-text: #b07c2a;
$warning-500-accent-pressed: #e29f36;
$warning-400-accent-default: #fbb13c;
$warning-300-border-hover: #fcc877;
$warning-200-subtle-border: #fde0b1;

//Danger
//Use this color to communicate danger actions
$danger-700-text-icon: #560e1c;
$danger-600-secondary-text: #961831;
$danger-500-accent-default: #c11f3f;
$danger-400-accent-hover: #d62246;
$danger-300-border: #e2647e;
$danger-200-subtle-border: #efa7b5;

//Info
//Use this color for informational ui elements
$info-700-text-icon: #0a3562;
$info-600-pressed: #0d4a89;
$info-500-2ry-text-default: #0f559c;
$info-400-accent-hover: #136ac3;
$info-300-border: #5a97d5;
$info-subtle200-border: #a1c3e7;

//Backgrounds
//Use this colors for backgrounds only. Do not combine elements with different colors.
$primary-100-background: #e6f5fa;
$secondary-100-background: #e7eff2;
$green-100-background: #f5faeb;
$dark-blue-100-background: #f1f2f3;
$success-100-background: #e6faf2;
$warning-100-background: #feefd8;
$danger-100-background: #fbe9ed;

//Full Color Palette
$primary-700: #001f29;
$secondary-700: #0a4b61;
$green-700: #1f290a;
$primary-600: #003d52;
$secondary-600: #0c5e79;
$green-600: #3d5214;
$primary-500: #006b8f;
$secondary-500: #3d7e94;
$green-500: #6b8f24;
$primary-400: #0099cc;
$secondary-400: #6d9eaf;
$green-400: #99cc33;
$primary-300: #4db8db;
$secondary-300: #9ebfc9;
$green-300: #b8db70;
$primary-200: #99d6eb;
$secondary-200: #cedfe4;
$green-200: #d6ebad;
$primary-100: #e6f5fa;
$secondary-100: #e7eff2;
$green-100: #f5faeb;

$success-800: #00281a;
$warning-800: #32230c;
$danger-800: #2b070e;
$success-700: #015134;
$warning-700: #644718;
$danger-700: #560e1c;
$success-600: #018d5a;
$warning-600: #b07c2a;
$danger-600: #961831;
$success-500: #02b674;
$warning-500: #e29f36;
$danger-500: #c11f3f;
$success-400: #02ca81;
$warning-400: #fbb13c;
$danger-400: #d62246;
$success-300: #4edaa7;
$warning-300: #fcc877;
$danger-300: #e2647e;
$success-200: #9aeacd;
$warning-200: #fde0b1;
$danger-200: #efa7b5;
$success-100: #e6faf2;
$warning-100: #feefd8;
$danger-100: #fbe9ed;

$info-800: #041527;
$dark-blue-700: #1a2a45;
$info-700: #0a3562;
$dark-blue-600: #3c4a61;
$info-600: #0d4a89;
$dark-blue-500: #5f6a7d;
$info-500: #0f559c;
$dark-blue-400: #818a99;
$info-400: #136ac3;
$dark-blue-300: #a3aab5;
$info-300: #5a97d5;
$dark-blue-200: #c6cad1;
$info-200: #a1c3e7;
$dark-blue-100: #f1f2f3;
$info-100: #e7f0f9;

/////////////////////////////////////////////////////////////////////////////////

$secondary-500: $secondary-500-secondary-text-accent;

$dark-blue-75: #546982;
$dark-blue-65: #6e7b91;
$dark-blue-50: #96a3b8;
$dark-blue-25: #c7ccd4;
$dark-blue-15: #dadee5;
$dark-blue-10: #e8eaee;
$dark-blue-05: #f4f6f8;

$light-danger-500: #c11f3f;
$light-danger-700: #560e1c;

$navy-blue-100: #0645ad;
$navy-blue-75: #4473c1;
$navy-blue-50: #82a2d6;
$navy-blue-25: #c1d0ea;
$navy-blue-15: #d8e3f5;
$navy-blue-10: #e8eef8;
$navy-blue-05: #f2f5fb;
$navy-blue-02: #fafbfd;

$accent-dark: #005fb3;
$accent-100: #008cff;
$accent-75: #45abff;
$accent-50: #8ccbff;
$accent-25: #cce9ff;
$accent-10: #e5f4ff;
$accent-05: #f2f9ff;
$accent-02: #fafdff;

$secondary: $secondary-500;
$schola-accent: $secondary-600;

$dashboard-border: #e5edd5;
$dashboard-background: #f6fcf9;
$green-dark: #317159;
$green: #52bd94;
$green-light: #f5fbf8;
$red-dark: #a73636;
$red-light: #fdf4f4;
$red: #d14343;
$yellow: #f0ad1b;
$black: #000000;
$white: #ffffff;
$neutral-800: #131d29;

//legacy color vars
$green-legacy: $schola-green;
$red-legacy: $red;
$primary-red: $red;
$heading-color: $dark-blue-75;
$body-color: #213556;
$anchor-color: $accent-100;
$border-color: $dark-blue-15;
$schola-gray: #4a4a4a;
$schola-purple: #af8cc4;
$schola-mercury: #e6e6e6;
$schola-mist: #b1c1c5;
$schola-carmine: #f4333e;
$schola-lightgrey: $dark-blue-50;
