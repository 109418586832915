@use '../../../../../../sass/mixins';
@use '../../../../../../sass/themes';

.modal_mobile {
  @include themes.theme-aware('background-color', 'bg-default');
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;

  &_header {
    @include mixins.toRem('border-bottom-width', 1);
    @include mixins.toRem('min-height', 56);
    @include themes.theme-aware('border-bottom-color', 'border-default');
    border-bottom-style: solid;
    display: grid;
    padding: 1rem;
    place-items: center;
    position: relative;

    &_title {
      max-width: 70%;
    }

    .buttonIcon {
      padding: 0.5rem;
      /* border-radius: 50%; */
      position: absolute;
      top: calc(50% - 1rem);
    }

    .close {
      right: 0.5rem;
      border: 1px solid lightgrey;

      @media screen and (min-width: 526px) {
        left: inherit;
        right: 0.5rem;
      }
    }

    .back {
      left: 0.5rem;
    }
  }

  &_children {
    height: calc(100vh - 90px);
    overflow-y: auto;

    &_container {
      padding-left: 1rem;
      padding-right: 1rem;
      margin: 1rem auto;

      & .image-gallery-thumbnails-wrapper {
        overflow-y: auto;
      }

      @media screen and (min-width: 767px) {
        max-width: 90%;
      }

      @media screen and (min-width: 1050px) {
        @include mixins.toRem('max-width', 1024);
      }
    }
  }
}
