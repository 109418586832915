@import '../../../../../../sass/color-palette';

.container {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid $danger-300;
  background: $danger-100;
  color: $danger-700;
  display: flex;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 170%;

  & svg {
    min-width: 1.2rem;
  }
}
