@use '../../sass/mixins';
@use '../../sass/color-palette';

.school-card {
  position: relative;
  @include mixins.toRem('border-radius', 10);
  width: 100%;
  display: grid;
  border: 1px solid #dadee5;

  .school-image,
  .school-image img {
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    height: 150px;
    width: 100%;
    /* @media (max-width: 480px) {
      height: 240px;
    } */
  }

  .school-card-text {
    text-decoration: none;
  }

  &_action {
    @include mixins.toRem('margin-top', 9);
    @include mixins.toRem('width', 30);
    @include mixins.toRem('height', 30);
    @include mixins.toRem('border-radius', 6);
    @include mixins.toRem('right', 12);
    @include mixins.toRem('top', 3);
    cursor: pointer;
    position: absolute;
    background-color: #fff;
    display: grid;
    place-items: center;

    @media (min-width: 768px) {
      position: absolute;
      top: 0 !important;
    }
  }

  &-match {
    &__divider {
      border: 0.5px solid #c6cad1;
      margin-right: 1rem;
    }

    &__title {
      display: inline-flex;
      margin: 12px 0;

      &-overview {
        font-weight: 600;
        font-size: 12px;
        line-height: 170%;
        color: #5f6a7d;
      }

      &-percentage {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #0099cc;
      }

      &-textDivider {
        margin: 0 8px;
        display: flex;
        align-items: center;

        @media (max-width: 575px) {
          margin: 0 2px;
        }
      }
    }

    &__chipsWrapper {
      display: grid;
      grid-template-columns: 90% 7%;
      margin-bottom: 16px;
      align-items: center;

      @media (max-width: 575px) {
        grid-template-columns: 87% 10%;
      }
    }

    &__chipsContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__chip {
      display: inline;
      min-height: 26px;
      background-color: #f5faeb;
      color: #1f290a;
      font-weight: 400;
      font-size: 12px;
      padding: 5px 12px;
      margin: 2px;
      border-radius: 20px;
    }

    &_toggle {
      display: grid;
      place-items: center;
    }
  }
}

.school-info {
  &-title {
    & h5 {
      display: inline;
      font-size: 1rem !important;
    }

    & svg {
      min-width: 1rem;
      margin-bottom: 0.3rem;
      margin-left: 0.2rem;
    }
  }

  &-badge {
    background: white;
    color: #006b8f;
    padding: 6px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-bottom-right-radius: 8px;
    position: absolute;
  }

  &-badgeContainer {
    position: absolute;
    top: 0;
    left: 0.5rem;
    width: 110px;
    height: 109px;

    @media screen and (min-width: 767px) {
      left: 1rem;
    }
  }

  &-reviews {
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
  }

  &-type {
    text-transform: capitalize;
    color: color-palette.$dark-blue-50;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }

  &-bestMatch {
    color: color-palette.$primary;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-address {
    color: color-palette.$dark-blue-500-secondary-text;
    font-size: 14px;
  }

  &-chipsContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  &-chip {
    background: color-palette.$primary-100; // change temporal from primary-100
    color: color-palette.$dark-blue-600; // change temporal from primary-600
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 4px;
    width: max-content;
  }
}

.results__map {
  .school-card {
    margin-bottom: 0;
    grid-template-columns: 100%;

    .school-image {
      height: 120px;
      width: 100%;
    }
  }
}
