
$blue-700:  #001F29;
$blue-600:  #003D52;
$blue-500:  #006B8F;
$blue-400:  #0099CC;
$blue-300:  #4DB8DB;
$blue-200:  #99D6EB;
$blue-100:  #E6F5FA;

$indigo-700:  #080C14;
$indigo-600:  #202F50;
$indigo-500:  #38538B;
$indigo-400:  #5076C7;
$indigo-300:  #859FD8;
$indigo-200:  #B9C8E9;
$indigo-100:  #DCE4F4;

$denim-800:  #041527;
$denim-700:  #0A3562;
$denim-600:  #0D4A89;
$denim-500:  #0F559C;
$denim-400:  #136AC3;
$denim-300:  #5A97D5;
$denim-200:  #A1C3E7;
$denim-100:  #E7F0F9;

$steelblue-700:  #0A4B61;
$steelblue-600:  #0C5E79;
$steelblue-500:  #3D7E94;
$steelblue-400:  #6D9EAF;
$steelblue-300:  #9EBFC9;
$steelblue-200:  #CEDFE4;
$steelblue-100:  #E7EFF2;

$turquoise-700:  #2A4548;
$turquoise-600:  #3C6367;
$turquoise-500:  #568D93;
$turquoise-400:  #7BCAD2;
$turquoise-300:  #A3DAE0;
$turquoise-200:  #CAEAED;
$turquoise-100:  #E5F4F6;

$green-800:  #00281A;
$green-700:  #015134;
$green-600:  #018D5A;
$green-500:  #02B674;
$green-400:  #02CA81;
$green-300:  #4EDAA7;
$green-200:  #4EDAA7;
$green-100:  #E6FAF2;

$lime-700:  #1F290A;
$lime-600:  #3D5214;
$lime-500:  #6B8F24;
$lime-400:  #99CC33;
$lime-300:  #B8DB70;
$lime-200:  #D6EBAD;
$lime-100:  #F5FAEB;

$yellow-700:  #644718;
$yellow-600:  #766117;
$yellow-500:  #A88A20;
$yellow-400:  #F0C52E;
$yellow-300:  #F5D76D;
$yellow-200:  #F9E8AB;
$yellow-100:  #FCF3D5;

$orange-800:  #32230C;
$orange-700:  #644718;
$orange-600:  #B07C2A;
$orange-500:  #E29F36;
$orange-400:  #FBB13C;
$orange-300:  #FCC877;
$orange-200:  #FDE0B1;
$orange-100:  #FEEFD8;

$tangerine-700:  #191109;
$tangerine-600:  #80552D;
$tangerine-500:  #B3773F;
$tangerine-400:  #FFAA5A;
$tangerine-300:  #FFC48C;
$tangerine-200:  #FFDDBD;
$tangerine-100:  #FFEEDE;

$salmon-700:  #512918;
$salmon-600:  #733A22;
$salmon-500:  #A45330;
$salmon-400:  #EB7745;
$salmon-300:  #F1A07D;
$salmon-200:  #F7C9B5;
$salmon-100:  #FBE4DA;

$red-700:  #2B070E;
$red-600:  #560E1C;
$red-500:  #C11F3F;
$red-400:  #D62246;
$red-300:  #E2647E;
$red-200:  #EFA7B5;
$red-100:  #FBE9ED;

$amaranth-700:  #18070A;
$amaranth-600:  #5F1A29;
$amaranth-500:  #A72E47;
$amaranth-400:  #EE4266;
$amaranth-300:  #F37B94;
$amaranth-200:  #F8B3C2;
$amaranth-100:  #FBE9ED;

$purple-700:  #412352;
$purple-600:  #5D3276;
$purple-500:  #8548A8;
$purple-400:  #AA7FC2;
$purple-300:  #CEB6DC;
$purple-200:  #E7DAEE;
$purple-100:  #F3EDF6;

$gray-700:  #1A2A45;
$gray-600:  #3C4A61;
$gray-500:  #5F6A7D;
$gray-400:  #818A99;
$gray-300:  #A3AAB5;
$gray-200:  #C6CAD1;
$gray-100:  #F1F2F3;
