.schoolHeader {
  width: 100%;
  background: #fff;

  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: max-content;

  &:last-child {
    border-right: none;
  }
}
.image {
  height: 80px;
  border-radius: 4px 4px 0 0;
  overflow: hidden;

  margin-bottom: 0.5rem;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 66px;
  margin: 0 0.5rem;
}

.name {
  line-height: 36px !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 500;
  margin-top: auto;
  margin-bottom: auto;
}
