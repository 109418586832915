.marker {
  text-transform: capitalize;

  .title {
    margin-bottom: .5rem;
  }

  .text {
    margin-bottom: .5rem;
    p {
      margin-bottom: .25rem;
    }
  }
}

.mapContainer {
  position: relative;
}

.searchButton {
  color: #3C4A61;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: .5rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;

  position: absolute;
  top: 10px;
  z-index: 1000;
  left: 52px;
}