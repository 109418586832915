@import '../../sass/themes';

.pagination-controls {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    height: 32px;
    line-height: 32px;

    button {
        font-weight: 600;
        @include theme-aware('color','primary');
        border-width: 1px;
        border-style: solid;
        @include theme-aware('border-color','primary');
        @include theme-aware('background-color', 'bg-default');
        padding: 0;
        height: 24px;
        width: 24px;
        vertical-align: middle;
        margin: 0 4px;
        font-size: 14px;
        border-radius: 2px;
        line-height: 0
    }

    .pagination-controls-btn__active {
        pointer-events: none;
        @include theme-aware('color', 'bg-default');
        @include theme-aware('background-color', 'primary');
    }

    .pagination-controls-btn__disabled {
        pointer-events: none;
        @include theme-aware('color', 'bg-default');
        @include theme-aware('background-color', 'bg-subtle');
        border: none;
        opacity: .65;
    }

    .pagination__count {
        display: block;
        margin-right: 24px;

    }

    .pagination-controls__arrows {

        button {
            display: inline-block;

            .icon {
                transition: all 0.25s ease-in-out;
            }

            &:hover {
            cursor: pointer;

                .icon {
                  @include theme-aware('fill', 'dark-blue-75');
                }
            }
        }
    }
}
