@import '../../../../../sass/mixins';

.mapMain {
  height: 100%;
}

.mini {
  @include toRem('width', 100);
  @include toRem('height', 60);
  position: absolute !important;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 99;
  overflow: auto;
  border: 1px solid #fff;
  border-radius: 8px;
  cursor: pointer;

  a {
    visibility: collapse;
  }
}
.street {
  position: relative;
  font-size: 12px;
  font-weight: 600;
  height: 12px;
  color: white;
  z-index: 9999;
  left: 1rem;
  top: 2.6rem;
}
