@import 'color-palette';

$screen-xs: 480px; // 320px480px: Mobile devices
$screen-md: 768px; // 481px768px: iPads, Tablets
$screen-xl: 1200px; // Large devices (laptops/desktops, 992px)

//Legacy vars:
$tablet-portrait: 600px;
$tablet-landscape: 900px;
$desktop: 1200px;

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$Extra: 800;

// shadows
$card-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$fab-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.16);
$box-shadow-onboarding: 1px 0 10px rgba(33, 53, 86, 0.2);

// Medium devices (landscape tablets, 768px)
$screen-lg: $desktop - 1; // Extra large devices (large laptops and desktops, 1200px)
//typography
$font-base: 1rem !important;
$sans: 'Open Sans', sans-serif;
$mono: 'Source Code Pro', monospace;
$montserrat: 'Montserrat', sans-serif;
$lato: 'Lato', sans-serif;
$light: 300;
$regular: 400;
$medium: 600;
$bold: 700;
$font-base: 1rem;

/* Settings */
$gutter: 1rem;
$card-border-radius: 0.25rem;
$slider-card-box-shadow: 0 3px 4px 0 rgba(0, 154, 211, 0.08), 0 3px 8px 0 rgba(0, 0, 0, 0.16);

/* Media Breakpoints */
$tablet-portrait: 767px;
$tablet-landscape: 1024px;
$desktop: 1200px;

$screen-xs: 575px; // Small devices (portrait tablets and large phones, 576px)
$screen-sm: 767px; // Medium devices (landscape tablets, 768px)
$screen-md: 991px; // Large devices (laptops/desktops, 992px)
$screen-lg: $desktop - 1; // Extra large devices (large laptops and desktops, 1200px)
