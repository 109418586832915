@import '../../../../../sass/color-palette';

.video-slider {
  height: 400px;
  @media (max-width: 767px) {
    width: 90%;
    margin: auto;
  }
  .slick-initialized {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        iframe {
          height: 100%;
        }
      }
    }
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: $dark-blue-700;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-arrow {
    display: block !important;
  }
}
