@use '../../../../sass/color-palette' as var;
@use '../../../../sass/mixins';

.container {
  overflow-y: auto;
  max-height: 80vh;
  .line1 {
    width: 48px;
    height: 4px;
    border-radius: 24px;
    border: 4px;
    background-color: #09c;
  }
  .header_section {
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: 75% 25%;
    margin-bottom: 32px;
    /* .ImageContainer {
      img {
        width: 150px;
      }
    } */
    .text_container {
      display: flex;
      flex-direction: column;
      h2 {
        margin-bottom: 1rem;
      }
      .bg {
        position: relative;
        display: inline-block;

        &:after {
          position: absolute;
          bottom: -24px;
          width: 70px;
          height: 25px;
          content: ' ';
          background-image: url(https://static.schola.com/profile/school-types-image-2.svg);
          background-repeat: no-repeat;
          left: -5px;
          display: inline-block;
        }
      }

      p {
        color: var.$dark-blue-500;
        font-size: 14px !important;
        font-style: normal;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.14px;
      }
    }
  }
  .body_container {
    margin-top: 32px;
    .section_container {
      border-bottom: 1px solid #c6cad1;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      &:last-child {
        border-bottom: none;
      }
      h4 {
        color: var.$primary-700 !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        margin-bottom: 16px;
      }
      .chips_container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        flex-wrap: wrap;
        .chip {
          display: flex;
          height: 24px;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          border-radius: 20px;
          background: var.$secondary-100 !important;
          color: var.$secondary-600 !important;
          /* Semibold/Body/Small */
          font-family: Open Sans;
          font-size: 12px !important;
          font-style: normal;
          font-weight: 600 !important;
          line-height: 16px !important; /* 133.333% */
          margin-right: 8px;
          margin-bottom: 12px;
        }
      }
      .paragraph {
        color: var.$dark-blue-500;
        font-family: Open Sans;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 24px !important; /* 171.429% */
        letter-spacing: -0.14px;
        b {
          font-weight: bold;
        }
      }
    }
  }
  .tip_section {
    background-color: #e6f5fa;
    padding: 2rem 1.25rem;
    display: grid;
    grid-template-columns: 10% 85%;
    gap: 1rem;
    border-radius: 0.5rem;
    .tip {
      color: var(
        --light-primary-500-text-icon-default-link,
        var(--legacy-light-primary-500-text-icon-default-link, #006b8f)
      );

      font-family: Open Sans;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.01rem;
      .boldTip {
        color: var(
          --light-primary-500-text-icon-default-link,
          var(--legacy-light-primary-500-text-icon-default-link, #006b8f)
        );
        font-family: Open Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem; /* 150% */
        letter-spacing: -0.01rem;
      }
    }
  }
}
