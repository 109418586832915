@import '../../../sass//color-palette';

.topItem {
  display: flex;
  height: 32px;
  padding: 0px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: $secondary-100;
}
.checkItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  color: $primary-500;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.pCheckItem {
  color: $primary-500;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-left: 8px;
  font-family: Open Sans;
  letter-spacing: -0.14px;
  font-style: normal;
}

.programItem {
  display: flex;
  height: auto;
  padding: 2px 12px;
  align-items: center;
  border-radius: 20px;
  border: 0.8px solid $secondary-200;
  background: #ffffff;
}

.pItem {
  color: $neutral-500;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.12px;
}

.modalItem {
  display: flex;
  gap: 8px;
  position: relative;
}

.modalDot {
  position: absolute;
  top: -70%;
  font-size: 30px;
}

.pModalItem {
  margin-left: 16px;
  color: $neutral-400;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.filterItem {
  display: flex;
  padding: var(--margin-8, 0.5rem) 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.5rem;
  background: var(--bg-secondary-subtle, #e7eff2);
  cursor: pointer;
  &:hover {
    background: var(--bg-secondary-pressed, #cedfe4);
  }
}

.pFilterItem {
  color: var(--text-link-secondary, #0c5e79);
  /* Mobile/Body/Medium */
  font-family: Montserrat;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: 0.01rem;
}

.active {
  background: var(--bg-secondary, #3d7e94);
  &:hover {
    background: var(--bg-secondary, #3d7e94);
    box-shadow: 0px 2px 6px 0px rgba(33, 53, 86, 0.5);
  }
}

.pActive {
  color: var(--base-white, #fff);
  &:hover {
    color: var(--text-on-action, #f1f2f3);
  }
}
