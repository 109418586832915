@import '../../school-profile.module.scss';

.wrapper {
  @include profileWrapper();
}

.closed {
  margin-top: 7rem;
  height: 30rem;
  display: grid;
  place-items: center;
  margin-bottom: 7rem;
}

.content {
  display: flex;
  align-items: center;
  gap: 3rem;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    flex-direction: row;
  }
}
