.container {
  margin-top: 1rem;
  padding: 22px 24px;
  background: #cedfe4 url(https://static.schola.com/scholamatch/schola-background-header-smv6.png) no-repeat center top;
  min-height: 183px;
  border: 1px solid #cedfe4;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  background-size: cover;
  @media (min-width: 767px) {
    display: flex;
    background-size: auto;
  }
  @media (min-width: 1080px) {
    justify-content: space-between;
  }
  gap: 16px;
  h3 {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 32px;
    margin-bottom: 12px !important;
  }
  p {
    font-size: 14px;
    line-height: 22px;
  }
}
.content_info {
  position: static;
}
.button {
  margin-top: 16px;
  padding: 4px 15px;
  width: fit-content;
}
.figure_content {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 767px) {
    width: 150px;
  }
  img {
    min-width: 150px;
  }
}
.button {
  margin-bottom: 1rem;
  width: 100%;
  min-height: 40px;
  @media screen and (min-width: 760px) {
    width: fit-content;
  }
}
