.body-schola-match {
  font-weight: 400;
  background-size: cover;
  background-color: #f9f9fc;
  background-attachment: fixed;
  background-position: top center;
  background-image: url('https://static.schola.com/matching-bg.png');
}
.matching-layout {
  height: 100vh;
  padding: 0 15px;
  .language {
    margin-top: 1rem;
  }
  .container {
    height: 100%;
  }
  .matching-header {
    padding: 20px 0;
    .header-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
      border-bottom: 1px solid #dddfe7;
      .header-content-box {
        &.header-content__back-button {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          text-align: left;
          width: 70px;
        }
        &.center {
          text-align: center;
        }
        &.header-content__close-button {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
        }
      }
      .matching-logo {
        width: 145px;
      }
      button {
        color: #c2c4cb;
        padding: 0;
        border: 0;
        background-color: transparent;
        &.back-button {
          font-size: 15px;
          font-weight: 700;
        }
      }
    }
    .progress-bars {
      margin-top: 20px;
      .progress-bars__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .progress-indicator {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 6px;
          margin-right: 3px;
          background-color: #e2e2e8;
          &.on {
            background-color: $secondary;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .matching-section {
    padding-bottom: 30px;
    .section-heading {
      padding-top: 30px;
      text-align: center;
      .section-legend {
        color: #6c6d70;
        font-size: 14px;
        font-weight: 600;
        .badge {
          color: #fff;
          padding: 2px 6px;
          margin-right: 5px;
          border-radius: 3px;
          display: inline-block;
          background-color: #aeaeb6;
        }
      }
    }
    .section-content {
      padding-top: 30px;
      text-align: center;
      .icon {
        &.schola-match-icon {
          width: auto;
          height: auto;
          margin-bottom: 20px;
          max-width: 100%;
          display: inline-block;
        }
      }
      .section-title {
        color: $primary;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        br {
          display: none;
        }
      }
      .section-instructions {
        color: #6c6d70;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        br {
          display: none;
        }
      }
      .survey-controls {
        padding-top: 30px;
        .geosuggest__suggests--hidden {
          display: none;
        }
        .geosuggest {
          max-width: 480px;
          margin: auto;
        }
        .geosuggest__item {
          padding: 16px;
        }
        .control-input {
          color: #6c6d70;
          font-size: 16px;
          font-weight: 300;
          width: 100%;
          height: 35px;
          padding: 0 12px;
          border-radius: 3.5px;
          margin-top: 1em;
          border: 0.7px solid #dddfe7;
          background-color: #f9f9fc;
          @media (min-width: 992px) {
            font-size: 12px !important;
          }
        }
        .geosuggest__input {
          color: #6c6d70 !important;
          font-weight: 300 !important;
          width: 100% !important;
          height: 35px !important;
          padding: 0 12px !important;
          border-radius: 3.5px !important;
          border: 0.7px solid #dddfe7 !important;
          background-color: #f9f9fc !important;
          @media (min-width: 992px) {
            font-size: 12px !important;
          }
        }
        .button-options {
          display: block;
          .control-button {
            color: #6c6d70;
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
            width: 140px;
            height: 140px;
            padding: 0 15px;
            margin: 0 auto 20px;
            display: block;
            border-radius: 7px;
            border: 1.4px solid #dddfe7;
            background-color: #f6f6fa;
            &:hover {
              border: 2px solid $secondary;
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .checkbox-options {
          .checkboxes {
            width: 320px;
            text-align: left;
            display: inline-block;
          }
          .checkbox-group {
            margin-bottom: 15px;
            &:last-of-type {
              margin-bottom: 0;
            }
            .help-button {
              top: 5.5px;
              right: -13px;
              color: #b0b1b5;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: 1px solid #b0b1b5;
              position: absolute;
              &::before {
                content: '?';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                font-size: 7px;
                font-weight: 600;
                position: absolute;
              }
            }
          }
          .checkbox-skin {
            display: inline-block;
            br {
              display: none;
            }
            label {
              font-size: 13px;
            }
          }
          .range-skin {
            label {
              color: #707174;
              font-size: 9px;
              font-weight: 300;
              margin-top: 6px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              span {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                &:last-child {
                  text-align: right;
                }
              }
            }
            input[type='range'] {
              width: 100%;
            }
          }
          .image-section {
            padding-bottom: 10px;
            margin-bottom: 15px;
            position: relative;
            &:before {
              content: '';
              left: 0;
              right: 0;
              bottom: 0;
              width: 180px;
              height: 1px;
              margin: auto;
              display: block;
              position: absolute;
              background-color: #ebecf1;
            }
            img {
              max-width: 100%;
              margin-bottom: 3px;
            }
            span {
              color: #6c6d70;
              font-size: 8px;
              font-weight: 500;
              text-transform: uppercase;
              line-height: 11px;
              width: 70px;
              margin: auto;
              display: block;
            }
          }
        }
        .submit-button {
          width: 100%;
          margin-top: 60px;
          -webkit-box-shadow: -1px 12px 20px 0 rgba(0, 53, 74, 0.14);
          box-shadow: -1px 12px 20px 0 rgba(0, 53, 74, 0.14);
        }
      }
    }
  }
}

.schola-match-disabled-button {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.checkbox-skin {
  position: relative;
  input[type='checkbox'] {
    left: -999px;
    opacity: 0;
    position: absolute;
  }
  input[type='checkbox']:checked {
    & + label {
      &:before {
        content: '✓';
        color: #fff;
        font-family: 'FontAwesome';
        font-size: 10px;
        text-align: center;
        line-height: 16px;
        border-color: $secondary;
        background-color: $secondary;
      }
    }
  }
  label {
    color: #707174;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:before {
      content: '';
      top: 1.5px;
      left: 0;
      width: 15px;
      height: 15px;
      display: block;
      position: absolute;
      border-radius: 50%;
      border: 1px solid #b0b1b5;
    }
    span {
      padding: 2.5px 10px 0 25px;
      display: inline-block;
    }
  }
}
.range-skin {
  input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent !important;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    height: 11px;
    width: 11px;
    margin-top: -4px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%;
    border-color: transparent;
    background: $primary;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 2px;
    background: rgba(2, 153, 211, 0.8);
  }
  input[type='range']::-moz-range-thumb {
    width: 11px;
    height: 11px;
    cursor: pointer;
    border-color: transparent;
    background: $primary;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 2px;
    background: transparent;
    background-color: #e9e8e9;
  }
  input[type='range']::-moz-range-progress {
    background: $primary;
  }
  input[type='range']::-ms-thumb {
    width: 11px;
    height: 11px;
    cursor: pointer;
    border-radius: 50%;
    border-color: transparent;
    background: $primary;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
  input[type='range']::-ms-track {
    color: transparent;
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    border-radius: 2px;
    background: $primary;
  }
  input[type='range']::-ms-fill-upper {
    border-radius: 2px;
    background: #e9e8e9;
  }
}
.progress-group {
  margin-bottom: 15px;
  .progress-label {
    color: #707174;
    font-size: 12px;
    font-weight: 300;
    width: 100%;
    display: block;
    overflow: hidden;
    .grade {
      float: left;
    }
    .percent {
      float: right;
    }
  }
  progress {
    color: $primary;
    width: 100%;
    height: 4px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    &::-webkit-progress-bar {
      border-radius: 2px;
      background-color: #e9e8e9;
    }
    &::-webkit-progress-value {
      border-radius: 2px;
      background-color: $primary;
    }
    &::-moz-progress-bar {
      border-radius: 2px;
      background-color: $primary;
    }
  }
}

@media (min-width: 768px) {
  .matching-layout {
    .matching-header {
      .progress-bars {
        .progress-bars__list {
          .progress-indicator {
            margin-right: 10px;
          }
        }
      }
    }
    .matching-section {
      .section-heading {
        padding-top: 40px;
      }
      .section-content {
        padding-top: 60px;
        .section-title {
          br {
            display: block;
          }
        }
        .section-instructions {
          br {
            display: block;
          }
        }
        .survey-controls {
          padding-top: 40px;
          .control-input {
            width: 323px;
          }
          .geosuggest__input {
            width: 323px !important;
          }
          .submit-button {
            bottom: 40px;
            width: 184px;
            margin: 50px auto 0;
            display: block;
          }
          .button-options {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            .control-button {
              margin: 10px;
            }
          }
          .checkbox-options {
            .image-section {
              margin-bottom: 30px;
            }
            .checkboxes {
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              .checkbox-group {
                .help-button {
                  top: 0;
                  right: -8px;
                  bottom: 0;
                  margin: auto;
                }
              }
            }
            .checkbox-group {
              width: 50%;
              margin-bottom: 20px;
            }
            .checkbox-skin {
              label {
                font-size: 14px;
                br {
                  display: block;
                }
              }
            }
            .range-skin {
              width: 140px;
            }
          }
        }
      }
      &.step-3 {
        .section-content {
          .survey-controls {
            .button-options {
              -webkit-box-pack: left;
              -ms-flex-pack: left;
              justify-content: left;
              .control-button {
                width: 108px;
                height: 108px;
              }
            }
          }
        }
      }
      &.step-10 {
        .section-content {
          .survey-controls {
            .checkbox-options {
              .checkboxes {
                .checkbox-group {
                  width: 25%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.alert-error {
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  min-height: 25px;
  padding: 8px 13px;
  margin-top: 6px;
  display: inline-block;
  border-radius: 3.5px;
  background-color: #ff5e5e;
}
.modal-non-close {
  .modal__close {
    display: none;
  }
}
.special-feature-tag {
  font-size: 10px;
  color: $primary;
  font-weight: 500;
  border: 1px solid $primary !important;
  margin-top: 16px !important;
  i {
    color: $primary !important;
  }
}
.schola__menu {
  text-align: left;
}
.schola__multi-value {
  margin: 2px 3px !important;
}
.geosuggest__input {
  font-size: 12px !important;
}
.schola-select {
  .schola__value-container {
    height: unset;
    min-height: 33px;
  }
}
.buttons-sm-resumen {
  button {
    display: inline;
    margin-right: 16px;
    @media (max-width: 767px) {
      display: block;
      margin-right: 0;
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
.enrollment-interest-form {
  h3 {
    font-size: 16px;
    font-weight: 500;
  }
}
.enrollment-interest-form__submit-button {
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  button {
    &:first-child {
      margin-right: 8px;
    }
  }
}
.suggested-school-content {
  @media (max-width: 767.98px) {
    flex-wrap: wrap;
  }
  .suggested-school {
    @media (max-width: 575.98px) {
      width: 100%;
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      width: 45%;
    }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      width: 33%;
    }
    @media (min-width: 1200px) {
      width: 27%;
    }

    .card-footer {
      padding: 16px 0;
      display: flex;
      justify-content: center;
    }
  }
}
.section-heading {
  p {
    margin-bottom: 16px;
  }
}
/**********************************************/
// v3 new design
.scholamatchv3 {
  .language {
    display: inline;
    width: 17%;
    .language-menu {
      right: 0;
    }
    .language-text {
      display: flex;
      justify-content: flex-end;
      span {
        display: none;
      }
    }
  }
  .navbar-brand {
    @media (max-width: 480px) {
      margin-right: 0;
    }
  }
}
.navbar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  &.navbar-expand-lg {
    justify-content: space-between;
  }
  @media (max-width: $screen-xs) {
    flex-wrap: nowrap;
    position: fixed;
    z-index: 9999;
    background: #fff;
    width: 100%;
  }
}

.quiz-wrapper {
  @media (max-width: $screen-xs) {
    padding-top: 84px;
  }
}
.container {
  &.scholamatch {
    height: 67vh;
    display: block;
    &.where-search {
      .text {
        display: inline;
        width: unset;
      }
    }
    &.which-school-applying {
      height: 90vh;
      .button-square {
        height: 8rem;
        width: 8rem;
        &.active {
          background: $dark-blue-700;
        }
      }
      .schola-select {
        width: 18rem;
      }
    }
  }
}
.container.scholamatch.where-search input,
.container.scholamatch.where-search button {
  width: 18rem;
}
.container.scholamatch.which-school-applying .which-school-applying-map,
.container.scholamatch.which-school-applying .which-school-applying-text {
  width: 100%;
  @media (min-width: $screen-md) {
    width: 50%;
  }
}
button {
  &.lets-start {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
  }
}
.image-footer {
  height: 160px;
  img {
    &.lets-start {
      object-fit: cover;
      margin-bottom: 0;
    }
  }
}
.modal-parent-form {
  z-index: 9999 !important;
  .modal__content {
    max-width: 450px;
    .section-heading {
      display: none;
    }
    .section-content {
      padding-top: 0 !important;
    }
    @media (max-width: $screen-xs) {
      overflow-y: scroll !important;
      height: 100%;
    }
  }
}
footer {
  &.lets-start {
    background: $primary;
    height: 5rem;
  }
}
.dropdown-school-year,
.question-buttons {
  display: flex;
  justify-content: center;
}
.question-buttons {
  flex-wrap: wrap;
}
.dropdown {
  &.dropdown-school-year {
    flex-direction: column;
    width: 250px;
    .dropdown-label {
      text-align: left;
    }
  }
}
.dropdown-school-year {
  .dropdown-select {
    width: 300px;
  }
}
.quiz-container {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.quiz-info-container {
  display: flex;
  flex-basis: 50%;
  order: 2;
  @media (max-width: $screen-md) {
    padding: 0 1rem;
  }
}
.quiz-info {
  margin-bottom: 3rem;
  @media (min-width: 770px) {
    padding: 0 2rem;
    width: 50%;
    padding-right: 2rem;
  }
}
.input-geosuggest {
  input {
    width: 18rem !important;
  }
}
.quiz-start-btn {
  button {
    width: 18rem;
  }
}
.quiz-btn-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  margin-top: 1em;
}
.quiz-map-container {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  order: 2;
  .quiz-map {
    min-height: 300px;
    height: 100%;
    position: relative;
    .school-criteria-number {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      color: #fff;
      padding: 10px;
      z-index: 999;
      top: 10px;
      left: 10px;
    }
  }
  .icon-hide-map {
    margin-top: -10px;
    z-index: 999;
    background: #fff;
    border-radius: 10px;
    padding-top: 10px;
    .rotate {
      transform: rotate(180deg);
    }
    @media (min-width: 769px) {
      display: none !important;
    }
  }
}
@media screen and (max-width: 769px) {
  .quiz-container {
    flex-direction: column;
  }
  .quiz-map-container {
    order: 1;
    flex-basis: 100%;
    .quiz-map {
      height: 300px;
    }
  }
  .quiz-info-container {
    order: 2;
    flex-basis: 100%;
  }
  .submit-form-parent {
    width: 100%;
  }
}
progress {
  margin-right: -1.6em;
  width: 90%;
  background-color: #eee;
  border-radius: 10px;
  background-color: #eee;
  border-radius: 10px;
  background-color: #95c23d;
  border-radius: 10px;
  &::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 10px;
  }
  &::-webkit-progress-value {
    background-color: #95c23d !important;
    border-radius: 10px;
  }
  &::-moz-progress-bar {
    background-color: #95c23d !important;
    border-radius: 10px;
  }
}
.progress-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  @media (max-width: $screen-sm) {
    padding: 0 24px;
  }
}
#Current-btn label,
#Pre-K-btn label,
#Elementary-btn label,
#Middle-btn label,
#High-btn label,
#btn-68-Yes label,
#btn-68-No label,
#btn-34-Other label,
#btn-34-Current label,
.button-checkbox-34 label,
.button-checkbox-51 label,
.button-checkbox-102 label {
  margin-left: 0 !important;
}
#Current-btn input,
#Pre-K-btn input,
#Elementary-btn input,
#Middle-btn input,
#High-btn input,
#btn-68-Yes input,
#btn-68-No input,
#btn-34-Other input,
#btn-34-Current input,
.button-checkbox-34 input,
.button-checkbox-51 input,
.button-checkbox-102 input {
  display: none;
}
.button-checkbox-85 {
  position: relative;
  span {
    display: block;
    margin-right: 3px;
    padding-left: 3px;
    line-height: 20px;
    text-align: right;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 3px;
      right: 3px;
      background: transparent;
      border: 2px solid #fff;
      border-radius: 3px;
      padding: 2px;
    }
    &::after {
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      top: 3px;
      right: 3px;
      font-size: 18px;
      color: #0087b7;
      line-height: 20px;
      text-align: center;
      padding: 2px;
    }
  }
  input[type='checkbox'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }
  input[type='checkbox']:checked {
    & + span {
      &::after {
        font-family: 'FontAwesome';
        content: '\f00c';
        background: transparent;
        color: #fff;
      }
    }
  }
}

.subtitle-letstart {
  @media (max-width: 768px) {
    width: 300px;
  }
}
.schola-noimage-filter {
  filter: sepia(100%) hue-rotate(155deg) saturate(200%);
}

.sm-form-project {
  label,
  input[type='text'],
  input[type='select'] {
    @media (max-width: 480px) {
      font-size: 1rem !important;
    }
  }
}
.schola-select {
  &.scholamatchv3 {
    .schola__menu {
      max-height: 150px !important;
      max-height: 100px !important;
    }
  }
  &.schola-interest {
    .schola__control {
      max-height: 105px;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 12%;
        background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, #fff 97%);
        background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, #fff 97%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, #fff 97%);
        opacity: 1;
      }
    }
    .schola__indicators {
      max-height: 105px;
      position: sticky;
      top: 0;
    }
    .schola__value-container {
      height: unset;
      min-height: 33px;
      max-height: 100px;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
