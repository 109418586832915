.table_title_gray {
  background-color: #f3f5f7;
  width: 100%;
  padding: 16px;
  border-radius: 8px 8px 0 0;
}

.table_row {
  display: grid;
  border: 1px solid #0000000f;
  font-size: 14px;
  grid-template-columns: 120px 1fr;

  & > div {
    padding: 16px;
  }
  &_title {
    font-weight: bold;
  }
}
