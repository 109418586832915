@import '../../../../../sass/color-palette';
@import '../../../../../sass/mixins';
@import '../../../../../sass/spacing';

.title {
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 2rem;
  color: $dark-blue-700;
}
.address {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: $dark-blue-500;
}

.profileMapSection {
  table {
    visibility: collapse;
  }

  .addressContainer {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin-bottom: 0.5rem;

    & svg {
      display: none;
    }

    @media (min-width: 768px) {
      @include toRem('margin-bottom', 16);
      & svg {
        display: block;
        align-self: center;
      }
    }
  }

  .profileMap {
    position: relative;
    margin: auto;
    @include toRem('height', 261);
    border: 1px solid #fff;
    border-radius: 0.5rem;
    overflow: hidden;

    @media (max-width: 767px) {
      overflow: inherit;
    }
  }
}

.gmnoprint {
  visibility: collapse;
}

.gm-style-cc {
  visibility: collapse;
}
