.container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  @media screen and (min-width: 321px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
