.container {
  overflow: auto;
  
  @media screen and (min-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 75% 20%;
    gap: 1.5rem;
  }
  @media screen and (min-width: 1420px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 1400px;
  }
}
.startHere {
  display: none;
  @media screen and (min-width: 900px) {
    display: flex;
    align-items: flex-end;
    height: 100%;
    background-color: #fff;
  }
}
.sponsored {
  display: none;
  @media screen and (min-width: 1000px) {
    display: block;
  }
}

.header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: max-content;
  position: sticky;
  top: 0;
  @media screen and (min-width: 768px) {
    gap: 1.5rem;
  }

  & span {
    text-align: center;
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: 180px repeat(3, 1fr);

    & .headerFirst:first-child {
      grid-column: 2;
    }
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content;

  @media screen and (min-width: 900px) {
    grid-template-columns: 180px repeat(3, 1fr);
  }
}

.box {
  grid-column: 1/4;
  display: grid;
  grid-template-columns: subgrid;

  @media screen and (min-width: 900px) {
    grid-column: 2 / 5;
  }
}

.ceil {
  border: 0.5px solid rgba(241, 242, 243, 1);
  display: grid;
  place-items: center;
  padding: 0.5rem 0;

  & img {
    width: 36px;
    height: 36px;
  }

  & svg {
    width: 36px;
    height: 36px;
  }

  @media screen and (min-width: 1000px) {
    border: none;
    border-bottom: 0.5px solid rgba(241, 242, 243, 1);
  }
}

.label {
  background: rgba(241, 242, 243, 1);
  height: 43px;
  font-weight: bold;
  grid-column: 1/4;
  display: grid;
  place-items: center;
  text-align: center;

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.leftBlock {
  display: none;

  @media screen and (min-width: 900px) {
    width: 100%;

    grid-row: 1 / 18;
    display: grid;
    grid-template-rows: subgrid;
  }
}

.leftLabel {
  display: grid;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  line-height: 1.3rem;
  border-bottom: 0.5px solid rgba(241, 242, 243, 1);
}
