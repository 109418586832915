.actionItem {
  height: 64px;
  background: #ffffff;
  border-bottom: 0.5px solid rgba(129, 138, 153, 0.2);
  padding: 0 0.5rem;
  cursor: pointer;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: inherit;

    .link {
      color: #006b8f;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1rem;
      }
    }

    p {
      color: inherit;
    }

    svg {
      fill: #006b8f;
    }
  }
}
