.map {
  height: 200px;
}

.icon {
  & svg {
    width: 2rem;
    height: 2rem;
    fill: #0099CC;
  }
}
