@import '../../../../sass/color-palette';

.schoolCurriculumContainer {
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
}

.subtitle {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.subtitleText {
  color: var(--dark-blue-100, #213556);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.curriculumContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 32px;
}

.descriptionText {
  color: var(--light-dark-blue-500-secondary-text-icon, #5f6a7d);
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.seeSchoolButton {
  border: 1px solid #9ebfc9;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  height: 32px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
}

.seeSchoolButton:hover {
  background-color: transparent;
}

.curriculumButton {
  display: flex;
  height: 32px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  color: $aqua-secondary;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.curriculumButton:hover {
  background-color: transparent;
}
