.modalResponse {
    margin-top: 40px;
}

.modalResponse .container {
    background-color: #fff;
    width: 50%;
    padding: 40px;
    border-radius: 15px;
}

.modalResponse .container h1 {
    text-align: center;
    font-size: 18px;
}

.modalResponse .container p {
    font-weight: 600;
    line-height: 20px;
}

.modalResponse .container .formContaimer {
    margin-top: 30px;
}

.modalResponse .container .formContaimer form p {
    margin-top: 40px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 25px;
}

.modalResponse .container .formContaimer form .error {
    font-size: 13px;
    margin: 0px;
    color: red;
}

.modalResponse .container .formContaimer .buttonBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.modalResponse .container .formContaimer .inputBox {
    width: 50%;
    position: relative;
    top: -40px;
    right: -80px;
}

.modalResponse .container .formContaimer .inputBox input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
}

.modalResponse .container .formContaimer .inputBox input:focus {
    outline: none;
}

.modalResponse .container .checkboxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.modalResponse .container .checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.modalResponse .container .checkboxContainer .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
}

.modalResponse .container .checkboxContainer:hover input ~ .checkmark {
    background-color: #ccc;
}

.modalResponse .container .checkboxContainer input:checked ~ .checkmark {
    background-color: $primary;
}

.modalResponse .container .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
}

.modalResponse .container .checkboxContainer .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
