.list_item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px 18px;
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 100px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 769px) {
    padding: 16px 24px;
  }
}
/*  
///// pendiente porque estara
///  dentro de otro componente

.title {
    font-size: 16px;
    font-weight: 600;
  } */
