.results-row {
  &:first-child {
    margin-top: 0 !important;
  }
}
.covid-protocol {
  .info-data {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid $primary;
    background-color: #dbf0f8;
    display: inline-block;
  }
  .fa {
    color: $primary;
  }
  .title-covid {
    color: $primary !important;
    font-size: 14px;
    font-weight: bold;
  }
  .title-covid-result {
    color: $secondary !important;
    font-size: 14px;
    font-weight: bold;
  }
}
.row-schools-results {
  min-height: 231px;
}
.layoutv3 {
  .row-schools-results {
    display: grid;
    grid-template-columns: 30% 70%;
  }
}
.info-data-address {
  font-weight: 500;
  font-size: 14px;
  color: #adb1bd;
  text-transform: capitalize;
}
.rate-content {
  background: rgba(0, 0, 0, 0.5);
  color: $secondary !important;
  width: 100%;
  font-size: 13px;
  height: 2rem;
  padding-left: 1rem !important;
  font-weight: 700 !important;
  .info-data-rate {
    font-size: 10px !important;
    position: absolute;
    margin-top: -0.5rem;
    color: #fff !important;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      margin-top: 0rem;
      margin-left: 1rem;
    }
  }
  @media screen and (max-width: 768px) {
    height: 1.3rem;
  }
}
.navbar {
  color: #808080;
  font-size: 0.7rem;
  .menu-icons {
    .nav-link {
      color: $primary !important;
    }
    .nav-link-green {
      color: $secondary !important;
    }
  }
  .nav-item {
    font-size: 14px;
    font-weight: bold;
  }
  .lineheight2 {
    margin-top: 0.5rem;
  }
  .navbar-toggler {
    border-color: #fff !important;
  }
  .navbar-toggler-icon {
    background-image: url('https://static.schola.com/v2/bars.png');
    width: 24px;
    height: 20px;
  }
}
.navbar .board-menu,
.navbar i {
  color: $primary;
}
.button-green {
  background-color: $secondary;
  color: #fff;
  padding: 12px 25px;
  height: 45px;
  font-size: 14px;
  font-weight: bold;
}
.layoutv2 {
  .layoutv2-search {
    padding-top: 6px !important;
    .address__radius__wrapper {
      width: 100%;
      @media (min-width: 480px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .s-search-button-holder {
      top: -15px;
    }
    .search-box,
    .s-geo-search-container {
      > label {
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
    .page-results {
      .geosuggest {
        position: absolute;
        width: 230px;
      }
    }
    .page-results .geosuggest__input-wrapper,
    .page-results .geosuggest__suggests-wrapper {
      width: 230px;
    }
  }
  .school-search-header__address__radius {
    border-bottom: 1px solid #ebeff4 !important;
    border-top: 0;
  }
  .school-search-content {
    position: relative;
    .fav {
      display: flex;
      justify-content: flex-end;
    }
  }
}
.filter-menu {
  .btn-section {
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
    .message {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
.button-map-filter {
  span.children {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }
}
.layoutv2-search {
  .text-blue {
    color: $primary !important;
  }
  .search-result__detail--title--star {
    .school-card--detail__title {
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 1rem !important;
    }
  }
  .school-search-header__radius {
    text-align: left !important;
    @media (max-width: 480px) {
      padding-right: 0;
    }
  }
  button {
    outline: none;
  }
  .info-row {
    .icon-logo-great {
      height: 30px;
      width: 55px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .school-search-header {
    .address {
      a {
        svg {
          color: $primary;
          margin-left: 0.5rem;
        }
      }
      @media screen and (max-width: 480px) {
        border: 1px solid $secondary;
        border-radius: 3px;
        max-height: 40px;
        font-size: 12px !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      @media screen and (min-width: 481px) and (max-width: 1365px) {
        font-size: 0.7rem;
        line-height: 100%;
        padding-top: 0;
      }
      .schools-near {
        @media screen and (max-width: 1365px) {
          font-size: 0.85rem;
          line-height: 100%;
          padding-top: 0;
        }
      }
    }
    .school-search-header__address__radius {
      @media screen and (max-width: 768px) {
        border: none !important;
        height: 100% !important;
      }
    }
  }
  .button--size--small,
  .button + .button {
    @media screen and (max-width: 480px) {
      margin-left: 0 !important;
      min-width: unset !important;
    }
  }
  .button__children {
    svg {
      @media screen and (max-width: 480px) {
        margin: 0 0.15rem;
        font-size: 1.3rem;
      }
    }
  }
  .btn-lightgray {
    background: #f5f7f9 !important;
    border-radius: 3.5px !important;
    color: #91949f !important;
    font-weight: 600 !important;
    font-size: 11px !important;
    border: none !important;
  }
  .map-bubble {
    cursor: default;
  }
  .map-bubble__icon {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.3em;
  }
  .map-bubble__header--rating {
    display: none;
  }
  .map-bubble__header--name {
    text-transform: capitalize;
  }
  .map-bubble__details,
  .map-bubble__details--address,
  .map-bubble__details--type,
  .map-bubble__details--grades {
    color: #adb1bd !important;
  }
  .search-filter {
    max-height: 90%;
  }

  .search-filter {
    max-height: 90%;
    z-index: 1;
    margin: 0px;
    width: 110%;
    @media (max-width: 480px) {
      margin: 6px 0;
    }
    .header {
      font-weight: bold;
      font-size: 12px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: 0;
      color: $secondary;
      border-radius: 3.5px;
      border: 1px solid $secondary;
      box-sizing: border-box;
      height: 35px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
      max-width: 156px;
      text-align: left;
      cursor: pointer;
      span {
        padding-left: 9px;
      }
      @media screen and (max-width: 992px) {
        font-size: 0.6rem;
      }
    }
    .filled {
      background: $secondary;
      color: #fff;
    }
    .material-icons {
      cursor: pointer;
      transition: 0.3s transform ease-in-out;
    }
    .icon-arrow-up {
      transform: rotate(180deg);
    }
    .filled {
      background: $secondary;
      color: #fff;
    }
    .material-icons {
      cursor: pointer;
      transition: 0.3s transform ease-in-out;
    }
    .icon-arrow-up {
      transform: rotate(180deg);
    }
    .filter-container {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #44464b;
      border-radius: 3.5px;
      text-transform: capitalize;
      overflow: auto;
      transition: 0.3s max-height ease-in-out;
      background-color: #fff;
      background: #fff;
      border-radius: 3.5px;
      width: 110%;
      margin: auto;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      &.collapsed {
        max-height: 0;
        box-shadow: none;
      }
      @media (min-width: 768px) {
        position: absolute;
        z-index: 9999;
      }
      .filter-list {
        margin-top: 10px;
        display: flex;
        flex-flow: column;
        overflow-y: auto;
        max-height: 60em;
        width: 110%;
        height: 90%;
        margin: auto;
        .nodata {
          text-align: center;
        }
        .checkbox {
          margin-left: 0px;
          & + .checkbox {
            margin-left: 0px;
          }
        }
        .radio-icon {
          height: 28px !important;
          width: 28px !important;
          .icon {
            width: unset !important;
            height: unset !important;
          }
        }
        .miles-form {
          height: 90px;
          margin: 5px;
          .content {
            width: 100%;
            margin: 0 auto;
            .miles-box {
              width: 100px;
              margin: 10px 30px;
            }
            .btn-content {
              margin: 10px auto;
              text-align: center;
            }
          }
        }
      }
    }
    .checkbox-icon {
      & + .checkbox-label {
        padding-top: 0px;
        padding-right: 5px;
        padding-bottom: 0px;
      }
    }
    .school-search-filter__heading {
      span {
        &:nth-child(1) {
          display: inline-block;
          color: $secondary;
        }
      }
    }
  }
  .school-search {
    .btn-white {
      background-color: #fff;
      color: $primary;
      border-color: #fff;
      .green {
        color: $secondary;
      }
    }
  }
  .school-card--detail .school-card--detail__info,
  .school-card--detail .material-icons {
    color: #44464b !important;
  }
  .school-card--detail__title {
    font-size: 16px;
    font-weight: 500;
    a {
      color: #000;
      :hover {
        color: #000;
      }
    }
  }
  .search-result__detail--title--star {
    color: #000;
  }
  .school-card--detail__info--buttons {
    padding-top: 20px;
  }
}
.map-button__wrapper .button-bubble {
  @media (max-width: 767px) {
    border: none;
    padding: 0;
  }
}
.school-search-content {
  background-color: #fff;
  .section {
    width: 100%;
    &.collapsed {
      display: none;
    }
  }
}
.all-filters-content {
  max-width: 1100px;
  min-height: 400px;
  min-width: 350px;
  background-color: #fff;
  margin: 0px auto;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  .all-filters {
    margin: 0 25px;
    display: grid;
    grid-template-columns: 100%;
    @media (min-width: 768px) {
      grid-template-columns: 26% 40% 33%;
      margin: 25px;
      gap: 1rem;
    }
    .input-miles {
      width: 100%;
    }
    .column {
      display: grid;

      &.column-first {
        @media (min-width: 768px) {
          grid-template-columns: 100%;
          grid-template-rows: min-content;
          align-items: baseline;
        }
      }

      .row-section {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        gap: 1.5rem;

        @media (min-width: 768px) {
          grid-template-columns: 100%;
          gap: 0;
        }
      }
      &.second-column {
        @media (min-width: 768px) {
          grid-template-columns: 55% 45%;
          grid-template-rows: min-content;
          align-items: baseline;
        }
      }

      .filter-section {
        margin: 25px 0;
        @media (min-width: 769px) {
          margin: 15px;
        }
        @media (min-width: 568px) {
          max-width: 235px;
        }
        .title {
          font-weight: bold;
          font-size: 18px;
          line-height: 150%;
          letter-spacing: 0.02em;
          margin-bottom: 1rem;
        }
        .section-content {
          //max-width: 175px;
          .checkbox {
            margin-left: 0px;
            & + .checkbox {
              margin-left: 0px;
            }
          }
          .element {
            display: block;
            text-transform: capitalize;
          }
          .features-content {
            //padding-left: 25px;
            width: 220px;
            &.collapsed {
              display: none;
            }
            .features {
              display: block;
            }
            .checkbox-label {
              padding-top: 0.438rem;
              padding-bottom: 0.438rem;
            }
          }
        }
      }
    }
  }
  .filter-footer {
    background: #fff;
    margin: 0 25px;
    height: 50px;
    padding-top: 10px;
    @media (max-width: 480px) {
      height: auto;
    }
    .message {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: $primary;
      border-radius: 3.5px;
      margin: 10px;
      color: #000;
      @media (max-width: 480px) {
        margin-right: 0;
        margin-left: 0;
      }
    }
    .blue {
      color: $primary;
    }
    .btn-section {
      min-height: 45px;
      margin: 10px;
      @media (max-width: 480px) {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }
      .button-filters {
        @media (min-width: 768px) {
          padding-right: 0.2rem;
          padding-left: 0.2rem;
          margin-right: 10px;
        }
      }
    }
  }
  .modal__content {
    width: 512px;
    padding: 0;
    .modal__header {
      display: none !important;
    }
    .modal__children {
      padding: 0;
      .looking-for-modal {
        .header {
          width: auto;
          height: 80px;
          background: #fff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 3.5px;
          padding: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: #44464b;
            border-radius: 3.5px;
            float: left;
          }
          .close-box {
            .btn-close {
              float: right;
            }
          }
        }
        .body {
          padding: 24px;
          .review-form {
            .message {
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #adb1bd;
            }
            .btn-green {
              background: $secondary;
              border-radius: 3.5px;
              width: 100%;
              height: 45px;
              .btn-txt {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.02em;
                color: #fff;
                margin: 0 auto;
                width: 300px;
              }
            }
          }
        }
      }
    }
  }
}
.school-search-header {
  width: unset;
  margin-top: 0;
  margin-left: 0;
  @media (min-width: 480px1281px) {
    height: 70px;
  }
  .school-search-header__address__radius {
    margin-top: 0 !important;
    background-color: #fff;
  }
  .address {
    width: 100%;
    color: #adb1bd;
    padding-top: 6px;
    padding-bottom: 6px;
    @media (min-width: 768px) {
      width: auto;
    }
  }
}
.to-right {
  margin-right: auto;
}
.map-view-beta {
  width: 100%;
  height: 100%;
  display: inline-block;
  &.collapsed {
    width: 100%;
  }
}
.map-list-beta {
  background: #fff;
  height: 95%;
  display: inline-block;
  width: 40%;
  &.collapsed {
    max-height: 0;
    display: none;
  }
  .map-list-schools {
    height: calc(100vh - 190px);
    overflow: auto;
    .results-row {
      background: #fff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
      border-radius: 3.5px;
      margin-left: auto;
      margin-right: auto;
      .unassigned-school {
        width: 595px;
        height: 20px;
        background: #fff;
        border-radius: 3.5px;
        padding: 8px 20px;
      }
      .assigned-school {
        width: 600px;
        height: 31px;
        background: $primary;
        border-radius: 3.5px;
        padding: 8px 20px;
        .text {
          font-weight: 600;
          font-size: 11px;
          line-height: 150%;
          letter-spacing: 0.06em;
          text-transform: uppercase;
          color: #fff;
        }
      }
      .result-image {
        transform: matrix(1, 0, 0, 1, 0, 0);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-right: 0;
      }
      .result-content {
        .line {
          border: 1px solid #ebeff4;
          width: 100%;
        }
        .details,
        .school-card--detail {
          padding-top: 15px;
          .header {
            padding-bottom: 10px;
            .name {
              font-weight: bold;
              font-size: 18px;
              line-height: 150%;
              letter-spacing: 0.02em;
            }
            .fav {
              width: 50px;
              color: #ff5e5e;
              display: flex;
              justify-content: flex-end;
            }
            .enroll {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: $secondary;
              .date {
                color: #91949f;
              }
            }
            .info {
              .info-row {
                text-transform: capitalize;
                .icon {
                  width: 30px;
                  margin-right: 0.5rem;
                  display: flex;
                  justify-content: flex-start;
                }
                .info-data {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 150%;
                  letter-spacing: 0.02em;
                  color: #6c6d70 !important;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .fa-graduation-cap .svg {
              color: $secondary !important;
            }
          }
        }
      }
    }
  }
}

.info-data {
  .icon-pin {
    margin-left: -5px;
  }
}
.matches {
  display: flex;
  padding: 0 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &.is-mobile {
    @media (max-width: 767px) {
      padding-top: 10px;
      margin-top: 1rem;
      border-top: 2px solid #e6e6e8;
    }
  }
}
.feature-more {
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $primary;
  margin: 5px;
  padding-left: 5px;
}
.feature-matched {
  width: auto;
  height: 35px;
  background: #f5f7f9;
  border-radius: 3.5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #91949f;
  padding: 0 10px;
  margin-right: 0.5rem;
  margin-bottom: 0.7rem;
}
.modal-create-new-school-board {
  .modal__content {
    @media (max-width: 992px) {
      width: 540px !important;
    }
  }
  .modal__children,
  .board-btn {
    padding-bottom: 0 !important;
  }
}
.scholamatch-zero-result {
  .modal__content {
    .content {
      h2 {
        font-weight: 700;
        margin-bottom: 24px;
      }
      p {
        font-weight: 500;
        color: #6c6d70;
        margin-bottom: 16px;
        font-size: 14px;
      }
      .form-row {
        margin: 24px 0;
        label {
          color: #6c6d70;
          font-weight: 500;
        }
        input {
          border: 1px solid #c3c3c3;
        }
      }
      .button--color--secondary {
        border-color: $secondary !important;
        background-color: $secondary !important;
        color: #fff;
        width: 100%;
      }
      .button--color--primary {
        border-color: $primary !important;
        background-color: $primary !important;
        color: #fff;
      }
    }
    @media (min-width: 768px) {
      width: 540px !important;
    }
  }
  .modal__header {
    display: none !important;
  }
  .modal__children {
    padding: 24px !important;
    padding-top: 0 !important;
  }
}
.modal-board {
  max-width: 554px !important;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  .modal__title {
    margin-left: 0;
  }
  &.size2 {
    .modal__content {
      z-index: 1000;
      .modal__children {
        height: 540px;
      }
    }
  }
  .modal__content {
    z-index: 1000;
    width: 100% !important;
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
      .board-content {
        .board-header {
          height: 30px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 3.5px;
          padding: 0 15px;
          .titles {
            margin-left: 0;
            float: left;
            .title {
              font-weight: bold;
              font-size: 18px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #44464b;
              border-radius: 3.5px;
            }
            .sub-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #44464b;
            }
          }
          .close-box {
            float: right;
          }
        }
        .board-body {
          padding-top: 19px;
          padding: 15px;
          .board-form {
            margin: 0 auto;
            padding-bottom: 30px;
            .content {
              .label {
                font-weight: 500;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.02em;
                color: #adb1bd;
              }
              .boardName {
                border: 1.5px solid #e2e2e2;
                box-sizing: border-box;
                border-radius: 3.5px;
                height: 50px;
              }
            }
          }
          .board-school {
            background: #fff;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
            border-radius: 3.5px;
            margin: 0 auto;
            margin-bottom: 30px;
            .school-image {
              transform: matrix(1, 0, 0, 1, 0, 0);
              width: 100px;
              height: 100px;
              float: left;
              background-size: 100%;
              background-position: 0;
              background-repeat: no-repeat;
            }
            .school-info {
              display: block;
              margin: auto 1rem;
              display: flex;
              .header {
                display: block;
                margin: auto;
                .name {
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 150%;
                  letter-spacing: 0.02em;
                }
                .enroll {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 150%;
                  letter-spacing: 0.02em;
                  color: $secondary;
                  float: left;
                  .date {
                    color: #91949f;
                  }
                }
              }
              .fav {
                width: 50px;
                color: #ff5e5e;
                margin: auto;
              }
            }
          }
          .board-btn {
            margin: 0 auto;
            padding-bottom: 25px;
            .btn-green-large {
              height: 45px;
              background: $secondary !important;
              border-radius: 3.5px;
              font-weight: bold;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #fff;
              width: 100%;
              .button__children {
                margin: 0 auto;
              }
            }
            .singup-login-content {
              padding-top: 15px;
              .singup-login {
                margin: 0 auto;
                width: 330px;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 150%;
                letter-spacing: 0.04em;
                color: #adb1bd;
              }
            }
            .btn-content {
              height: 45px;
              display: flex;
              justify-content: center;
              .btn-white {
                width: 250px;
                height: 45px;
                border: 1px solid $secondary;
                box-sizing: border-box;
                border-radius: 3.5px;
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.02em;
                color: $secondary;
                float: left;
                .button__children {
                  margin: 0 auto;
                }
              }
              .btn-green {
                width: 250px;
                height: 45px;
                background: $secondary !important;
                border-radius: 3.5px;
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.02em;
                color: #fff;
                float: left;
                .button__children {
                  margin: 0 auto;
                }
              }
            }
          }
          .board-list-content {
            width: 521px;
            margin: 0 auto;
            margin-bottom: 25px;
            .board-item {
              padding: 13px;
              margin: 5px 0;
              background: #fff;
              box-sizing: border-box;
              border: 1px solid #ebeff4;
              border-radius: 3.5px;
              height: 47px;
              width: 515px;
              &.selected {
                border: 1px solid $secondary;
              }
              .name {
                float: left;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: 0.02em;
                color: #44464b;
                &:before {
                  content: '\f111';
                  font-family: 'FontAwesome';
                  font-style: normal;
                  font-weight: 200;
                  font-size: 20px;
                  line-height: 25px;
                  text-align: right;
                  letter-spacing: -0.34px;
                  padding: 0 10px;
                }
                &.selected {
                  &:before {
                    color: $primary;
                    content: '\f192';
                    font-weight: 900;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal-loggin {
  width: 616px;
  height: 480px;
  .modal__content {
    z-index: 1000;
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
      height: 360px;
      .loggin-content {
        .header {
          height: 80px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 3.5px;
          padding: 15px;
          padding-top: 20px;
          .titles {
            margin-left: 20px;
            float: left;
            .title {
              font-weight: bold;
              font-size: 18px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #44464b;
              border-radius: 3.5px;
            }
            .sub-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #44464b;
            }
          }
          .close-box {
            float: right;
            color: $secondary;
          }
        }
        .body {
          padding-top: 20px;
          .form-content {
            width: 520px;
            margin: 0 auto;
            padding-bottom: 30px;
            .tbx-email {
              width: 522px;
              height: 50px;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #91949f;
              margin: 10px 0;
            }
            .tbx-pwd {
              width: 522px;
              height: 50px;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #91949f;
              margin: 10px 0;
            }
            .btn-green-large {
              width: 521px;
              height: 45px;
              font-weight: bold;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #fff;
              background: $secondary !important;
              border-radius: 3.5px;
              margin-top: 30px;
              margin-bottom: 10px;
              .button__children {
                margin: 0 auto;
              }
            }
            .footer {
              text-align: center;
              .footer-msg {
                font-weight: 500;
                font-size: 12px;
                line-height: 150%;
                color: #91949f;
              }
            }
          }
        }
      }
    }
  }
}
.modal-loggin {
  width: 616px;
  height: 480px;
  .modal__content {
    z-index: 1000;
    .modal__header {
      display: none;
    }
    .modal__children {
      padding: 0;
      height: 360px;
      .loggin-content {
        .header {
          height: 80px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 3.5px;
          padding: 15px;
          padding-top: 20px;
          .titles {
            margin-left: 20px;
            float: left;
            .title {
              font-weight: bold;
              font-size: 18px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #44464b;
              border-radius: 3.5px;
            }
            .sub-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #44464b;
            }
          }
          .close-box {
            float: right;
            color: $secondary;
          }
        }
        .body {
          padding-top: 20px;
          .form-content {
            width: 520px;
            margin: 0 auto;
            padding-bottom: 30px;
            .tbx-email {
              width: 522px;
              height: 50px;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #91949f;
              margin: 10px 0;
            }
            .tbx-pwd {
              width: 522px;
              height: 50px;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #91949f;
              margin: 10px 0;
            }
            .btn-green-large {
              width: 521px;
              height: 45px;
              font-weight: bold;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #fff;
              background: $secondary !important;
              border-radius: 3.5px;
              margin-top: 30px;
              margin-bottom: 10px;
              .button__children {
                margin: 0 auto;
              }
            }
            .footer {
              text-align: center;
              .footer-msg {
                font-weight: 500;
                font-size: 12px;
                line-height: 150%;
                color: #91949f;
              }
            }
          }
        }
      }
    }
  }
}
.modal-saving-search {
  width: 616px;
  height: 480px;
  .modal__content {
    z-index: 1000;
    height: 415px !important;
    width: 630px;
    .modal__header {
      display: none !important;
    }
    .modal__children {
      padding: 0 !important;
      .content {
        .header {
          height: 80px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
          border-radius: 3.5px;
          padding: 15px;
          padding-top: 20px;
          .titles {
            margin-left: 20px;
            float: left;
            .title {
              font-weight: bold;
              font-size: 18px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.02em;
              color: #44464b;
              border-radius: 3.5px;
            }
            .sub-title {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #44464b;
            }
          }
          .close-box {
            float: right;
            color: $secondary;
          }
        }
        .body {
          padding-top: 20px;
          .body-content {
            width: 521px;
            margin: 0 auto;
            .label {
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #adb1bd;
            }
            .txb-search {
              width: 521px;
              height: 50px;
              border: 1.5px solid #e2e2e2;
              box-sizing: border-box;
              border-radius: 3.5px;
            }
            .message {
              padding-top: 30px;
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.02em;
              color: #44464b;
              border-radius: 3.5px;
            }
            .option {
              display: block;
              width: 500px;
            }
            .btn-Green {
              margin-top: 15px;
              width: 521px;
              height: 45px;
              background: $secondary !important;
              border-radius: 3.5px;
              font-size: 14px;
              line-height: 150%;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.02em;
              color: #fff;
              .button__children {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
.hover-list-result {
  box-shadow: 0px 0px 0px 2px rgba(0, 140, 255, 0.25) !important;
  border-radius: 10px !important;
  border: 1px solid #09c;
  cursor: pointer;
  transition: 0.2s all;
}
.heard-icon-hover {
  cursor: pointer;
}
.selectCatFilter {
  margin-bottom: 1rem;
}
.checkboxSelectCatFilter {
  display: inline-flex;
  margin-bottom: 1rem;
  .checkbox-label {
    font-weight: 700 !important;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
}
.checkboxSelectFilter {
  display: inline-flex;
  font-weight: 900 !important;
  margin-left: 0.4rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
  .featureGroup {
    .checkbox-label {
      font-weight: 600 !important;
      font-size: 16px;
    }
  }
}
.icon-collapse-filter {
  margin-top: 12px;
  cursor: pointer;
  color: $secondary;
}
.label-filter-select {
  padding-top: 5px !important;
  font-weight: 600 !important;
  color: #4a4a4a !important;
  cursor: pointer;
  padding-right: 12px;
  font-size: 12px;
  width: 160px;
}
.hidden-op {
  display: none;
}
.e-name-filter {
  //width: 250px;
}
.schools-list-container-scroll {
  transition: all 0.9s ease;
}
.milles-i-list {
  display: flex;
  font-size: 13.5px;
  font-weight: bold;
  .icon {
    margin-right: 0.5rem !important;
  }
}
.school-card--img {
  height: 100%;
  overflow: hidden;
  .LazyLoad {
    height: 100% !important;
  }
  img {
    height: 100% !important;
    object-fit: cover;
    @media (min-width: 481px) {
      min-height: 150px;
    }
  }
}
.card-buttons-results {
  display: flex;
  gap: 0.25rem;
}
.card-buttons-results {
  display: flex;
  gap: 0.25rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .school-search-header__radius,
  .address__radius__wrapper {
    flex-direction: column !important;
  }
  .school-search-header__address__radius {
    padding-top: 2rem !important;
  }
  .adreess {
    width: 300px;
    display: flex;
  }
  .button-primary-medium-outlined-regular {
    min-width: unset !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .school-search-header {
    display: block !important;
    margin-top: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .school-search-header__radius {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1281px) {
  .school-search-header__address__radius {
    height: 71px;
  }
  .school-search-header {
    align-items: center;
  }
}
