@import '../../../../../sass/color-palette';

.testimonialCard {
  border-radius: 12px;
  border: 1px solid $dark-blue-15;
  padding: 24px;
  height: fit-content;
  @media (min-width: 768px) {
    cursor: grab;
  }

  &_text {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    width: 95%;
  }

  &_info {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_layout {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_photo {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: $dark-blue-15;
    }

    &_name {
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
    }

    &_role {
      color: $dark-blue-75;
      font-size: 12px;
      line-height: 170%;
    }

    svg {
      fill: $primary-500;
    }
  }

  &_showButton {
    padding: 0.5rem 0;
    color: $dark-blue-50;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
  }
}

.grabbing {
  @media (min-width: 768px) {
    cursor: grabbing;
  }
}
