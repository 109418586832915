// fonts and typography styles
//main font
@use 'themes';
@use 'mixins';
@use 'variables';

html,
body {
  font-size: variables.$font-base;
  font-family: variables.$sans;
}

@mixin Heading(
  $fontSizeDesktop,
  $lineHeightDesktop,
  $fontSizeMobile,
  $lineHeightMobile,
  $fontweightDesktop: 400,
  $fontweightMobile: 400
) {
  @include mixins.toRem('font-size', $fontSizeDesktop);
  @include mixins.toRem('line-height', $lineHeightDesktop);
  font-weight: $fontweightDesktop;

  @media (max-width: 768px) {
    @include mixins.toRem('font-size', $fontSizeMobile);
    @include mixins.toRem('line-height', $lineHeightMobile);
    font-weight: $fontweightMobile;
  }
}
.layoutv3 {
  h1.heading-1,
  h1,
  .heading-1 {
    @include themes.theme-aware('color', 'dark-blue-700');
    @include Heading(32, 38.4, 30, 36, 600, 600);
    font-family: variables.$montserrat;
  }
  h2.heading-2,
  h2,
  .heading-2 {
    @include themes.theme-aware('color', 'dark-blue-700');
    @include Heading(28, 33.6, 26, 31.2, 600, 600);
    font-family: variables.$sans;
  }
  h3.heading-3,
  h3,
  .heading-3 {
    @include themes.theme-aware('color', 'dark-blue-700');
    @include Heading(22, 26.4, 20, 24, 600, 600);
    font-family: variables.$sans;
  }
  h4.heading-4,
  h4,
  .heading-4 {
    @include themes.theme-aware('color', 'dark-blue-700');
    @include Heading(20, 24, 18, 21.6, 600, 600);
    font-family: variables.$sans;
  }
  h5.heading-5,
  h5,
  .heading-5 {
    @include themes.theme-aware('color', 'dark-blue-700');
    @include Heading(18, 25.2, 16, 19.2, 600, 700);
    font-family: variables.$sans;
  }
  h6.heading-6,
  h6,
  .heading-6 {
    @include themes.theme-aware('color', 'primary-700');
    @include Heading(16, 22.4, 16, 19.2, 600, 700);
    font-family: variables.$sans;
  }
  .body-large {
    @include themes.theme-aware('color', 'dark-blue-75');
    @include Heading(18, 28.8, 18, 27);
    font-family: variables.$sans;
  }
  .body-regular {
    @include themes.theme-aware('color', 'dark-blue-75');
    @include Heading(16, 25.6, 16, 23.2);
    font-family: variables.$sans;
  }
  .body-small {
    @include themes.theme-aware('color', 'dark-blue-75');
    @include Heading(14, 23.8, 14, 21);
    font-family: variables.$sans;
  }
  .body-xs-small {
    @include themes.theme-aware('color', 'dark-blue-75');
    @include Heading(12, 20.4, 12, 20.4);
    font-family: variables.$sans;
  }
  .text-xs-small {
    @include Heading(14, 21, 14, 21, 400, 400);
    font-family: variables.$sans;
  }
  .text-caption {
    @include Heading(14, 21, 14, 21, 600, 600);
    font-family: variables.$sans;
  }
  .text-overline {
    text-transform: uppercase;
    @include Heading(12, 18, 12, 18, 700, 700);
    font-family: variables.$sans;
  }
  h1.font-display-3 {
    font-weight: 700 !important;
    font-size: 44px !important;
    line-height: 52.8px !important;
  }
}
a {
  @include themes.theme-aware('color', 'text-link-primary');
  text-decoration: none;
  &:hover {
    @include themes.theme-aware('color', 'text-link-primary');
  }
}
.font-600 {
  font-weight: 600 !important;
}
b,
strong,
.font-700 {
  font-weight: 700 !important;
}

.font-600 {
  font-weight: 600 !important;
}
.font-700 {
  font-weight: 700 !important;
}
.underline {
  text-decoration: underline;
}
