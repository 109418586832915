@import '../../../../../../sass/color-palette';

@mixin activeButton {
  background: $secondary-100;
  color: #0c5e79;
  border: 0.8px solid $secondary-100;
}

.label {
  color: $dark-blue-600;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 0.875rem;
}

.message {
  border-radius: 0.5rem;
  border: 1px solid $dark-blue-300-subtle-border-disabled-text;
  padding: 1rem 0.88rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  &_input {
    border: none;
    resize: none;
    height: 4rem;
    padding: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;

    &:focus {
      outline: none;
    }
  }

  &_options {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.chip {
  padding: 0.5rem 0.75rem;
  border: 0.8px solid $secondary-200;
  border-radius: 1.25rem;
  width: fit-content;

  &:hover {
    @include activeButton();
  }

  &:focus {
    @include activeButton();
    outline: 0.8px solid $secondary-100;
  }

  & span {
    text-align: left;
  }
}
