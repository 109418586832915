.school-about__faq {
  padding-top: 1rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  text-align: left;

  &-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &-item {
    color: #1a2a45;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #f3f5f7;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    cursor: pointer;

    &.open {
      .school-about__faq-answer {
        display: block;
      }
    }

    .school-about__faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
    }

    .school-about__faq-answer {
      display: none;
      margin-top: 8px;
      font-size: 16px;
      line-height: 1.5;
    }
  }
}
