.groups {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.group {
  background: #f3f5f7;
  padding: 1rem;
  border-radius: 0.5rem;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  bottom: 16px; /* Ensure it is aligned to the bottom */
  left: 16px;
  right: 16px;
  background-color: #f3f5f7; /* Match the background of the feature list */
  padding: 8px; /* Add padding for better appearance */
  border-radius: 8px; /* Match the border radius of the feature list */

  button {
    background-color: #006b8f;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {
      background-color: #a9a9a9;
      cursor: not-allowed;
    }
  }

  span {
    font-family: 'Open Sans', Helvetica, sans-serif;
    font-size: 16px;
  }
}
