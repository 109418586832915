@import '../../../../../sass/mixins';

.gallery-modal {
  &__title {
    text-transform: capitalize;
    z-index: 10000;
  }
}

.layoutv3 {
  .image-gallery-slide .image-gallery-image {
    padding: 0 120px;
    width: unset;
    max-height: calc(100vh - 280px) !important;
  }

  .image-gallery-slide-wrapper {
    button {
      color: #546982;
    }
  }

  .image-gallery-thumbnails {
    width: fit-content;
    margin: 28px auto 0 auto;
    nav {
      display: flex;
    }

    button {
      border-radius: 8px;
      width: 140px;
      height: 126px;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }
  }
}
