.buttonGroup {
  display: flex;
  justify-content: center;
}
.button_radio {
  border: 1px solid #006b8f;
  padding: .5rem;
  text-align: center;
  font-size: 14px;

  &:first-child {
    border-radius: 4px 0 0 4px;
  }
  &:last-child {
    border-radius: 0 4px 4px 0;
    margin-left: -1px;
  }
}
.checked {
  background-color: #e6f5fa;
  color: #006b8f;
}
