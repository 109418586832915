@import '../../../../../sass/color-palette';
@import '../../../../../sass/mixins';
@import '../../../../../sass/spacing';

.galleryContainer {
  border-radius: 0.5rem;
  overflow: hidden;
  width: 100%;
  height: 38rem;
  position: relative;
}

.image {
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.content {
  height: 100%;

  .main {
    height: 29rem;
    margin-bottom: 0.5rem;
  }

  .strip {
    height: 8rem;
    display: grid;
    grid-auto-flow: column;
    gap: 0.5rem;
  }
}

.mobilebuttons_bottom {
  position: absolute;
  bottom: 2%;
  right: 0.5rem;
  z-index: 50;

  button {
    font-size: 1rem;
    display: flex;
    gap: 0.5rem 0.2rem;
    align-items: center;
    padding: 0.5rem;
    color: white;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    font-weight: 600;
    max-height: 36px;
    font-size: 14px;
  }
}
.mobilebuttons_top {
  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    top: 8%;
    right: 1rem;
  }

  .galleryButtons {
    top: 8px;
    gap: 0;
    right: 0;
    z-index: 0;
  }
}
