.buttonRetool {
  background: #006b8f;
  color: #fff;
  border-radius: 2px;
  width: 100%;
  text-align: center;
  padding: 0.75rem 1rem;
  font-weight: 700;
}
.pro {
  background: linear-gradient(91.04deg, #ad6800 0%, #faad14 106.41%);
}
.green {
  background: #007a4d;
}
