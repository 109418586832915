.careersGrid {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 740px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
}

.career {
  padding: 0.5rem;
  border-radius: 16px;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 0px 1px 0px #0c1a4b3d;

  .image {
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  .text {
    padding-left: 10px;
    padding-right: 10px;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #425466;
    margin-bottom: 0.5rem;
  }
}
.title {
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 16px !important;
}
