@import '../../sass/color-palette';

.topList {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 0.75rem 0.25rem;
  @media screen and (min-width: 768px) {
    gap: 8px;
  }
}

.checkList {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding-left: 32px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.modalList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px 20px;
}

.regularFeatures {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    padding-left: 32px;
    gap: 0.5rem;
  }
}

.subtitle {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  @media screen and (min-width: 768px) {
    gap: 12px;
  }
  & svg {
    fill: $dark-blue-500-secondary-text;
  }
}

.subtitleText {
  color: $dark-blue;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.01rem !important;
}

.featureListContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.featureListProgram {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.programFeatures {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 0.75rem 0.25rem;
  @media screen and (min-width: 768px) {
    padding-left: 32px;
    gap: 0.5rem;
  }
}
