@use '../../../../../sass/mixins';
@use '../../../../../sass/spacing';
@import '../../../../../sass/color-palette';

.helpBanner {
  .claimedContainer {
    @include mixins.padding(24, 24);
  }

  .unclaimedContainer {
    @include mixins.padding(24, 24);
    background-color: #f2fafa;
    border-radius: 8px;
  }

  /* button.button_primary,
  .button_primary {
    // color: white;
    @include spacing.spacing-mb(32);

    svg {
      height: 100%;
      display: inherit;
    }
  } */

  .title {
    @include mixins.margin(16, 0);
    color: #2a4548 !important;
    margin-top: 0;
  }

  ul {
    @include spacing.spacing-mb(24);
  }

  .item {
    display: flex;
    align-items: center;
    color: $dark-blue-700;
    gap: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.12px;
    margin-bottom: 8px;
    & svg {
      fill: #568d93;
    }
  }

  .image {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-image: url('https://static.schola.com/profile/profile-help-banner-star.svg'),
      url('https://static.schola.com/profile/profile-help-banner-serpent.svg');
    background-position: top, bottom;
    background-repeat: no-repeat, no-repeat;
    width: fit-content;
    margin: auto;
  }

  img {
    margin-bottom: 16px;
  }

  .leftStar {
    position: absolute;
    left: 9rem;
    bottom: 7rem;
  }

  .rightStar {
    position: absolute;
    right: 9rem;
    bottom: 0;
  }

  .learn {
    @include spacing.spacing-mt(24);
    display: flex;
    justify-content: center;

    a {
      color: $primary-500 !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      text-decoration: none;
    }

    svg {
      fill: $primary-500;
      margin-right: 10px;
    }
  }
}
.bannerMainColumn {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  border: 1px solid #c6cad1;
  border-radius: 0.5rem;
  padding: 1rem;

  .image {
    align-items: flex-end;
    margin-right: 0;
  }
  .button_primary {
    width: fit-content;
  }
}
