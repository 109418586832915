@use 'themes';
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'spacing';
@import 'typography';
@import 'colors';

// legacy stylus to scss
@import 'legacy/reset';
@import 'legacy/layout';
/* @import 'legacy/carousel'; */
/* @import 'legacy/table';
@import 'legacy/tooltip';
@import 'legacy/translate';
@import 'legacy/tabs'; */

@import '../components/form/form';
@import '../components/icon/icon';
/* @import '../components/navigation-new/Navbar';
@import '../components/navigation-new/navigation'; */
@import '../components/modal/modal';
@import '../components/pages/schools-page/beta/SchoolsPage';
@import '../components/pages/landing-page-new/LandingPage';
@import '../components/pages/landing-page-new/landing';
/* @import '../components/pages/landing-page-new/FeatureList'; */
@import '../components/map/map';
@import '../components/pages/connections/ResponseConnectRequestPage';
@import '../components/pagination/pagination';
@import '../components/pages/schools-page/school-search-filter';
@import '../components/pages/schools-page/search-results';
@import '../components/pages/connections/connections_list';
@import '../components/pages/login-page/login';
@import '../components/pages/schola-match/scholaMatch';
@import '../components/pages/school-application-page/school-application';
/*
@import '../components/pages/schools-page/school-search-results';
@import '../components/slider/slider';
@import '../components/pages/school-profile-page/school-profile';
@import '../components/pages/schools-page/schools-page';
@import '../components/pages/schools-page/group-form';
@import '../components/pages/schools-page/features-form';
@import '../components/pages/schools-page/recommend-school';
@import "../components/pages/parent-portal/ParentPortal";
*/

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 1rem;
  }
}
.schola__bodyfixed {
  position: fixed;
}
.w-100 {
  width: 100% !important;
}

.acsb-trigger {
  z-index: 9999 !important;
}

hr {
  @include themes.theme-aware('border-top', 'border-default');
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top-style: solid;
  border-top-width: 0.0625rem;
}
html.profile-page,
#ContactUnClaimed {
  scroll-behavior: smooth;
}
.cursor-pointer {
  cursor: pointer;
}
.schola-select {
  font-size: 12px;
  color: #c9cdd7 !important;
  padding: 0;
  .schola__value-container {
    padding: 0 8px;
    height: 36px;
    & > div {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      input {
        height: 30px !important;
      }
    }
  }
  .schola__menu {
    color: #484848 !important;
  }
  .schola__control {
    box-shadow: none !important;
    min-height: 33px;
    border-radius: 3.5px;
    border: 0.7px solid #dddfe7;
    .schola__indicator-separator {
      margin-bottom: 12px;
      margin-top: 9px;
    }
    &:hover {
      @include themes.theme-aware('border-color', 'green-legacy');
    }
  }
}
.rc-slider,
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  width: 85% !important;
  margin-top: 7px;
}
.rc-slider-rail,
.rc-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-rail {
  width: 100%;
  background-color: #e9e9e9;
}
.rc-slider-track {
  background-color: #abe2fb;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: 2px solid #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-mark-text-active {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  font-size: 10px;
}
.modal-stage-response {
  .modal-desktop-footer {
    display: none;
  }
}
