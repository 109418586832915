@import '../../../sass/color-palette';

/************************************/
/********* Block  ************/
/************************************/

.school-search-filter {
  vertical-align: top;
  width: 18.1%;
  max-width: 220px;
  display: inline-block;
  box-shadow: 5px 0 5px -2px $gray-100;
  background: #fafafa;
  z-index: 2;
}

.school-search-filter__map {
  position: absolute;
  margin: 12px;
  max-height: 90%;
  overflow: hidden;

  .school-search-filter__heading {
    border-bottom: 1px solid $gray-100;
    margin-top: 4px;
    padding: 4px 0;
    font-size: 14px;

    .material-icons {
      margin-left: 4px;
      cursor: pointer;
      transition: 0.3s transform ease-in-out;
    }

    .icon-arrow-up {
      transform: rotate(180deg);
    }
  }

  .school-search-filters {
    max-height: 400px;
    overflow: auto;
    transition: 0.3s max-height ease-in-out;

    &.school-search-filters__collapsed {
      max-height: 0;
    }
  }

  .school-search-filter__type,
  .school-search-filter__categories,
  .school-search-filter__distance {
    margin-top: 12px;
  }
}

/************************************/
/********* Element  ************/
/************************************/
.school-search-filter__heading {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.school-search-filter__type,
.school-search-filter__categories,
.school-search-filter__distance {
  margin-top: 32px;
  padding: 8px 8px 0;
}

.school-search-filter__type span.school-search-filter__type--title,
.school-search-filter__categories span.school-search-filter__categories--title,
.school-search-filter__distance span.school-search-filter__distance--title {
  font-weight: $medium;
}

.school-search-filter__type span.checkbox-label,
.school-search-filter__categories span.checkbox-label {
  font-weight: $bold;
}

.school-search-filter__type--list,
.school-search-filter__categories--list,
.school-search-filter__distance--list {
  margin-top: 24px;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  max-height: 60em;
}

.school-search-filter__map .school-search-filter__categories--list {
  overflow-y: visible;
}

.school-search-filter .checkbox {
  margin-left: 8px;
}

.school-search-filter .subfeature {
  margin-left: 22px;
  // width:calc(100% - 22px);

  .checkbox-label {
    font-size: 10px;
    color: $schola-gray;
    opacity: 0.64;
  }
}

.sub-feature--holder {
  display: flex;
  justify-content: space-between;

  .edit--holder {
    cursor: pointer;
    .icon {
      margin-top: 10px;
    }
  }
}

/************************************/
/********* Modifiers  ************/
/************************************/
.school-search-filter__heading span:nth-child(1) {
  display: inline-block;
  color: $primary;
}

.school-search-filter__heading span:nth-child(2) {
  vertical-align: text-top;
  font-weight: 600;
  margin-left: 8px;
  color: #4a4a4a;
}

.modal--weight {
  text-align: center;
  max-width: 30%;
  margin: 0 auto;
  .modal--weight__header {
    color: $primary;
    font-weight: $bold;
  }
  .slider--header {
    width: 50%;
    margin: 50px auto;

    .slider--header_left {
      float: left;
    }
    .slider--header_right {
      float: right;
    }
  }
  .rc-slider {
    max-width: 50%;
    margin: 0 auto;
    padding-top: 20px;
  }
  .button {
    margin-top: 40px;
  }
}

.school-search-filter__mobile {
  width: 100%;
  height: 100%;
  display: block;
  &.school-search-filter__mobile--collapsed {
    display: none;
  }
  &.school-search-filter__mobile__expanded {
    .material-icons {
      margin-left: 4px;
      cursor: pointer;
      float: right;
      transition: 0.3s transform ease-in-out;
    }
    .icon-arrow-up {
      transform: rotate(180deg);
    }
  }
  .school-search-filter {
    width: 100%;
    max-width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 4px 0 rgba(0, 154, 211, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    .school-search-filter__sub-filter {
      font-size: 16px;
      border-bottom: 1px solid #cfd8dc;
      padding: 16px;
      margin-top: 10px;
    }
    .school-search-filter__sub-filter--collapsed {
      display: none;
    }
    .school-search-filter__sub-filter--expanded {
      height: 100%;
    }
    &.school-search-filter__map {
      margin: 0;
    }
  }
  .school-search-filter__distance--list button {
    width: 100%;
    border-radius: 4px;
    height: 32px;
    margin: 5px 0;
    &.button--color--light {
      border: 1px solid #b0bec5;
      opacity: 0.64;
    }
  }
}
