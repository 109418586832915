@import '../../sass/color-palette';

.boxContainer {
  border-radius: 0.5rem;
  background: #f3edf6;
  padding: 1rem;
  color: #412352;
  position: relative;
  overflow: hidden;
  order: 4;
  &_title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    .titleContainer {
      position: relative;
      &h5,
      .bannerTitle {
        color: #412352 !important;
        font-family: Montserrat !important;
        font-size: 1.125rem !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 1.625rem !important;
      }
      & > svg {
        position: absolute;
        left: 100%;
        top: -0.5rem;
      }
    }
  }

  &p,
  .bannerDescription {
    color: $dark-blue !important;
    font-size: 0.75rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    letter-spacing: -0.0075rem !important;
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
  }
}

.bubbleShape {
  position: absolute;
  bottom: 0;
  right: 0;
}

.item {
  color: $dark-blue;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: -0.0075rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  & svg {
    fill: #5d3276;
  }
}
