@mixin layout-width {
  max-width: 1226px;
  margin: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@mixin light-gray {
  background-color: #f8f8f8;
}
@mixin columns {
  width: 100%;
  display: grid;
  gap: 2rem;
}

.layout {
  padding-top: 3rem;
  @include light-gray();
}
.layout_overview {
  @extend .layout;
  background-color: #ffffff;
}
.header {
  line-height: 36px;
  font-size: 20px;
  padding-bottom: 40px;
  background: #f4faec
    url(https://scholamatch-static.s3.us-west-2.amazonaws.com/scholamatch/schola-background-header-smv6.png) no-repeat
    center top;
  background-size: cover;
  border-radius: 0 0 50px 50px;
  @media (min-width: 767px) {
    border-radius: 0;
  }
  overflow: hidden;
  &_container {
    @include layout-width;
    padding-top: 20px;
    margin: auto;
  }
  .logo {
    margin-bottom: 47px;
  }
}
.container {
  @include layout-width;
  margin-top: 21px;
  line-height: 30px;
  font-size: 16px;

  p {
    margin-bottom: 1rem;
  }
  margin-bottom: 2rem;
  ul {
    padding-left: 2rem;
    li {
      list-style-type: disc;
      b {
        font-weight: bold;
      }
    }
  }
}
.columns_graph_superpowers {
  @include columns;
  grid-template-columns: 1fr;
  align-items: center;
  @media screen and (min-width: 769px) {
    grid-template-columns: 535px 1fr;
  }
}

.content_figure {
  .scout_figure {
    display: none;
    @media (min-width: 768px) {
      display: inline;
    }
  }
  display: grid;
  grid-template-columns: 100%;
  gap: 27px;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    grid-template-columns: 25% 70%;
  }
}
.chip_container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 0.5rem;
  @media (min-width: 767px) {
    flex-direction: row;
    gap: 1rem;
  }
}
.chip {
  background-color: #e7eff2;
  color: #006b8f;
  border: none;
  padding: 0 14px;
}
.filters_cards_container {
  @include layout-width;
  display: block;
  grid-template-columns: 1fr;
  @media (min-width: 767px) {
    display: grid;
    grid-template-columns: 0.3fr 0.7fr;
    // gap: 1rem;
  }
}
.column_filters {
  width: 100%;
  border: 1px solid #e7eff2;
  border-radius: 0.5rem;
  padding: 1rem;

  position: fixed;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 10000;

  overflow-y: auto;
  height: 100vh;

  transition: left 0.4s;

  @media (min-width: 767px) {
    position: initial;
    z-index: 0;
    height: max-content;
  }
}

.list {
  @media (min-width: 767px) {
    padding-left: 1.5rem;
  }
}

.listHeader {
  margin-bottom: 1rem;
  .listHeaderButton {
    display: none;
  }
  @media (min-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .listHeaderButton {
      display: block;
    }
  }
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  & .label {
    width: 100px;
  }
}
