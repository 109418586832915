@import '../../../../../sass/color-palette';

.logo {
  grid-area: logo;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid $secondary-100;
  display: grid;
  place-items: center;
  overflow: hidden;
  object-fit: contain;
}

.name {
  grid-area: name;
  align-self: center;
}
.schoolName {
  font-family: 'Open Sans', sans-serif !important;
  display: inline;
  font-size: 20px !important;
  line-height: 20px !important;
  @media screen and (min-width: 768px) {
    line-height: 32px !important;
    font-size: 1.75rem !important;
  }
}

.claimed {
  vertical-align: middle;
  margin-left: 0.5rem;
  margin-bottom: 0.3rem;
}
.name_check_claimed {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
