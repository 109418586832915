@import '../../../../../sass/color-palette';

.schoolActions {
  margin: 1rem 0;
  height: auto;

  .container {
    border: 1px solid #c6cad1;
    border-radius: 8px;
    padding: 24px 16px;
  }

  .unclaimedContainer {
    @extend .container;
  }

  .unclaimedText {
    color: $dark-blue-500;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .title {
    color: $dark-blue-700;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
}

.list {
  border: 1px solid #c6cad1;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;

  li:last-child {
    border-bottom: none;
  }
}
