@use '../../../sass/themes';
@use '../../../sass/mixins';

$checkbox-padding: .75rem;

.checkbox {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: -($checkbox-padding);
}

.feature--info {
    display: inline-flex;

    .school-feature__question--icon {
        margin-left: .625rem;

        .icon {
            max-width: 1.25rem;
            max-height: 1.063rem;
        }
    }
}

.checkbox--small .checkbox-icon {
    width: 1.75rem;
    height: 1.75rem;
    padding: .375rem;
}

.checkbox--small .checkbox-label {
    font-size: .75rem;
}

.checkbox-icon {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 1.5rem + ($checkbox-padding * 2);
    height: 1.5rem + ($checkbox-padding * 2);
    border-radius: 100%;
    padding: $checkbox-padding;
    flex-shrink: 0;
}

.checkbox-icon:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    @include themes.theme-aware('background-color', 'icon-primary'); 
    transform: scale(0, 0);
    opacity: 0.35;
    transition-property: transform, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0;
}

.checkbox--checked .checkbox-icon:after {
    transform: scale(1, 1);
    opacity: 0;
    transition-duration: 500ms;
}

.checkbox-label {
    font-weight: 500;
    font-size: 1.125rem;
}

.checkbox-icon+.checkbox-label {
    padding-top: $checkbox-padding;
    padding-right: $checkbox-padding;
    padding-bottom: $checkbox-padding;
}

/* Hide HTML input */
.checkbox input[type="checkbox"] {
    display: none;
}

// Disabled cursor on parent label and all children elements
.checkbox--disabled,
.checkbox--disabled * {
    cursor: not-allowed !important;
}

.checkbox--disabled .checkbox-icon {
    opacity: 0.75;
}

.checkbox--disabled .checkbox-label {
    opacity: 0.5;
}
