@import '../../../../sass/color-palette';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border-bottom: 1px solid $dark-blue-200;
  @media screen and (min-width: 768px) {
    margin-bottom: 3rem;
    padding-bottom: 49px;
  }
  .features_heading {
    margin-bottom: 1.5rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 32px;
    }
  }

  /* .feature_description {
    margin-bottom: 16px;
  } */
}
