.skeletonLoader {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: -100%;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -100%;
  }

  to {
    left: 100%;
  }
}