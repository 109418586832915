.distance {
  border: 1px solid #C6CAD1;
  padding-left: .5rem;
  width: 120px;
  height: 34px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupButtons {
  width: 1.5rem;
  border-left: 1px solid #C6CAD1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  & svg {
    fill: #818A99;
  }
}
