.modalLanguage {
  z-index: 10000;

  .buttonsGroup {
    display: grid;
    gap: .5rem;

    @media screen and (min-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}