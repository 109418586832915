@mixin layout-width {
  max-width: 1226px;
  margin: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header {
  line-height: 36px;
  font-size: 20px;
  padding-bottom: 6rem;
  background: #f4faec
    url(https://scholamatch-static.s3.us-west-2.amazonaws.com/scholamatch/schola-background-header-smv6.png) no-repeat
    center top;
  background-size: cover;
  border-bottom: 1px solid #006b8f;
  position: relative;

  @media (min-width: 767px) {
    border-radius: 0;
  }

  &_container {
    @include layout-width;
    padding-top: 20px;
    margin: auto;
  }
  .logo {
    margin-bottom: 47px;
  }
}

.personality {
  text-transform: capitalize;
}

.tabContainer {
  @media (min-width: 500px) {
    max-width: 1226px;
    margin: auto;
    padding-left: 1.5rem;
  }
}

.tabs {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  bottom: -1px;
  text-align: center;
  @media (max-width: 480px) {
    left: 1.2rem;
    width: 90%;
  }
}

.tab {
  padding: 0.5rem 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5rem;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 360px) {
    width: 100%;
  }

  @media (min-width: 700px) {
    font-size: 20px;
    line-height: inherit;
    width: fit-content;
  }
}

.selected {
  border: 1px solid #006b8f;
  border-bottom: 1px solid #f7f7f7;
  color: #006b8f;
  background: #ffffff;
}
.results_tab {
  background: #f8f8f8;
}
.default {
  border: 1px solid #006b8f;
  color: #fff !important;
  background: #006b8f !important;
}
