.background {
  background: rgba(33, 53, 86, 0.55);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: grid;
  place-items: center;
}

.modal {
  background: #fff;
  border-radius: 0.5rem;
  min-width: 95%;
  margin-left: 1rem;
  margin-right: 1rem;

  @media screen and (min-width: 767px) {
    min-width: 30rem;
    max-width: 35rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.header {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 0;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem; */
  .closeContainer {
    padding-right: 1.5rem;
  }
  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.close {
  padding: 0.5rem;
  border-radius: 50%;
}

.body {
  padding: 1rem;
  max-height: 70vh;
  border-top: 0.167px solid#A3AAB5;
  overflow-y: auto;
}

.nortonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
}
.nortonContainer_flexend {
  @extend .nortonContainer;
  justify-content: flex-end;
}
.norton {
  width: 120px;
  height: 75px;
}

.footer {
  padding: 1.5rem;
  border-top: 0.167px solid#A3AAB5;
}
