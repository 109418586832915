@import '../../../../../sass/color-palette';

.banner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #e6f5fa;
  border-radius: 8px;
  padding: 12px 8px;
  margin-top: 20px;

  .image {
    max-height: 116px;
  }

  .cta {
    width: 60%;
    display: flex;
    flex-direction: column;

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.14px;
      color: $dark-blue-500;
    }
  }
  .bold {
    font-weight: 700;
    margin-right: 2px;
  }

  button {
    background-color: #e6f5fa;
    margin-top: 12px;

    &:hover {
      background-color: #e7eff2;
    }

    span {
      font-weight: 600;
    }
  }
}
