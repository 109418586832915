.chip {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #f3f5f7;
  padding: 10px;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  overflow: hidden; // Gap between icon and info
}

.icon {
  background: #e6f5fa;
  max-height: 48px;
  min-height: 48px;
  min-width: 48px;
  border-radius: 8px;
  display: grid;
  place-items: center;

  & svg {
    color: #006b8f;
  }
}

.info {
  color: #5f6a7d;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: 500;
  color: #1a2a45;
  line-height: 1.3;
}

.value {
  line-height: 1.5rem;
  text-transform: capitalize;
}
