.wrapper {
  display: flex;
  align-items: center;
  height: 24px;
}
.label {
  display: flex;
  font-size: 14px;
  align-items: center;
  gap: 0.25rem;
  color: #fff;
  font-weight: 500;
  padding-left: 0.3rem;
  width: fit-content;
  height: 100%;
  position: relative;
  font-weight: bold;

  @media screen and (min-width: 767px) {
    padding-right: 1rem;
  }

  @media screen and (min-width: 767px) {
    width: fit-content;
    gap: 0.5rem;
  }
  p {
    text-align: left;
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
}
.ornament {
  height: 100%;
  width: 24px;

  position: relative;

  &::after {
    content: '';
    width: 24px;
    height: 100%;
    background: white;
    position: absolute;
    right: -9px;
    transform: rotate(45deg);
    border-top-right-radius: 20px;
  }
}

.good {
  background: #02b674;
}

.normal {
  background: #02b674;
}

.partner {
  background: #d48806;
}
