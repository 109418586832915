.table_title_gray {
  background-color: #f3f5f7;
  width: 100%;
  padding: 16px;
  border-radius: 8px 8px 0 0;
}
.table_title_gray {
  background-color: #f3f5f7;
  width: 100%;
  padding: 16px;
  border-radius: 8px 8px 0 0;
}
.table_row {
  display: grid;
  border: 1px solid #0000000f;
  font-size: 14px;
  & > div {
    padding: 0 16px;
    @media screen and (min-width: 768px) {
      padding: 16px;
    }
  }
  &_title {
    font-weight: bold;
  }
}
.table_two_col {
  @extend .table_row;
  grid-template-columns: 120px 1fr;
}
.table_three_col {
  @extend .table_row;
  grid-template-columns: 1fr;
  @media screen and (min-width: 769px) {
    grid-template-columns: 300px 250px 1fr;
  }
}
