@use '../../../sass/themes';
@use '../../../sass/colors/scheme-colors';
@use '../../../sass/mixins';

.layoutv2 {
  .column-left-hero {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: 768px) {
      padding: 2rem;
    }
    @media (min-width: 1440px) {
      padding: 5rem;
    }
    @media (min-width: 1800px) {
      padding-top: 5rem 2.5rem 5rem 20rem;
    }
  }
  .modal__children {
    padding-top: 0;
  }
  .container {
    padding: 0 1em !important;
    display: block !important;
  }
  &.landing-home {
    .geosuggest__suggests--hidden {
      display: inline;
    }
  }
  .find-school-made {
    margin-bottom: 84px;
    .card {
      height: 100%;
      img {
        width: 100%;
      }
      .card-body {
        height: 105px;
        h6 {
          font-size: 14px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          height: 145px;
        }
      }
    }
    @media screen and (min-width: 768px) {
      height: 320px;
    }
  }
  .card-body {
    .address-featured-school {
      margin-bottom: 26px;
    }
    h6 {
      margin-bottom: 8px;
      font-weight: 600;
      color: #000;
      span {
        text-transform: capitalize;
      }
    }
    .grades {
      margin-bottom: 8px;
    }
  }
  .slick-dots {
    li {
      &.slick-active {
        button {
          &::before {
            color: scheme-colors.$neutral-400;
          }
        }
      }
    }
    .search-button {
      right: 0;
      bottom: 0;
      font-size: 12px;
      width: 70px;
      height: 38px;
      margin: 0;
      padding: 0;
      top: 30px !important;
      position: absolute;
    }
    .radio-button-login-modal {
      position: relative;
    }
    .radio-button-login-modal-text {
      background: #f7f7f7;
      border-radius: 3.5px;
      margin-bottom: 2em !important;
      margin-top: 3em !important;
      padding-right: 2em !important;
      padding-left: 2em !important;
      padding-bottom: 1em !important;
      &:focus {
        outline: none;
      }
    }
    .text-option-below {
      margin-left: 2.4em;
      margin-top: -1em;
    }
    .modal-title-text {
      font-style: normal;
      font-weight: 500;
      font-size: 17.5px;
      letter-spacing: -0.34px;
      color: #858585;
      text-align: right;
    }
    .login-modal {
      top: 0 !important;
      .modal__children {
        padding: 0 !important;
      }
      .modal__close {
        padding-top: 5px;
      }
      .modal__overlay {
        background: rgba(255, 255, 255, 0.7) !important;
      }
      .modal__content {
        overflow-y: hidden !important;
      }
    }
    @media screen and (max-width: 991px) {
      #navbarSupportedContent {
        display: none !important;
      }
    }
    @media screen and (min-width: 991px) {
      #navbarSupportedContentMobile {
        display: none !important;
      }
    }
    @media screen and (max-width: 768px) {
      .menu-icons .nav-link {
        color: $primary !important;
      }
    }
    @media screen and (min-width: 769px) {
      .menu-icons .nav-link {
        color: $primary !important;
      }
    }
    @media screen and (max-width: 768px) {
      .navbar .nav-item {
        a {
          font-size: 1rem;
          margin: 0.5rem 0;
          text-align: left;
        }
      }
    }
  }
  .search-button {
    right: 0;
    bottom: 0;
    font-size: 12px;
    width: 70px;
    height: 38px;
    margin: 0;
    padding: 0;
    top: 30px !important;
    position: absolute;
  }
  .radio-button-login-modal {
    position: relative;
  }
  .radio-button-login-modal-text {
    background: #f7f7f7;
    border-radius: 3.5px;
    margin-bottom: 2em !important;
    margin-top: 3em !important;
    padding-right: 2em !important;
    padding-left: 2em !important;
    padding-bottom: 1em !important;
    &:focus {
      outline: none;
    }
  }
  .text-option-below {
    margin-left: 2.4em;
    margin-top: -1em;
  }
  .modal-title-text {
    font-style: normal;
    font-weight: 500;
    font-size: 17.5px;
    letter-spacing: -0.34px;
    color: #858585;
    text-align: right;
  }
  .login-modal {
    top: 0 !important;
    .modal__children {
      padding: 0 !important;
    }
    .modal__close {
      padding-top: 5px;
    }
    .modal__overlay {
      background: rgba(255, 255, 255, 0.7) !important;
    }
    .modal__content {
      overflow-y: hidden !important;
    }
  }
  @media screen and (max-width: 991px) {
    #navbarSupportedContent {
      display: none !important;
    }
  }
  @media screen and (min-width: 991px) {
    #navbarSupportedContentMobile {
      display: none !important;
    }
  }
  @media screen and (max-width: 768px) {
    .menu-icons .nav-link {
      color: scheme-colors.$secondary-500 !important;
    }
  }
  @media screen and (min-width: 769px) {
    .menu-icons .board {
      color: scheme-colors.$secondary-500 !important;
    }
  }
  @media screen and (max-width: 768px) {
    .navbar .nav-item {
      a {
        font-size: 1rem;
        margin: 0.5rem 0;
        text-align: left;
      }
    }
  }
  .hero {
    @media (min-width: 1440px) {
      margin-bottom: -10px;
    }

    .hero-img {
      @media (min-width: 1440px) {
        height: 685px;
      }
    }
    h1 {
      @media (max-width: 768px) {
        color: white !important;
      }
    }
    p {
      text-align: left;
    }
    h3 {
      font-style: normal;
      font-size: 40px;
      line-height: 110%;
    }
    .text-hero {
      font-size: 16px;
      margin-top: 24px;
      text-align: center;
      margin-bottom: 36px;
      color: #fff;
      line-height: 150%;
      @media screen and (min-width: 769px) {
        text-align: left;
        margin-bottom: 60px;
      }
    }
    .geosuggest__input {
      height: 46px;
      border-radius: 5px;
      border: 1px solid #ebeff4;
      font-size: 14px;
      font-weight: 500;
      padding: 10.5px 20px;
      padding-left: 50px;
      width: 100%;
      background: url('https://static.schola.com/landing/search.png') no-repeat center left;
    }
    .text-hero,
    .input-geo,
    .or-content,
    .button-launch-schola-match-content {
      @media (max-width: 768px) {
        padding-right: 2rem;
        padding-left: 2rem;
      }
      @media (max-width: 480px) {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
      }
    }

    .or-content {
      margin: 16px;
      @media (max-width: 768px) {
        p,
        p.font-subtitle1-scholagray-regular {
          color: #fff !important;
        }
      }
    }
    .school__popup {
      margin: 0 auto;
      z-index: 9999;
      @media (max-width: 576px) {
        width: 100%;
        top: 10%;
      }
      .modal__header {
        @media (max-width: 992px) {
          height: 64px;
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        width: 40%;
        height: 500px;
        position: absolute;
        top: 10%;
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        width: 35%;
        position: absolute;
        top: 0;
      }
      @media (min-width: 1201px) {
        width: 30%;
        position: absolute;
        top: 0;
      }
      .cta {
        @media (max-width: 576px) {
          width: 60%;
          margin-top: 40px;
        }
        @media (min-width: 577px) and (max-width: 768px) {
          width: 80%;
          margin-top: 40px;
        }
        @media (min-width: 769px) and (max-width: 992px) {
          width: 80%;
          margin-top: 40px;
        }
        a {
          border-radius: 0;
          font-size: 11px;
        }
        .close {
          background-color: transparent;
          border: 1px solid #008000;
          width: 192px;
          margin-top: 5px;
          margin-top: 10px;
        }
        .takeQuiz {
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 25px;
        }
        .theme-button {
          width: 100%;
          display: block;
        }
        .scholaText {
          font-size: 12px;
          font-weight: 500;
          margin-top: 15px;
          img {
            vertical-align: middle;
          }
        }
      }
      .hero-text-and-search-input {
        @media (max-width: 768px) {
          margin-top: 40px;
        }
      }
    }
    #background-hero {
      @media (max-width: 768px) {
        background: url(https://static.schola.com/landing/schola-landing-page.jpg) no-repeat;
        background-size: cover;
        background-position: top;
        align-items: center;
        padding: 1.5rem;
        padding-top: 5.4rem;
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.35);
      }
      @media (min-width: 480) and (max-width: 768px) {
        padding: 3rem;
      }
      h1 {
        @media (max-width: 768px) {
          text-align: center;
        }
      }
      .button-find {
        height: 46px;
        @media (max-width: 480px) {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
    .hero-text-and-search-input {
      @media (max-width: 768px) {
        margin-top: 40px;
      }
    }
  }
  .for-families-img {
    @media (min-width: 769px) {
      overflow: hidden;
    }
  }
  .featurelist-families,
  .featurelist-schools {
    @media (min-width: 769px) {
      border-radius: 10px;
      box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.08);
      height: 384px;
    }
  }
  .for-container {
    margin-bottom: 84px;
  }
  .for-families,
  .for-schools {
    @media (min-width: 769px) {
      margin-top: -1.3rem;
    }
    @media screen and (max-width: 768px) {
      .heart-green {
        background-color: #ffffff;
      }
    }
  }
  @media screen and (min-width: 480px) {
    .button-sign-up-for-families,
    .button-claim-your-school {
      font-weight: 600 !important;
      font-size: 18px !important;
      line-height: 22px !important;
    }
    .for-families a.btn-block,
    .for-schools a.btn-block {
      display: inline !important;
      width: auto !important;
    }
  }
  @media screen and (max-width: 480px) {
    div.for-families-img::before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      content: '';
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }
    .happiness-container {
      @include themes.theme-aware('background-color', 'bg-primary-disabled');
      padding: 40px 10px;
      @media (min-width: 768px) {
        padding: 84px 0;
      }
      .title-happiness {
        margin-bottom: 24px;
      }
      .happiness-text {
        width: 100%;
      }
      .happiness-text-p {
        font-weight: 500;
        font-size: 16px;
      }
      .margin-bottom26 {
        margin-bottom: 26px !important;
        margin-top: 36px !important;
      }
      .banner-improve {
        background-image: url('https://static.schola.com/schools/What+Is+Schola.png');
        background-repeat: no-repeat;
        background-position-x: left;
        background-position-y: center;
        background-size: cover;
        color: #fff;
        margin-top: 99px;
        display: flex;
        flex-direction: column;
        height: 626px;
        justify-content: flex-end;
        .play-icon {
          display: flex;
          justify-content: center;
          img {
            width: 90px;
            height: auto;
            cursor: pointer;
          }
        }
        .text-welcome-schola {
          padding: 4rem 2rem 2rem;
          min-height: 298px;
          @media (min-width: 768px) {
            width: 60%;
            height: 50%;
          }
          h3 {
            margin-top: 36px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .button-green {
    @include mixins.button-tertiary($isModule: false);
    padding: 12px 25px;
    height: 45px;
    font-size: 14px;
    font-weight: bold;
  }
  button {
    &.disable {
      &.btn-success {
        @include themes.theme-aware('background-color', 'bg-subtle');
        background-color: scheme-colors.$secondary-500;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .lineheight1 {
    line-height: 45px;
  }
  .lineheight2 {
    line-height: 55px;
  }
  .or-line {
    height: 1px;
    width: 250px;
    padding: 0 5px;
    margin-top: 0.7em;
  }
  .happiness-container {
    @include themes.theme-aware('background-color', 'bg-primary-disabled');
    padding: 40px 10px;
    @media (min-width: 768px) {
      padding: 84px 0;
    }
    .title-happiness {
      margin-bottom: 24px;
    }
    .happiness-text {
      width: 100%;
    }
    .happiness-text-p {
      font-weight: 500;
      font-size: 16px;
    }
    .margin-bottom26 {
      margin-bottom: 26px !important;
      margin-top: 36px !important;
    }
    .banner-improve {
      background-image: url('https://static.schola.com/schools/What+Is+Schola.png');
      background-repeat: no-repeat;
      background-position-x: left;
      background-position-y: center;
      background-size: cover;
      color: #fff;
      margin-top: 99px;
      display: flex;
      flex-direction: column;
      height: 626px;
      justify-content: flex-end;
      .play-icon {
        display: flex;
        justify-content: center;
        img {
          width: 90px;
          height: auto;
          cursor: pointer;
        }
      }
      .text-welcome-schola {
        padding: 4rem 2rem 2rem;
        min-height: 298px;
        @media (min-width: 768px) {
          width: 60%;
          height: 50%;
        }
        h3 {
          margin-top: 36px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .feature-schools {
    h5 {
      font-size: 16px;
    }
    p {
      &.text-green {
        text-transform: uppercase;
        font-size: 11px;
      }
    }
    a {
      text-decoration: none;
    }
  }
  .bulletin-grid {
    .bulletinCard {
      .name {
        text-transform: capitalize;
        font-weight: 500;
      }
      .card-image {
        img {
          height: 150px;
          object-fit: cover;
          object-position: center center;
          width: 100%;
        }
      }
    }
  }
  .bulletin-board {
    padding-top: 84px;
    padding-bottom: 84px;
    @include themes.theme-aware('background-color', 'bg-default');
    .card-bulletin {
      border-radius: 3.5px;
      overflow: hidden;
    }
    h5 {
    }
    .title-bulletin-board {
      margin-bottom: 16px;
    }
    .subtile-bulletin-board {
      margin-bottom: 36px;
    }
    .button-load-more {
      font-size: 16px;
      font-family: 'Montserrar', sans-serif;
      font-weight: 600;
      border: none;
    }
    .bulletin-item {
      margin-bottom: 1.7rem;
    }
    .loading {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .find-feature-schools {
    margin-bottom: 84px;
    h3 {
      text-align: center;
      padding: 36px 0;
    }
    .card {
      p {
        font-size: 14px;
      }
    }
    .card-column {
      padding-left: 13.5px;
      padding-right: 13.5px;
    }
  }
  .title-find-and-feature-school {
    h3 {
      text-align: center !important;
      padding: 36px 0;
    }
  }
  .title-for-families-mobile {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 25%;
  }
  .transportation {
    .transportation-text {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        text-align: center;
        margin-top: 36px;
      }
      p {
        font-weight: 500;
        margin-bottom: 36px;
        @media (min-width: 768px) {
          margin-bottom: 60px;
        }
      }
      input.input-transportation {
        width: auto;
        height: 46px;
        border-radius: 5px;
        border: 1px solid #ebeff4;
        font-size: 14px;
      }
      .button-get-notified {
        margin-left: 8px;
      }
    }
  }
  .input-suscription {
    width: 100%;
    display: flex;
    input {
      padding-top: 11px;
      padding-bottom: 11px;
    }
    .btn {
      display: block;
      width: 100%;
      margin-top: 16px;
    }
  }
  footer {
    .footer-menu {
      ul {
        li {
          font-weight: 700;
          margin-left: 2rem;
        }
      }
    }
  }
  .footer-menu {
    ul {
      margin: auto;
      li {
        line-height: 1.8rem;
        margin-left: 0 !important;
        line-height: 1.8rem;
        margin-left: 0 !important;
      }
    }
  }
  .geosuggest {
    max-width: 100%;
    top: 0px;
    flex: 1;
  }
  .vigneta {
    width: 25px;
    height: 25px;
  }
  .text-title-vigneta {
    margin-bottom: 6px;
  }
  .text-vigneta {
    margin-bottom: 36px;
  }
  .featured-schools2 {
    background: #fff;
    .thumb {
      height: 150px;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
    .schools-grid {
      max-width: 567px;
      height: 338px;
      padding: 0 26px !important;
      overflow-y: hidden;
      overflow-x: hidden;
      .school-item {
        width: 525px;
        margin: 0 10px;
        width: 260px;
        max-width: initial;
        width: 490px;
        max-width: initial;
        width: 325px;
        width: 260px;
        margin: 0 25px !important;
        width: 320px;
        a {
          text-decoration: none;
          display: block;
          color: #000;
        }
        .card {
          height: 330px;
          margin-top: 10px;
        }
        .heading {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          text-transform: uppercase;
          height: 38px;
          line-height: 40px;
          border-radius: 3.5px 3.5px 0 0;
          background-color: scheme-colors.$secondary-500;
        }
        .thumb {
          height: 150px;
          object-fit: cover;
          object-position: center center;
          width: 100%;
          .name {
            left: 0;
            right: 0;
            bottom: 0;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            padding: 17px 10px;
            display: block;
            position: absolute;
            background: -webkit-gradient(linear, left top, left bottom, from(rgba(29, 29, 29, 0)), to(#1d1d1d));
            background: linear-gradient(180deg, rgba(29, 29, 29, 0) 0%, #1d1d1d 100%);
          }
        }
        .info {
          padding: 26px;
          border-radius: 0 0 3.5px 3.5px;
          background-color: #fff;
          p {
            font-size: 13px;
            font-weight: 300;
            margin-bottom: 20px;
            padding-left: 35px;
            position: relative;
            &:before {
              content: '';
              left: 0;
              position: absolute;
              background-size: 100%;
              background-position: center;
              background-repeat: no-repeat;
            }
            &.location {
              &:before {
                width: 11px;
                height: 16px;
              }
            }
            &.hat {
              text-transform: capitalize;
              &:before {
                width: 16px;
                height: 14px;
              }
            }
            small {
              font-size: 10px;
              font-weight: 500;
              text-transform: uppercase;
              margin-top: 6px;
              display: block;
            }
          }
        }
      }
      &.slick-slider {
        .slick-slide {
          img {
            display: inline-block;
          }
        }
        button {
          top: 0;
          bottom: 0;
          width: 30px;
          height: 30px;
          text-indent: -999px;
          border: 0;
          padding: 0;
          margin: auto;
          cursor: pointer;
          overflow-x: hidden;
          position: absolute;
          background-repeat: no-repeat;
          background-position: center;
          background-color: transparent;
          z-index: 1;
          &.slick-prev {
            left: 0;
            background-image: url('https://static.schola.com/carousel-arrow-left.svg');
            left: -15px;
            left: -10px;
          }
          &.slick-next {
            right: 0;
            background-image: url('https://static.schola.com/carousel-arrow-right.svg');
            right: -15px;
            right: -10px;
          }
        }
      }
    }
  }
}

.geosuggest__suggests--hidden {
  display: none;
}
.geosuggest__input-wrapper,
.geosuggest__input {
  width: 100%;
  outline: none;
}
.geosuggest__suggests-wrapper {
  background-color: #fff;
  box-shadow: none !important;
  margin-bottom: 1rem;
  ul {
    text-align: left;
  }
}
.geosuggest__item {
  padding: 16px;
  line-height: 1.25;
  font-size: 0.8rem;
  &:hover {
    background: scheme-colors.$secondary-500;
    color: white;
  }
  &:focus {
    background: #f8f9fb;
  }
}
.geosuggest__item-separator {
  padding: 8px 12px;
  line-height: 1.25;
  cursor: context-menu;
  background: #f1f4f7;
  border-bottom: 1px solid #ebf2f7;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
.container {
  &.blog-posts {
    padding: 2rem 26px !important;
    .excerpt {
      p {
        margin: 1rem 0;
      }
      .link-more {
        display: none;
      }
    }
    .featured-image {
      height: 203px;
      width: 100%;
      img {
        height: 203px;
        width: 100%;
      }
    }
  }
}
.bulletin-board {
  padding-top: 0;
  background-color: #f4f4fa;
  .bulletin-grid {
    .sgs2 {
      float: none;
      margin-bottom: 40px;
      border-radius: 3.5px;
      &:last-child {
        margin-bottom: 0;
      }
      .bulletinCard {
        &.sgs3 {
          margin: 0;
          max-width: initial;
          .card-desc--bulletin {
            .info {
              height: 112px;
              margin: 0;
              padding: 30px;
              .name {
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;
                height: initial;
                margin-bottom: 15px;
                a {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
    .bulletin-item {
      .thumb {
        .heading {
          padding: 10px 10px 0 15px;
          border-radius: 3.5px 3.5px 0 0;
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(rgba(27, 27, 27, 0.74)),
            to(rgba(41, 41, 41, 0))
          );
          background: linear-gradient(180deg, rgba(27, 27, 27, 0.74) 0%, rgba(41, 41, 41, 0) 100%);
          .time-ago {
            color: #fff;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0.6px;
            text-transform: uppercase;
          }
          .menu-button {
            width: 20px;
            height: 30px;
            padding: 0;
            border: 0;
            float: right;
            cursor: pointer;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .play-button {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 35px;
          height: 35px;
          margin: auto;
          border: none;
          position: absolute;
          background-size: cover;
          background-position: center;
          background-color: transparent;
        }
      }
      .info {
        text-align: center;
        padding: 30px;
        background-color: #fff;
        border-radius: 0 0 3.5px 3.5px;
        .name {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .uploaded-legend {
          font-size: 13px;
          font-weight: 300;
        }
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
