@use '../../../sass/mixins';
@use '../../../sass/themes';

.text {
    display: block;
    width: 100%;
}

.text-label {
    @include mixins.toRem('font-size', 14);
    @include mixins.toRem('margin-bottom', 8);
    display: block;
    font-weight: 500;
    width: 100%;
}

.text-input,
.text-area {
    @include mixins.padding(12, 16);
    @include mixins.toRem('border-width', 2);
    @include mixins.toRem('line-height', 20);
    @include themes.theme-aware('border-color', 'border-default');
    appearance: none;
    border-radius: 0;
    border-style: solid;       
    color: inherit;
    display: block;
    font-size: 1rem;
    width: 100%;
}

.text-area {
    @include mixins.toRem('min-height', 200);
    resize: vertical;
}
textarea {
    @extend .text-area;
    @include mixins.toRem('padding-top', 12);
}

// Disabled cursor on parent label and all children elements
.text--disabled,
.text--disabled * {
    cursor: not-allowed !important;
    opacity: 0.75;
}

.text--disabled .text-input,
.text--disabled .text-area {
    @include themes.theme-aware('background-color', 'bg-primary-disabled');    
}
