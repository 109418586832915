@import '../../../../../sass/color-palette';

.ratesWrapper {
  height: 55vh;
  // overflow-y: scroll;
  // scroll-behavior: smooth;
  // position: relative;
  position: relative;

  /* @media (max-height:705px){
    height: 45vh;
  }
  @media (max-height:390px){
    height: 25vh;
  } */

  &_shadow {
    background: linear-gradient(180deg, #ffffff00 0%, #ffffff 100%);
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    @media (max-height:480px){
      background:none;
    }
  }
}

.ratesScroll {
  height: 55vh;
  overflow-y: scroll;
  scroll-behavior: smooth; 
  padding-left:1rem;
  @media (min-width:769px){
    padding-left: 0;
  }
  @media (max-height:568px){
    height: 45vh;
  }
  @media (max-height:390px){
    height: 25vh;
  }
}

.generalRatingBlock {
  border-bottom: 1px solid $dark-blue-15;
}