@import '../../../../../sass/color-palette';
@import '../../../../../sass/mixins';
@import '../../../../../sass/spacing';

.banner {
  @media (max-width: 768px) {
    width: 100%;
  }
  .schoolTypes {
    max-width: 598px;
  }
  .about-banner {
    border: 1px solid #99d6eb;
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .top_scout {
    position: relative;
    @include toRem('top', 6);
  }

  .wrapper {
    @media (max-width: 768px) {
      width: 100%;
    }
    img {
      @include toRem('height', 68);
      @include toRem('width', 64);
    }
    .square {
      //   @include toRem('height', 264);
      @include toRem('width', 235);
      @media (max-width: 768px) {
        width: 100% !important;
      }
    }

    .rectangle {
      width: 100%;
      @include toRem('min-height', 132);
      display: flex;
      flex-direction: row;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $dark-blue-700;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $dark-blue-500;
    margin-top: 4px;
  }

  .link {
    width: fit-content;
    margin-top: 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: $primary-500;
  }

  .chipsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }

  .chip {
    display: flex;
    width: fit-content;
    padding: 2px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    background: $secondary-100;
    color: $secondary-600;
  }
}
