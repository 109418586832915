.map {
    width: auto;
    height: calc(100vh - 150px);
    border-radius: $card-border-radius;
    box-shadow: $card-box-shadow;

    @media (min-width: $tablet-landscape) {
        display: flex
    }
}

.map-marker__wrapper {
    display: relative;
}

.map-marker {
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 0;
    top: -32px;
    left: -16px;
}

.map-bubble {
    display: block;
    width: 350px;
    position: absolute;
    z-index: 1;   
    background: #ffffff;
    border-radius:0.5rem;
    box-shadow: $card-box-shadow;
    transform: translate(-50%, -100%); // Position the bubble back centered above the mark
    top: -46px; 
    //overflow: hidden;
    
    // Account for bubble point (12px) + map-marker height (32px) + additional spacing (2px)
    /* @media (max-width: 480px){
        top: -10px
    }
    @media (min-width: $tablet-portrait) {
        width: 300px;
        padding: 16px;
        top: -50px; // Account for bubble point (16px) + map-marker height (32px) + additional spacing (2px)
    }
    @media (min-width: $tablet-landscape) {
        padding: 20px;
        top: -50px; // Account for bubble point (16px) + map-marker height (32px) + additional spacing (2px)
    } */

    .results_listing__school-card{
        &:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #ffffff;
            border-width: 12px;
            margin-left: -12px;
            z-index:9999;
    
            @media (min-width: $tablet-portrait) {
                border-width: 14px;
                margin-left: -14px;
            }
    
            @media (min-width: $tablet-landscape) {
                border-width: 16px;
                margin-left: -16px;
            }
        }
        &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-top-color: rgba(0, 0, 0, 0.6);
            border-width: 12px;
            margin-left: -12px;
            z-index:9999;
    
            @media (min-width: $tablet-portrait) {
                border-width: 14px;
                margin-left: -14px;
            }
    
            @media (min-width: $tablet-landscape) {
                border-width: 16px;
                margin-left: -16px;
            }
        }
    }

    
}

/* .map-bubble__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    // Automatically space the rating, name, and distance text
    > .map-bubble__header--rating {
        margin-right: .5rem;

        @media (min-width: $tablet-portrait) {
            margin-right: .5rem;
        }

        @media (min-width: $tablet-landscape) {
            margin-right: .5rem
        }
    }
}
 */
/* .map-bubble__header--rating {
    span {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: $primary;
        color: #ffffff;        
        font-weight: $medium;
        border-radius: 100px;
        text-align: center;
        line-height: 40px;
        flex-shrink: 0;
    }
} */

/* .map-bubble__header--name {
    font-size: 20px;
    font-weight: $medium;
    flex-grow: 1;
} */

/* .map-bubble__header--distance {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-shrink: 0;

    .icon {
        display: inline-block;
    }
} */
/* 
.map-bubble__details {
    margin-top: 16px;
    font-weight: $medium;

    // Automatically space the address, type, grades, etc.
    & > * + * {
        margin-top: 12px;
    }
}
.map-bubble__mi{
    margin-right: .3rem;
    p {
        display:flex;
        flex-direction: column;
        align-items: center;
    }
}

.map-bubble__details .icon {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 2px;
}

.map-bubble__details--address,
.map-bubble__details--type,
.map-bubble__details--grades {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    font-size: 12px;
    line-height: 1.25;

    @media (min-width: $tablet-portrait) {
        font-size: 14px;
    }

} */

/* .map-button__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.map-marker--home {
    margin-top: -16px;
    margin-left: -16px;
    z-index: 1;
}

.map-marker--school {
    margin-top: -16px;
    margin-left: -16px;
    z-index: 0;
    &-top {
        z-index: 999999 !important;
    }
}

.map-marker--current {
    // z-index: 1;
    margin-top: -16px;
    margin-left: -23px;
} */