.reviews-section {
  margin-top: 3rem;
}

.reviewsHeader {
  display: grid;
  grid-template-columns: 100%;

  @media (min-width: 767px) {
    grid-template-columns: 50% 50%;
    justify-content: space-between;
  }
}
.title {
  margin-bottom: 2rem;
  font-size: 1.5rem !important;
  font-weight: 600;
  line-height: 2rem !important;
}

.externalWrapper {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

.externalReview {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;

  & img {
    width: 50px;
    height: 50px;
  }
}

.leaveReview {
  border: 1px solid #c6cad1;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.barStar {
  display: grid;
  grid-template-columns: 12% 88%;
  align-items: baseline;
  margin-bottom: 0.75rem;

  &_progress {
    display: grid;
    grid-template-columns: 90% 10%;
    align-items: center;
    gap: 0.25rem;
  }

  &_num {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;

    p {
      font-size: 12px;
    }

    & img {
      margin: 0;
    }
  }
}

.background {
  width: 100%;
  background: #e8eaee;
  border-radius: 0.5rem;
  height: 0.625rem;
  overflow: hidden;
}
.barColor {
  height: 100%;
  background: #f0ad1b;
}
