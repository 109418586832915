.school_match_overview {
  border-top: .5px solid #c6cad1;
  padding-top: 1rem;
}

.overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .25rem;
  margin-bottom: .5rem;
  cursor: pointer;

  h6 {
    font-size: 14px !important;
    color: #5f6a7d;
  }
}

.chipsGrid {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
}

.chip {
  padding: 3px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.chip_match {
  @extend .chip;
  background-color: #f5faeb;
  color: #1f290a;
  border: none;
}

.chip_plus {
  @extend .chip;
  border: 1px solid #5f6a7d;
  color: #5f6a7d;
  gap: 2px;
  cursor: pointer;
}

.autoHeight {
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: .4s;
}
.fixHeight {
  max-height: 28px;
  overflow: hidden;
  transition: .4s;
}
