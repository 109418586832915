.container {
  position: relative;
  background-color: #e8f0f2;
  border-radius: 0.5rem;
  max-width: 1048px;
  margin: auto;
  margin-top: 94px;
  padding: 3rem 1rem;

  @media screen and (min-width: 767px) {
    padding: 3rem 4rem 4rem 4rem;
  }
}
.bg_grid_bullets {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  display: none;
  @media screen and (min-width: 768px) {
    display: inline;
  }
}
.title {
  font-size: 32px !important;
  line-height: 39px !important;
  font-weight: 700 !important;
  text-align: center;
  margin-bottom: 37px;
}
.title_step {
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-bottom: 14px;
}
.steps_container {
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}
.step {
  padding: 24px;
  min-height: 358px;
  width: 100%;
  border-radius: 4px;
  z-index: 1;

  &_figure {
    width: 84px;
    height: auto;
    margin-bottom: 1rem;
  }
  &_blue {
    background-color: #cee8f0cc;
  }
  &_green {
    background-color: #dbeacdcc;
  }
  &_number {
    font-size: 30px;
    font-weight: 900;
  }
  &_number_green {
    color: #b7db5a;
  }
  &_number_blue {
    color: #99d6eb;
  }
  p {
    line-height: 19px;
    font-size: 1rem !important;
  }
}
.step_figure_number {
  display: flex;
  justify-content: space-between;
}
.button {
  background: linear-gradient(90deg, rgba(0, 153, 204, 1) 0%, rgba(0, 153, 204, 0.5018382352941176) 100%);
  margin-top: 42px;
  color: #fff;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 100%;
  padding: 0.5rem;
  @media (min-width: 768px) {
    width: 180px;
  }
}
