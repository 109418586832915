.externalReviews {
  max-width: 300px;
  display: flex;

  .externalReviewsSite {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3rem;
    .image {
      width: 32px;
      height: 32px;
      margin-left: 5px;
    }
    span {
      display: flex;
    }
    .dot {
      color: #5f6a7d;
      font-size: 16px;
      font-family: Open Sans;
      font-weight: 700;
      line-height: 160%;
      letter-spacing: -0.16px;
      margin-right: 5px;
      margin-left: 5px;
    }

    .dot:last-child {
      visibility: collapse;
    }
  }
}
