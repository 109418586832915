//shcolav1
//$primary: #0099CC
//dark-blue-100 : #4a4a4a
//$secondary: #9C3
//dark-blue-75 #0c5e79 //#546982,

@mixin theme-aware($key, $color) {
    @each $theme-name, $theme-color in $themes {
        .theme-#{$theme-name} & {
            #{$key}: map-get(map-get($themes, $theme-name), $color) !important;
        }
    }
}

// UI Colors
// Primary Color Brand
$primary-blue: #0099CC;
$schola-green:#99CC33;
$aqua-secondary : #0C5E79;
$dark-blue: #1A2A45;
// Secondary Colors
$orange: #FFAA5A;
$amaranth: #EE4266;
$indigo: #5076C7;
// Semantic Colors
$success: #02CA81;
$warning: #FBB13C;
$danger: #D62246;
$info: #136AC3;

//// Usage

// Primary
// Use this to emphasise main ui components and text.
$primary-700-text-icon:                    $dark-blue;
$primary-600-pressed:                     #003D52;
$primary-500-default-link:                #006B8F;
$primary-400-accent-icon-hover-selected:   $primary-blue;
$primary-300-border:                      #4DB8DB;
$primary-200-subtle-border:               #99D6EB;

//Secondary
//Use this to emphasise secondary ui components and text.
$secondary-600-text-icon-default:         $aqua-secondary;
$secondary-500-secondary-text-accent:   #3D7E94;
$secondary-300-border-pressed:          #9EBFC9;
$secondary-200-subtle-border-hover:     #CEDFE4;

//Neutral
//Use this as the base for text color and neutral greys for Schola platform. Use it for Titles, secondary text and pargraph.
$dark-blue-700-text-icon:                   #1A2A45;
$dark-blue-500-secondary-text:              #5F6A7D;
$dark-blue-400-subtle-icon-border:          #818A99;
$dark-blue-300-subtle-border-disabled-text: #A3AAB5;
$dark-blue-200-disabled-border:             #C6CAD1;

//Success
//Use this color to communicate positive actions
$success-700-text-icon:             #015134;
$success-600-2ry-text-icon-pressed: #018D5A;
$success-400-accent-default:        #02CA81;
$success-300-border-hover:          #4EDAA7;
$success-200-subtle-border:         #9AEACD;

//Warning
//Use this color to communicate warning actions
$warning-700-text-icon:         #644718;
$warning-600-secondary-text:    #B07C2A;
$warning-500-accent-pressed:    #E29F36;
$warning-400-accent-default:    #FBB13C;
$warning-300-border-hover:      #FCC877;
$warning-200-subtle-border:     #FDE0B1;

//Danger
//Use this color to communicate danger actions
$danger-700-text-icon:          #560E1C;
$danger-600-secondary-text:     #961831;
$danger-500-accent-default:     #C11F3F;
$danger-400-accent-hover:       #D62246;
$danger-300-border:             #E2647E;
$danger-200-subtle-border:      #EFA7B5;

//Info
//Use this color for informational ui elements
$info-700-text-icon:        #0A3562;
$info-600-pressed:          #0D4A89;
$info-500-2ry-text-default: #0F559C;
$info-400-accent-hover:     #136AC3;
$info-300-border:           #5A97D5;
$info-subtle200-border:     #A1C3E7;

//Backgrounds
//Use this colors for backgrounds only. Do not combine elements with different colors.
$primary-100-background:#E6F5FA;
$secondary-100-background: #E7EFF2;
$green-100-background: #F5FAEB;
$dark-blue-100-background: #F1F2F3;
$success-100-background: #E6FAF2;
$warning-100-background: #FEEFD8;
$danger-100-background: #FBE9ED;

//Full Color Palette
$primary-700:#001F29;   $secondary-700: #0A4B61;    $green-700:#1F290A;
$primary-600:#003D52;   $secondary-600: #0C5E79;    $green-600:#3D5214;
$primary-500:#006B8F;   $secondary-500: #3D7E94;    $green-500:#6B8F24;
$primary-400:#0099CC;   $secondary-400: #6D9EAF;    $green-400:#99CC33;
$primary-300:#4DB8DB;   $secondary-300: #9EBFC9;    $green-300:#B8DB70;
$primary-200:#99D6EB;   $secondary-200: #CEDFE4;    $green-200:#D6EBAD;
$primary-100:#E6F5FA;   $secondary-100: #E7EFF2;    $green-100:#F5FAEB;

$success-800: #00281A;  $warning-800:   #32230C;    $danger-800:#2B070E;
$success-700: #015134;  $warning-700:   #644718;    $danger-700:#560E1C;
$success-600: #018D5A;  $warning-600:   #B07C2A;    $danger-600:#961831;
$success-500: #02B674;  $warning-500:   #E29F36;    $danger-500:#C11F3F;
$success-400: #02CA81;  $warning-400:   #FBB13C;    $danger-400:#D62246;
$success-300: #4EDAA7;  $warning-300:   #FCC877;    $danger-300:#E2647E;
$success-200: #9AEACD;  $warning-200:   #FDE0B1;    $danger-200:#EFA7B5;
$success-100: #E6FAF2;  $warning-100:   #FEEFD8;    $danger-100:#FBE9ED;

$info-800:  #041527;    $dark-blue-700: #1A2A45;
$info-700:  #0A3562;    $dark-blue-600: #3C4A61;
$info-600:  #0D4A89;    $dark-blue-500: #5F6A7D;
$info-500:  #0F559C;    $dark-blue-400: #818A99;
$info-400:  #136AC3;    $dark-blue-300: #A3AAB5;
$info-300:  #5A97D5;    $dark-blue-200: #C6CAD1;
$info-200:  #A1C3E7;    $dark-blue-100: #F1F2F3;
$info-100:  #E7F0F9;

/////////////////////////////////////////////////////////////////////////////////

$secondary-500: $secondary-500-secondary-text-accent;

$dark-blue-100:    #213556;
$dark-blue-75:     #546982;
$dark-blue-65:     #6E7B91;
$dark-blue-50:     #96A3B8;
$dark-blue-25:     #C7CCD4;
$dark-blue-15:     #DADEE5;
$dark-blue-10:     #E8EAEE;
$dark-blue-05:     #F4F6F8;

$navy-blue-100:    #0645AD;
$navy-blue-75:     #4473C1;
$navy-blue-50:     #82A2D6;
$navy-blue-25:     #C1D0EA;
$navy-blue-15:     #D8E3F5;
$navy-blue-10:     #E8EEF8;
$navy-blue-05:     #F2F5FB;
$navy-blue-02:     #FAFBFD;

$accent-dark:  #005FB3;
$accent-100:   #008CFF;
$accent-75:    #45ABFF;
$accent-50:    #8CCBFF;
$accent-25:    #CCE9FF;
$accent-10:    #E5F4FF;
$accent-05:    #F2F9FF;
$accent-02:    #FAFDFF;


$themes:(
	schola: (
        secondary: 		#99CC33,

        dark-blue-100:    $dark-blue-100,
        dark-blue-75:     $dark-blue-75,
        dark-blue-65:     $dark-blue-65,
        dark-blue-50:     $dark-blue-50,
        dark-blue-25:     $dark-blue-25,
        dark-blue-15:     $dark-blue-15,
        dark-blue-10:     $dark-blue-10,
        dark-blue-05:     $dark-blue-05,
        
        navy-blue-100:    $navy-blue-100,
        navy-blue-75:     $navy-blue-75,
        navy-blue-50:     $navy-blue-50,
        navy-blue-25:     $navy-blue-25,
        navy-blue-15:     $navy-blue-15,
        navy-blue-10:     $navy-blue-10,
        navy-blue-05:     $navy-blue-05,
        navy-blue-02:     $navy-blue-02,
        
        accent-dark:      $accent-dark,
        accent-100:       $accent-100,
        accent-75:        $accent-75,
        accent-50:        $accent-50,
        accent-25:        $accent-25,
        accent-10:        $accent-10,
        accent-05:        $accent-05,
        accent-02:        $accent-02,
    )       
);

////////////////////////////////////// color classes for text and carbon icons:

.text-dark-blue-100 {
    color: $dark-blue-100;
    fill: $dark-blue-100;
}
.text-dark-blue-75 {
    color: $dark-blue-75;
    fill: $dark-blue-75;
}
.text-dark-blue-65 {
    color: $dark-blue-65;
    fill: $dark-blue-65;
}
.text-dark-blue-50 {
    color: $dark-blue-50;
    fill: $dark-blue-50;
}
.text-dark-blue-25 {
    color: $dark-blue-25;
    fill: $dark-blue-25;
}
.text-dark-blue-15 {
    color: $dark-blue-15;
    fill: $dark-blue-15;
}
.text-dark-blue-10 {
    color: $dark-blue-10;
    fill: $dark-blue-10;
}
.text-dark-blue-05 {
    color: $dark-blue-05;
    fill: $dark-blue-05;
}

.text-navy-blue-100 {
    color: $navy-blue-100 !important;
    fill: $navy-blue-100 !important;
}
.text-navy-blue-75 {
    color: $navy-blue-75 !important;
    fill: $navy-blue-75 !important;
}
.text-navy-blue-50 {
    color: $navy-blue-50 !important;
    fill: $navy-blue-50 !important;
}
.text-navy-blue-25 {
    color: $navy-blue-25 !important;
    fill: $navy-blue-25 !important;
}
.text-navy-blue-15 {
    color: $navy-blue-15 !important;
    fill: $navy-blue-15 !important;
}
.text-navy-blue-10 {
    color: $navy-blue-10 !important;
    fill: $navy-blue-10 !important;
}
.text-navy-blue-05 {
    color: $navy-blue-05 !important;
    fill: $navy-blue-05 !important;
}
.text-navy-blue-02 {
    color: $navy-blue-02 !important;
    fill: $navy-blue-02 !important;
}

.text-accent-dark {
    color: $accent-dark !important;
    fill: $accent-dark !important;
}
.text-accent-100 {
    color: $accent-100 !important;
    fill: $accent-100 !important;
}
.text-accent-75 {
    color: $accent-75 !important;
    fill: $accent-75 !important;
}
.text-accent-50 {
    color: $accent-50 !important;
    fill: $accent-50 !important;
}
.text-accent-25 {
    color: $accent-25 !important;
    fill: $accent-25 !important;
}
.text-accent-10 {
    color: $accent-10 !important;
    fill: $accent-10 !important;
}
.text-accent-05 {
    color: $accent-05 !important;
    fill: $accent-05 !important;
}
.text-accent-02 {
    color: $accent-02 !important;
    fill: $accent-02 !important;
}
////////////////////////////////////////////////// classes for background color:

.bg-dark-blue-100 {
    background-color: $dark-blue-100 !important;
} 
.bg-dark-blue-75 {
    background-color: $dark-blue-75 !important;
} 
.bg-dark-blue-65 {
    background-color: $dark-blue-65 !important;
} 
.bg-dark-blue-50 {
    background-color: $dark-blue-50 !important;
} 
.bg-dark-blue-25 {
    background-color: $dark-blue-25 !important;
} 
.bg-dark-blue-15 {
    background-color: $dark-blue-15 !important;
} 
.bg-dark-blue-10 {
    background-color: $dark-blue-10 !important;
} 
.bg-dark-blue-05 {
    background-color: $dark-blue-05 !important;
} 

 .bg-navy-blue-100 {
     background-color:$navy-blue-100;
 }
 .bg-navy-blue-75 {
     background-color:$navy-blue-75; 
 }
 .bg-navy-blue-50 {
     background-color:$navy-blue-50; 
 }
 .bg-navy-blue-25 {
     background-color:$navy-blue-25; 
 }
 .bg-navy-blue-15 {
     background-color:$navy-blue-15; 
 }
 .bg-navy-blue-10 {
     background-color:$navy-blue-10; 
 }
 .bg-navy-blue-05 {
     background-color:$navy-blue-05; 
 }
 .bg-navy-blue-02 {
     background-color:$navy-blue-02; 
 }
///
.bg-accent-dark {
    background-color: $accent-dark !important;
}
.bg-accent-100 {
    background-color: $accent-100 !important;
}
.bg-accent-75 {
    background-color: $accent-75 !important;
}
.bg-accent-50 {
    background-color: $accent-50 !important;
}
.bg-accent-25 {
    background-color: $accent-25 !important;
}
.bg-accent-10 {
    background-color: $accent-10 !important;
}
.bg-accent-05 {
    background-color: $accent-05 !important;
}
.bg-accent-02 {
    background-color: $accent-02 !important;
}

////////////////////////////////////////////////////////////////
//legacy color vars
// ============= DEPRECATED DONT USE FOR FUTURE DESIGN
$dashboard-border:      #E5EDD5;
$dashboard-background:  #F6FCF9;
$green-dark:            #317159;
$green:                 #52BD94;
$green-light:           #F5FBF8;
$red-dark:              #A73636;
$red-light:             #FDF4F4;
$red:                   #D14343;
$yellow:                #F0AD1B;
$black:                 #000000;
$white:                 #FFFFFF;

$primary: #0099CC;
$green-legacy: #9c3;
$secondary: $green-legacy;
$red-legacy : #ff444f;
$heading-color : $dark-blue-75;
$body-color : $dark-blue-100;
$anchor-color : $accent-100;
$border-color : $dark-blue-15;
$gray-200 : #d1d3dd;
$gray-400 : #8d919a;

$text-secondary: $green-legacy;

$primary-red : #ff444f;
$primary-orange : #FDA353;
$primary-purple : #95A2FF;
$fill-disabled : #EBEFF4;
$fill-hover : #F9F9FB;
$fill-blue-hover : #F2FBFF;
$fill-tab : #FBFCFD;
$fill-border : #DFE4EA;
$fill-modal : #F5F7F9;

$primary-blue : #0099CC;
$schola-atlantis : #81C784;
$schola-carmine : #f4333e;
$schola-purple : #af8cc4;
$schola-purple-2 : #7E57C2;
$schola-yellow : #f6d270;
$schola-bluegrey : #6e95a0;
$schola-seablue : #086788;
$schola-bluegreen : #108daf;
$schola-mist : #b1c1c5;
$schola-lightgrey : #999999;
$schola-alabaster : #fafafa;
$schola-gray : #4a4a4a;
$schola-darkgray : #333333;
$schola-gray-sub : #78909C;
$schola-gray-light : #ccc;
$schola-lemon : #FF9100;
$schola-hr-color : #CFD8DC;
$schola-back-grey : #f5f5f5;
$schola-border : #B0BEC5;
$schola-bluemoon : #eaf0fa;
/* color leads v3 */
$secondary-red : #FF5757;
$secondary-orange : #FC6E35;
$secondary-purple : #7F5DFF;
$secondary-yellow : #F0C52E;
$secondary-blue : #5381EF;
$secondary-teal : #5DCDD3;
$secondary-purple2 : #8E44AD;
$secondary-lead-green : #29A37E;
$secondary-sea-green : #53CAA6;
$secondary-green : #29A37E;

$white : #fff;
$gray-200 : #d1d3dd;
$fill-disabled : #EBEFF4;
$fill-hover : #F9F9FB;

$text-disabled : #D1D4DE;

$primary-blue : #0099CC;
$schola-atlantis : #81C784;
$schola-carmine : #f4333e;
$schola-purple : #af8cc4;
$schola-purple-2 : #7E57C2;
$schola-yellow : #f6d270;
$schola-bluegrey : #6e95a0;
$schola-seablue : #086788;
$schola-bluegreen : #108daf;
$schola-mist : #b1c1c5;
$schola-lightgrey : #999999;
$schola-alabaster : #fafafa;
$schola-gray : #4a4a4a;
$schola-darkgray : #333333;
$schola-gray-sub : #78909C;
$schola-lemon : #FF9100;
$schola-hr-color : #CFD8DC;
$schola-back-grey : #f5f5f5;
$schola-border : #B0BEC5;
$schola-bluemoon : #eaf0fa;
/* color leads v3 */
$secondary-red : #FF5757;
$secondary-orange : #FC6E35;
$secondary-purple : #7F5DFF;
$secondary-yellow : #F0C52E;
$secondary-blue : #5381EF;
$secondary-teal : #5DCDD3;
$secondary-purple2 : #8E44AD;
$secondary-lead-green : #29A37E;
$secondary-sea-green : #53CAA6;
$secondary-green : #29A37E;
// ============= DEPRECATED DONT USE FOR FUTURE DESIGN