.facilities_cards {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 740px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
}
.facility_image {
  border-radius: 8px;
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
}
.facility_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #425466;
  margin-bottom: .5rem;
}
.facility_card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #f3f5f7;
  box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  padding: 8px;
}
