.content {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.phone {
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
