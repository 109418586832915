@use '../../sass/mixins';
@use '../../sass/themes';

// *******************************************
// *******************************************

// Base icon styles

// *******************************************
// *******************************************

.schola_icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    fill: #000000;
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
    }
}

// Sizes
.icon--xsmall {
  @include mixins.toRem('width', 12);
  @include mixins.toRem('height', 12);  
}

.icon--small {
  @include mixins.toRem('width', 16);
  @include mixins.toRem('height', 16);
}

.icon--medium {
  @include mixins.toRem('width', 24);
  @include mixins.toRem('height', 24);
}

.icon--large {
  @include mixins.toRem('width', 32);
  @include mixins.toRem('height', 32);
}

.icon--xlarge {
  @include mixins.toRem('width', 40);
  @include mixins.toRem('height', 40);
}

.icon--2xlarge {
  @include mixins.toRem('width', 48);
  @include mixins.toRem('height', 48);
}

// Rotation
.icon--rotate-45 {
  transform: rotate(45deg);
}

.icon--rotate-90 {
  transform: rotate(90deg);
}

.icon--rotate-180 {
  transform: rotate(180deg);
}

.icon--rotate-270 {
  transform: rotate(270deg);
}

// Colors
.icon--primary {
  @include themes.theme-aware('fill','icon-primary');
}

.icon--secondary {
  @include themes.theme-aware('fill','icon-primary');
}

.icon--dark {
  @include themes.theme-aware('fill','icon-default');
}

.icon--light {
  @include themes.theme-aware('fill','icon-onAction');
}

.icon--mist {
  @include themes.theme-aware('fill','icon-subtle');
}

.icon--black {
  @include themes.theme-aware('fill','icon-default');
}

.icon--white {
  @include themes.theme-aware('fill','icon-onAction');
}
