.section {
  &-banner {
    background-color: white;
    border: 1px solid #dadee5;
    border-radius: 8px;
    padding: 1.5rem;

    &-content {
      display: flex;
      flex-direction: column-reverse;
      gap: 80px;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    &-button {
      a {
        display: inline-block;
      }
    }
    &-img {
      &.transportation {
        overflow: hidden;
        background-image: url(https://static.schola.com/Schola-onboarding-illustration-update-01.png);
        background-repeat: no-repeat;
        min-height: 96px;
      }
      &.claim {
        @extend .transportation;
        background-position: center;
        background-image: url(https://static.schola.com/banners/Schola-Communities-Flow-teachers-01.png);
      }

      @media (min-width: 768px) and (max-width: 810px) {
        background: none;
        max-height: unset;
      }

      @media (min-width: 811px) {
        background: none;
        max-height: unset;
      }

      img {
        display: none;
        @media (min-width: 768px) {
          display: inline;
          margin: 0;
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
