.starsExperience {
  display: flex;
  gap: 18px;

  & button:focus {
    outline: none;
  }

  .starsExperienceItem {
    & svg {
      width: 32px;
      height: 32px;
      transition: all 0.3s ease-out;
    }
  }
}

.starOn {
  fill: rgb(239, 210, 76);
}
.starOff {
  fill: #c7ccd4;
}
