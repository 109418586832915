.accordionHeader {
  padding: 1rem 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3C4A61;
  border-bottom: 1px solid #C6CAD1;
  margin-bottom: 1rem;

  &:hover {
    cursor: pointer;
  }
}